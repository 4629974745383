import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-content',
  standalone: true,
  imports: [],
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss'
})
export class ContentComponent {
  @Input() level = 1;

  @Input() background: 'none' | 'primary' | 'secondary' | 'tertiary' = 'none';

  get borderClass(): string {
    let border = "border-black border-top"
    if (this.background == 'primary') {
      border = "border-tertiary border-top"
    }
    if(this.level>1) {
      border = "border-none"
    }
    return border+' g-col-2 mt-2 mr-2 d-none d-md-block'
  }
}
