<div class="position-relative">
  <!--<img src="/assets/Keyvisuals/BO_Keyvisual_Icon_1.jpg" class="img-fluid">-->
  <img src="/assets/Keyvisuals/Testbild.png" class="img-fluid">
  <div class="position-absolute top-50 start-25 translate-middle text-center p-4" style="max-width: 48%; background-color: #f7e4eb9b;">
    <h1 class="text-primary fs-1 fw-bold line-break-translated">
      {{ tagline | translate }}
    </h1>
    @if(subtTagline && false) {
      <div class="text-primary fs-4 fw-bold line-break-translated">
        {{ subtTagline | translate }}
      </div>
    }
    <div class="text-center mt-2" style="margin: auto; max-width: 400px;">
      <div class="mb-2 call-to-action-button">
        <a class="btn btn-primary w-100 fs-4" (click)="openRegisterForm()">
          {{ callToAction | translate }}
        </a>
      </div>
      <div class="d-flex justify-content-between">
        <div class="me-2">
          <a href="https://apps.apple.com/us/app/band-on/id1673700582" target="_blank">
            <img [src]="'/assets/Download_on_the_App_Store_Badge.svg' | localizeAsset" class="scaling-store-icons">
          </a>
        </div>
        <div class="ms-2">
          <a href="https://play.google.com/store/apps/details?id=ch.pmtbmbh.bandon.app" target="_blank">
            <img [src]="'/assets/google-play.png' | localizeAsset" class="scaling-store-icons">
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="position-absolute top-50 start-75 text-center translate-middle d-none d-md-block" style="width: 30%">
    <img [src]="mockupUrl | localizeAsset" class="img-fluid">
  </div>
  <div class="position-absolute bottom-2 start-50 text-center" style="transform: translateX(-50%);">
    <!--<a href="#faqs" class="dyn-font-3xl text-primary">
      Aktuelles von band-on
    </a>-->
  </div>
  <div class="position-absolute top-0 end-r1 bg-primary pt-4 d-md-none">
    <img src="/assets/Band-On_Logo_1024.png" style="width: 13vw">
  </div>
</div>
