<app-text-block background="tertiary" [level]="2">
  <span slot="header">Unsere Preise</span>
  <span slot="content">
    <div class="grid text-center">
      <div class="d-none d-md-block g-col-4"></div>
      <div class="g-col-12 g-col-md-4 d-flex w-100">
        <div class="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" [checked]="period=='monthly'" (click)="setPeriod('monthly')">
          <label class="btn btn-outline-primary" for="btnradio1">{{'PAYMENT.MONTHLY' | translate}}</label>

          <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" [checked]="period=='annual'" (click)="setPeriod('annual')">
          <label class="btn btn-outline-primary" for="btnradio2">{{'PAYMENT.ANNUAL' | translate}}</label>
        </div>
      </div>
      <div class="d-none d-md-block g-col-4"></div>

      <div class="g-col-12 g-col-lg-4">
        <app-subscription-panel [offer]="offers[0]"></app-subscription-panel>
      </div>
      <div class="g-col-12 g-col-lg-4">
        <app-subscription-panel [offer]="offers[1]" [paymentPeriod]="period"></app-subscription-panel>
      </div>
      <div class="g-col-12 g-col-lg-4">
        <app-subscription-panel [offer]="offers[2]"></app-subscription-panel>
      </div>
    </div>
  </span>
</app-text-block>
