<div class="d-none d-md-block" [class.bg-tertiary]="background === 'tertiary'">
  <div class="grid">
    <div class="g-col-2"></div>
    <div class="g-col-12 g-col-md-8 btn-group">
      @for (feature of features; track $index) {
        <a class="btn"
          [class.text-primary]="$index!==featureIndex"
          [class.btn-primary]="$index===featureIndex" (click)="showFeature($index)">
          {{ feature.title | translate }}
        </a>
      }
    </div>
    <div class="g-col-2 d-none d-md-block"></div>
  </div>
</div>
<div class="dropdown d-md-none w-100">
  <button class="btn btn-primary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    {{ selectedFeature.title | translate }}
  </button>
  <ul class="dropdown-menu w-100 bg-tertiary border-0">
    @for (feature of features; track $index) {
      <li>
        <a class="dropdown-item"
          (click)="showFeature($index)"
          [class.active]="$index===featureIndex"
        >
          {{ feature.title | translate }}
        </a>
      </li>
    }
  </ul>
</div>

<ngb-carousel class="carousel-fade" #featureCarousel [keyboard]="false"
    [showNavigationArrows]="false" [showNavigationIndicators]="false"
    [interval]="0">
  @for (feature of features; track $index) {
    <ng-template ngbSlide>
      <app-feature-element [feature]="feature"></app-feature-element>
    </ng-template>
  }
</ngb-carousel>
