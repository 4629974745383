<div class="position-relative">
  <img src="/assets/Keyvisuals/BO_Keyvisual_Icon_2.jpg" class="w-100">
  <div class="position-absolute top-0 end-r1 bg-primary pt-4 d-md-none">
    <img src="/assets/Band-On_Logo_1024.png" style="width: 13vw">
  </div>
</div>

<app-text-block background="primary">
  <span slot="header">{{ 'FEATURES.TITLE' | translate }}</span>
  <span slot="content">
    {{ 'FEATURES.DESCRIPTION' | translate }}
  </span>
</app-text-block>

<!--Musikplayer-->
@for (feature of features; track $index) {
  <app-feature-element [feature]="feature" [background]="$index % 2 === 1 ? 'none' : 'tertiary'" id="feature{{ $index }}"></app-feature-element>
}
