import { Component } from '@angular/core';

@Component({
  selector: 'app-midi-import',
  standalone: true,
  imports: [],
  templateUrl: './midi-import.component.html',
  styleUrl: './midi-import.component.scss'
})
export class MidiImportComponent {

}
