import { GoogleLoginProvider, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, inject } from '@angular/core';
import { NgFor, NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { LocalizeRouterModule, LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { environment } from 'src/environments/environment';
import { MenuOverlayComponent } from '../menu-overlay/menu-overlay.component';
import { PrivacyPolicyComponent } from "../../../pages/privacy-policy/privacy-policy.component";

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss'],
    standalone: true,
    imports: [
      RouterLink,
      NgFor,
      NgClass,
      NgIf,
      ReactiveFormsModule,
      GoogleSigninButtonModule,
      UpperCasePipe,
      TranslateModule,
      LocalizeRouterModule,
      MenuOverlayComponent,
      PrivacyPolicyComponent,
    ],
})
export class TopNavComponent implements OnInit {
  @ViewChild('closeModalBtn') closeModalBtn: ElementRef | undefined;

  private readonly localizeRouterService = inject(LocalizeRouterService);
  private readonly router = inject(Router);

  isNavbarHidden = false;
  lastScrollTop = 0;

  protected currentUrl = '';

  GoogleLoginProvider = GoogleLoginProvider;

  @Input() showTopNav: boolean = true;

  constructor() {
    this.setCurrentUrl();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(event => {
      this.setCurrentUrl();
    });

  }

  get isTestserver() {
    return environment.apiURL.includes('test.band')
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.closeModal();
      }
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Determine the scroll direction
    if (currentScrollTop > this.lastScrollTop) {
      // Scrolling down
      this.isNavbarHidden = true;
    } else {
      // Scrolling up
      this.isNavbarHidden = false;
    }

    // Update the last scroll position
    this.lastScrollTop = Math.max(0, currentScrollTop); // For mobile or negative scrolling
  }

  closeModal() {
    if(this.closeModalBtn) {
      this.closeModalBtn.nativeElement.click();
    }
  }

  private setCurrentUrl(): void {
    this.currentUrl = this.router.url
      .replace('/' + this.localizeRouterService.parser.currentLang, '')
      .split('?')[0];
  }
}
