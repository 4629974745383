<div class="grid">
  <div class="g-col-3 d-none d-md-block"></div>
  <div class="g-col-12 g-col-md-9 g-col-lg-6 card container border-0">
    <div class="card-body">
      <div class="card-title">
        <h2>{{ 'AUTH.DELETEUSER.DELETUSER' | translate }}</h2>
      </div>
          <div *ngIf="validForm && !deleted">
          <button  class="btn btn-primary" (click)="onSubmit()">
            {{ 'AUTH.DELETEUSER.DELETE' | translate }}
          </button>
        </div>
        <div *ngIf="!validForm && errmsg" class="alert">
            {{ errmsg }}
        </div>
        <div class="spinner-border text-primary" *ngIf="!validForm && !errmsg">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div *ngIf="deleted">
          {{ 'AUTH.DELETEUSER.DELETED' | translate }}
          <button  class="btn btn-primary" (click)="closeTab()">
            {{ 'AUTH.DELETEUSER.CLOSE' | translate }}
          </button>
        </div>
    </div>
  </div>
</div>
