import { Location, CommonModule, UpperCasePipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router, RouterModule, UrlTree } from '@angular/router';
import { LocalizeRouterModule, LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DocumentService } from 'src/app/services/document.service';
import { ScrollingService } from 'src/app/services/scrolling.service';
import { GoogleLoginProvider, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { appPages } from 'src/app/shared/data/pages.data';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { filter, Subject, takeUntil } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { AuthenticationService as BandonAuthService } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { RegisterModalComponent } from '../../register/register-modal.component';
import { UserDataService } from 'src/app/services/user/user-data.service';

@Component({
  selector: 'app-menu-overlay',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    LocalizeRouterModule,
    GoogleSigninButtonModule,
    ReactiveFormsModule,
    UpperCasePipe
  ],
  templateUrl: './menu-overlay.component.html',
  styleUrl: './menu-overlay.component.scss'
})
export class MenuOverlayComponent implements OnInit, OnDestroy {
  private readonly localizeRouterService = inject(LocalizeRouterService);
  private modalService = inject(NgbModal);
  private readonly router = inject(Router);
  scroller = inject(ScrollingService)
  documentService = inject(DocumentService)
  userDataService = inject(UserDataService)
  bandonAuthService = inject(BandonAuthService)

  @Input() showTopNav: boolean = true;
  @Input() background: 'primary' | 'secondary' | 'tertiary' | 'light' = 'light';
  @Input() mode: 'topnav' | 'modal' = 'topnav';

  @Output() close = new EventEmitter<void>();

  public appPages = appPages;

  isAuth = false;

  protected readonly locales = ['en', 'de'];
  protected currentUrl = '';

  GoogleLoginProvider = GoogleLoginProvider;

  loginForm = new FormGroup({
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]
    ),
    password: new FormControl('', [
      Validators.required
    ])
  });

  private unsubscribe$ = new Subject<void>();

  constructor(
    private languageService: LanguageService,
    private location: Location,
    private translate: TranslateService,
    private authService: AuthenticationService,
  ) {
    this.setCurrentUrl();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(event => {
      this.setCurrentUrl();
    });
  }

  get languages() {
    return this.languageService.getLanguages();
  }

  get language() {
    return this.languageService.selected;
  }

  get passwordString(): string {
    return this.translate.instant('NAVBAR.PASSWORD');
  }

  get username(): string {
    if (this.authService && this.isAuth && this.userDataService.user) {
      return this.userDataService.user.name
    }
    return '';
  }

  get usermail(): string {
    if (this.authService && this.isAuth && this.userDataService.user) {
      return this.userDataService.user.email
    }
    return '';
  }

  get isTestserver() {
    return environment.apiURL.includes('test.band')
  }

  get appUrl() {
    return environment.apiURL.replace('api', 'app');
  }

  ngOnInit(): void {
    this.bandonAuthService.isAuthenticated
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe( auth => {
        this.isAuth=auth;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setCurrentUrl(): void {
    this.currentUrl = this.router.url
      .replace('/' + this.localizeRouterService.parser.currentLang, '')
      .split('?')[0];
  }

  setLanguage(lang: string) {
    this.languageService.setLanguage(lang);
    this.router.navigateByUrl(`${lang}${this.currentUrl}`)
  }

  closeOverlay() {
    this.close.emit();
  }

  getClass(url: string, fragment: string | undefined = undefined): string {
    const localizedURL = this.localizeRouterService.translateRoute(url);
    if(localizedURL.includes(this.location.path().substring(1)) && !fragment) {
      return 'nav-link active'
    }
    return 'nav-link';
  }

  scrollToFragment(fragment: string | undefined) {
    if(fragment) {
      this.scroller.scrollToFragment(fragment)
    } else {
      this.scroller.scrollToTop();
    }
    this.closeOverlay();
  }


  login() {
    const email = this.loginForm.get('email')?.value
    const password = this.loginForm.get('password')?.value

    if(this.loginForm.valid && email && password) {
      this.authService.login({email, password})
        .subscribe({
          next: res => {
            const url = this.localizeRouterService.translateRoute('/userdata');
            if (typeof url === 'string') {
              this.router.navigateByUrl(url);
            } else if (url instanceof UrlTree) {
              this.router.navigateByUrl(url);
            } else {
              // Handle the unexpected case where 'url' is neither a string nor an UrlTree
              console.error('Unexpected type for url:', url);
            }            this.loginForm.reset();
          },
          error: err => {
            console.log('Error loggin in: ', err)
            this.loginForm.reset();
          }
        });
    }
  }

  loginWithApple() {
    this.authService.loginWithApple();
  }

  loginWithGoogle() {
    this.authService.loginWithGoogle();
  }

  onGoogleSignInCallback(response: any) {
    console.log(response);
    if (response.status === 'OK') {
      // Handle successful sign-in here.
      const userToken = response.credential;
      // Now you can send userToken to your server for authentication.
    } else {
      // Handle sign-in errors here.
      console.error('Sign-in error:', response.error);
    }
  }


  logout() {
    this.bandonAuthService.logout();
  }

  resetPassword() {
    console.log('Reset Password');
  }

  openRegisterForm() {
    this.modalService.open(RegisterModalComponent, { 'centered': true } )
  }

  openWebApp() {
    const token = this.bandonAuthService.getToken();
    const refresh_token = this.bandonAuthService.getRefreshToken();
    if(token && refresh_token) {
      const baseURL = `${this.appUrl}/collection/user/login-token`;

      // Construct the query parameters
      const queryParams = new URLSearchParams({ token, refresh_token });

      const url = `${baseURL}?${queryParams}`;
      window.open(url, "_blank");
    }

  }
}
