<div class="grid"
    [class.bg-primary]="background==='primary'"
    [class.bg-tertiary]="background==='tertiary'"
>
  @if(level==1) {
    <div class="g-col-1 d-none d-md-block"></div>
  } @else {
    <div class="g-col-2 d-none d-md-block"></div>
  }
  <p [class]="titleClass">
    <ng-content></ng-content>
  </p>
</div>
