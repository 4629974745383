import { inject, Injectable } from '@angular/core';
import { LibraryService } from './library.service';
import { AuthenticationService } from 'bandon-shared';
import { DocumentService } from './document.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {
  documentService = inject(DocumentService)
//  translateService = inject(TranslateService)

  public showTopNavAndFooter = true;

  constructor(
    private authService: AuthenticationService,
    private libraryService: LibraryService,
  ) { }

  async init() {
    await this.libraryService.init();
    return Promise.resolve().then(() => {});
  }
}
