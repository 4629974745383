import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { environment } from '../../../environments/environment';
import { DocumentService } from '../../services/document.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-gen-reset-password',
    templateUrl: './gen-reset-password.component.html',
    styleUrls: ['./gen-reset-password.component.scss'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, TranslateModule]
})
export class GenResetPasswordComponent implements OnInit, OnDestroy {
  documentService = inject(DocumentService)

  msgSent = false;
  msgSending = false;

  private unsubscribe$ = new Subject<void>();

  contactForm = new FormGroup({
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]
    ),
    mailadd: new FormControl('', [
      this.forbiddenFieldValidator()
    ])
  });

  constructor(private httpClient: HttpClient) {}

  ngOnInit(): void {
    setTimeout(() => {
      if(this.documentService.window) {
        this.documentService.window.scroll(0, 0);
      }
    });
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  submit() {
    if (this.contactForm.valid) {
      this.msgSending = true;

      const email = this.contactForm.get('email')?.value;

      const url = `${environment.apiURL}/messages`

      const headers = new HttpHeaders()
      .set('Authorization', 'Bearer '+environment.apiKey)
      .set('content-type', 'application/json');
      const body = JSON.stringify({email});

      this.httpClient.post<any>(environment.apiURL+'/auth/reset_password', body, {headers})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.msgSent = true;
            this.msgSending = false;
          },
          error: () => {
            this.msgSending = false;
          }
        });
    }
  }

  forbiddenFieldValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? {forbiddenField: {value: control.value}} : null;
    };
  }

}
