import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { AssetLocalizationPipe } from 'src/app/core/pipes/asset-localization.pipe';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [RouterLink, ReactiveFormsModule, NgIf, TranslateModule, LocalizeRouterModule, AssetLocalizationPipe]
})
export class FooterComponent implements OnInit, OnDestroy {

  @ViewChild('newsletterEMail') emailInput: ElementRef<HTMLInputElement> | undefined;

  newsletterEMailForm = new FormGroup({
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]
    ),
  });
  emailAdding = false;
  emailAdded = false;

  private unsubscribe$ = new Subject<void>();

  get supportMail(): string {
    return environment.supportEMail
  }

  constructor(
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  registerNewsletter() {
    let email = this.emailInput?.nativeElement.value

    if(email && !this.newsletterEMailForm.get('email')?.invalid) {
      this.emailAdding = true;
      const url = `${environment.apiURL}/newsletter`

      const headers = new HttpHeaders().set('Authorization', `Bearer ${environment.bandon_api_w_key}`);

      const data = {
        email: email,
      };

      this.httpClient.put(url, data, {headers})
        .subscribe({
          next: resp => {
            if(this.emailInput) {
              this.emailInput.nativeElement.value = ''
            }
            this.emailAdding = false;
            this.emailAdded = true;
          },
          error: err => {
            this.emailAdding = false;
            console.log(err)
          }
        });
    } else {
      console.log('No valid E-Mail')
    }
  }

  private generateFormData(data: any): string {
    return Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
  }

}
