import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FeatureElementComponent } from './feature-element/feature-element.component';
import { CommonModule } from '@angular/common';
import { TextBlockComponent } from '../text-block/text-block.component';
import { Subject } from 'rxjs';
import { DocumentService } from '../../../services/document.service';
import { SEOService } from '../../../services/seoservice.service';
import { NgbCarousel, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { Feature } from 'src/app/shared/interfaces/feature';
import { features } from 'src/app/shared/data/features.data'; // Import features from the separate file

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss'],
    standalone: true,
    imports: [
      TextBlockComponent,
      CommonModule,
      FeatureElementComponent,
      TranslateModule,
      NgbCarouselModule
    ],
})
export class FeaturesComponent implements OnInit, OnDestroy {
  seoService = inject(SEOService)
  translateService = inject(TranslateService)
  documentService = inject(DocumentService)

  @Input() background: 'none' | 'primary' | 'secondary' | 'tertiary' = 'none';

  featureIndex = 0;

  @ViewChild('featureCarousel') featureCarousel!: NgbCarousel;


  features: Feature[] = features;

  get selectedFeature() {
    return this.features[this.featureIndex];
  }

  get bgClass() {
    if(this.background === 'primary') {
      return 'bg-primary';
    }
    if(this.background === 'secondary') {
      return 'bg-secondary';
    }
    if(this.background === 'tertiary') {
      return 'bg-tertiary';
    }
    return 'bg-none';
  }

  private unsubscribe$ = new Subject<void>();

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      if(this.documentService.window) {
        this.documentService.window.scroll(0, 0);
      }
    });

    this.seoService.updateTitle('TITLES.FEATURES');
    this.seoService.updateDescription('DESCRIPTIONS.FEATURES');

  }


  getBackground(index: number): string {
    const type = index % 2;
    if(type===1) {
      return 'none';
    }
    return 'tertiary'
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showFeature(index: number) {
    if(this.featureCarousel) {
      this.featureCarousel.select(`ngb-slide-${index}`);
      this.featureIndex = index;
    }
  }

}
