<app-app-mockup
  hookLine="{{ 'LANDINGPAGES.JAZZ.HOOKLINE' | translate }}"
  description="{{ 'LANDINGPAGES.JAZZ.DESCRIPTION' | translate }}"
  bannerline1="{{ 'LANDINGPAGES.JAZZ.BANNERLINE1' | translate }}"
  bannerline2="{{ 'LANDINGPAGES.JAZZ.BANNERLINE2' | translate }}"
></app-app-mockup>

<div class="bg-tertiary text-black">
  <div class="grid">
    <div class="g-col-3 d-none d-md-block"></div>
    <p class="dyn-font-6xl g-col-12 g-col-md-8 px-4 my-4">{{ 'LANDINGPAGES.JAZZ.REGISTERNOW' | translate }}</p>
  </div>
  <div class="grid">
    <div class="border-black g-col-3 border-top mt-2 mr-2 d-none d-md-block"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-8 pb-5">
      {{ 'LANDINGPAGES.JAZZ.DISCOVERNOW' | translate }}
      <app-register [freeTune]="free_tune_id"></app-register>
  </div>
  <div class="border-black g-col-3 border-top mt-2 mr-2 d-none d-md-block"></div>
  <div class="px-4 text-lg g-col-12 g-col-md-8 pb-5">
    {{ 'LANDINGPAGES.JAZZ.NOTYET' | translate }}
    <app-register-newsletter></app-register-newsletter>
  </div>
</div>

<app-get-bandon-now></app-get-bandon-now>
