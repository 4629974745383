import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthenticationService, UserModel } from 'bandon-shared';
import { BehaviorSubject, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../language.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  httpClient = inject(HttpClient)
  authService = inject(AuthenticationService)
  languageService = inject(LanguageService)

  user?: UserModel;
  private userSubject = new BehaviorSubject<UserModel | any>(null);
  user$ = this.userSubject.asObservable();

  constructor() {
    this.authService.isAuthenticated.subscribe(auth => {
      if(auth) {
        this.getUserData();
      } else {
        this.user = undefined;
      }
    })
  }

  deleteUser() {
    const token = this.authService.getToken()
    if(this.user && token) {
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      const user_id = this.authService.getUserID();

      return this.httpClient.put(`${environment.apiURL}/users/${user_id}/deletion`, null, {headers});
    }
    return of(null)
  }

  updateUserData() {
    const token = this.authService.getToken()
    if (!this.user || !token) {
      return;
    }

    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    const formData = new FormData();
    formData.append('name', JSON.stringify(this.user.name));
    formData.append('email', JSON.stringify(this.user.email));
    formData.append('language', JSON.stringify(this.user.language));
    formData.append('newsletter', JSON.stringify(this.user.newsletter));
    formData.append('instruments', JSON.stringify(this.user.instruments));

    const data = {
      name: this.user.name,
      email: this.user.email,
      language: this.user.language,
      newsletter: this.user.newsletter,
      instruments: this.user.instruments
    };

    return this.httpClient.post<UserModel>(environment.apiURL+`/users/${this.user.uid}`, data, {headers})
      .pipe(
        tap(resp => {
          this.user = resp;
          this.userSubject.next(resp);
        })
      );
  }

  public getUserData() {
    const token = this.authService.getToken()
    if (!token) {
      return;
    }

    // Get user Data
    const user_id = this.authService.getUserID();
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get<UserModel>(environment.apiURL+`/users/${user_id}`, {headers})
//    return this.httpClient.get<UserModel>(environment.apiURL+`/users/${user_id}`, {headers})
      .subscribe({
        next: resp => {
          if(resp) {
            this.user = resp;
            this.userSubject.next(resp);
            this.languageService.setLanguage(this.user.language);
          }
        },
        error: () => {}
      });
  }
}
