<div class="ratio ratio-4x3">
  <img [src]="imgSrc" class="object-fit-cover">
</div>
<div class="grid my-2 px-2" *ngIf="text" >
  <div class="g-col-3 d-flex align-items-center">
    <i class="bi bi-arrow-right fs-2"></i>
  </div>
  <div class="g-col-8 d-flex align-items-center text-wrap fw-bold">
    {{ text | translate }}
  </div>
</div>
