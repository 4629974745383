import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { KeyvisualImgComponent } from 'src/app/components/general/keyvisual-img/keyvisual-img.component';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-team',
  standalone: true,
  imports: [
    CommonModule,
    KeyvisualImgComponent,
    TranslateModule
  ],
  templateUrl: './team.component.html',
  styleUrl: './team.component.scss'
})
export class TeamComponent implements OnInit{
  private documentService = inject(DocumentService)

  ngOnInit(): void {
    setTimeout(() => {
      if(this.documentService.window) {
        this.documentService.window.scroll(0, 0);
      }
    });

  }
}
