<div class="my-4 my-sm-40 px-4">
  <div class="grid">
    <div class="g-col-1 d-none d-md-block"></div>
    <p class="g-col-12 g-col-md-11 dyn-font-8xl">
      MIDI Import
    </p>
  </div>

  <div class="grid">
    <div class="g-col-3 mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <p>
        Der Import von MIDI Daten in band-on ist ein Werkzeug, welches den schnellen und effizienten Import von Arrangement-Informationen erlaubt. Allerdings erfordert das Erstellen eines entsprechenden MIDI-Files know how bei der Bedienung von anderen Produktionsprogrammen (Pro Tools, Logic, Sibelius,...). Ein MIDI File kann Informationen über das Tempo, die Taktart und die Teile des Arrangements angegeben werden.
      </p>
      <ul>
        <li>
          <b>Tempo</b>: Die im MIDI-FIle gespeicherte Information über das Tempo wird zusammen mit der Information über die Taktart zur berechnung der Taktzahlen verwendet.
        </li>
        <li>
          <b>Taktart</b>: Die im MIDI-FIle gespeicherte Information über die Taktart wird zusammen mit der Information über das Tempo zur berechnung der Taktzahlen verwendet.
        </li>
        <li>
          <b>Textmarker</b>: Die Textmarker dienen dazu, Teile im Arrangement zu definieren (z.B. Intro, Thema 1, Verse 2,...)
        </li>
      </ul>
      <h2>Textmarker</h2>
      <p>
        Textmarker definieren den Start eines neuen Arrangement-Teils (z.B. Intro, Thema 1, Verse 2,...) und können zudem Zusatzfunktionen übernehmen.
      </p>
      <h3>Mehrere Marker an derselben Stelle</h3>
      <p>
        Wenn zu einem Zeitpunkt mehrere Marker gesetzt werden müssen, so müssen Diese mit ";" getrennt werden (z.B. Intro;Head In;Head Out).
      </p>
      <h3>Spezielle Textmarker</h3>
      <h4>Filestart</h4>
      <p>

      </p>
      <h4>Start</h4>
      <p>

      </p>
      <h4>End</h4>
      <h4>Pickup</h4>
      <p>

      </p>
      <h4>Takt</h4>
      <p>

      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>
</div>
