import { Feature } from "../interfaces/feature";

export const features: Feature[] = [
  {
    title: "FEATURES.PLAYER",
    description: "FEATURES.DESCPLAYER",
    helperParts: [
      {
        img: "/assets/Features/Player_Website.png",
        texts: [
          {
            text: "FEATURES.PLAYPAUSE",
            top: 89.8,
            left: 40.5,
            width: 20,
            height: 7
          },
          {
            text: "FEATURES.COUNTOFF",
            top: 89.8,
            left: 3.4,
            width: 20,
            height: 7
          },
          {
            text: "FEATURES.RECORDONOFF",
            top: 89.8,
            left: 21.6,
            width: 20,
            height: 7
          },
          {
            text: "FEATURES.LOOPONOFF",
            top: 89.8,
            left: 58.4,
            width: 20,
            height: 7
          },
          {
            text: "FEATURES.CHANGETEMPO",
            top: 89.8,
            left: 78,
            width: 20,
            height: 7
          },
          {
            text: "FEATURES.JUMPTOPREVIOUS",
            top: 83,
            left: 5.5,
            width: 14,
            height: 7
          },
          {
            text: "FEATURES.CURRENTPART",
            top: 83,
            left: 40.5,
            width: 20,
            height: 7
          },
          {
            text: "FEATURES.JUMPTONEXT",
            top: 83,
            left: 81,
            width: 14,
            height: 7
          },
          {
            text: "FEATURES.CURRENTBAR",
            top: 76.7,
            left: 40.5,
            width: 20,
            height: 7
          },
          /*{
            text: "FEATURES.OPENINFO",
            top: 8,
            left: 79,
            width: 12,
            height: 6.5
          },*/
          {
            text: "FEATURES.OPENCOVERTEXT",
            top: 16,
            left: 12,
            width: 76,
            height: 39
          },
        ]
      }
    ]
  },
  {
    title: "FEATURES.MIXER",
    description: "FEATURES.DESCMIXER",
    helperParts: [
      {
        img: "/assets/Features/Player_Website.png",
        texts: [
          {
            text: "FEATURES.OPENMIXER",
            top: 70.6,
            left: 6,
            width: 29.4,
            height: 7
          },
        ]
      },
      {
        img: "/assets/Features/Mixer_Website.png",
        texts: [
          {
            text: "FEATURES.CHANGEVOLUME",
            top: 16,
            left: 32,
            width: 50,
            height: 6.3
          },
          {
            text: "FEATURES.MUTEINSTRUMENT",
            top: 16,
            left: 23,
            width: 14,
            height: 6.3
          },
          {
            text: "FEATURES.SOLOINSTRUMENT",
            top: 16,
            left: 76,
            width: 14,
            height: 6.3
          },
          {
            text: "FEATURES.MUTEGROUP",
            top: 62.4,
            left: 23,
            width: 14,
            height: 6.3
          },
          {
            text: "FEATURES.SOLOGROUP",
            top: 62.4,
            left: 75.8,
            width: 14,
            height: 6.3
          },
        ]
      },
    ]
  },
  {
    title: "FEATURES.ARRANGEMENTVIEW",
    description: "FEATURES.DESCARRANGEMENT",
    helperParts: [
      {
        img: "/assets/Features/Player_Website.png",
        texts: [
          {
            text: "FEATURES.OPENARRANGEMENT",
            top: 70.6,
            left: 65,
            width: 29.4,
            height: 7
          },
        ]
      },
      {
        img: "/assets/Features/Arrangement_Website.png",
        texts: [
          {
            text: "FEATURES.SEEARRANGEMENT",
            top: 13,
            left: 7.5,
            width: 85,
            height: 58.3
          },
          {
            text: "FEATURES.JUMPTOPART",
            top: 33.5,
            left: 30,
            width: 40,
            height: 7
          },
          {
            text: "FEATURES.CHANGELOOP",
            top: 44.8,
            left: 77.7,
            width: 13,
            height: 7
          },
          {
            text: "FEATURES.REFINELOOP",
            top: 21.9,
            left: 7.5,
            width: 85,
            height: 7
          },
          {
            text: "FEATURES.LOOPNEXTPART",
            top: 83,
            left: 81,
            width: 14,
            height: 7
          },
        ]
      },
    ]
  },
  {
    title: "FEATURES.SHEETS",
    description: "FEATURES.DESCSHEET",
    helperParts: [
      {
        img: "/assets/Features/Player_Website.png",
        texts: [
          {
            text: "FEATURES.OPENMIXER",
            top: 70.6,
            left: 6,
            width: 29.4,
            height: 7
          }
        ]
      },
      {
        img: "/assets/Features/Mixer_Website.png",
        texts: [
          {
            text: "FEATURES.CLICKINSTRUMENT",
            top: 27.6,
            left: 8.5,
            width: 15,
            height: 7.5
          },
        ]
      },
      {
        img: "/assets/Features/Mixer_Website.png",
        texts: [
          {
            text: "FEATURES.CHOOSESHEET",
            top: 27.6,
            left: 8.5,
            width: 15,
            height: 7.5
          },
        ]
      },
      {
        img: "/assets/Features/Mixer_Website.png",
        texts: [
          {
            text: "FEATURES.SEESHEET",
            top: 27.6,
            left: 8.5,
            width: 15,
            height: 7.5
          },
        ]
      },
    ]
  },
  {
    title: "FEATURES.GROUPS",
    description: "FEATURES.DESCGROUP",
    helperParts: [
      {
        img: "/assets/Features/Profil.png",
        texts: [
          {
            text: "FEATURES.OPENGROUPS",
            top: 46.2,
            left: 8.5,
            width: 83,
            height: 6.0
          },
        ]
      },
      {
        img: "/assets/Features/Gruppen.png",
        texts: [
          {
            text: "FEATURES.OPENGROUP",
            top: 27.0,
            left: 4.5,
            width: 90,
            height: 10.0
          },
        ]
      },
      {
        img: "/assets/Features/Gruppe.png",
        texts: [
          {
            text: "FEATURES.EDITGROUP",
            top: 7.0,
            left: 82.3,
            width: 12,
            height: 6.0
          },
          {
            text: "FEATURES.SEARCHUSER",
            top: 46.5,
            left: 5.5,
            width: 30,
            height: 11.5
          },
          {
            text: "FEATURES.SENDINVITATION",
            top: 46.5,
            left: 34.7,
            width: 30,
            height: 11.5
          },
          {
            text: "FEATURES.SHOWGROUPQR",
            top: 46.5,
            left: 64,
            width: 30,
            height: 11.5
          },
          {
            text: "FEATURES.EDITGROUPUSER",
            top: 77.1,
            left: 4.5,
            width: 90,
            height: 6.5
          },
        ]
      },
      {
        img: "/assets/Features/Gruppenuser.png",
        texts: [
          {
            text: "FEATURES.MAKEADMIN",
            top: 68.0,
            left: 4.5,
            width: 90,
            height: 30.0
          },
        ]
      },
    ]
  },
  {
    title: "FEATURES.LIBRARY",
    description: "FEATURES.DESCLIBRARY",
    helperParts: [
      {
        img: "/assets/Features/Library_Website_2.png",
        texts: [
          {
            text: "FEATURES.OPENSETLISTS",
            top: 14,
            left: 7,
            width: 87,
            height: 7
          },
          {
            text: "FEATURES.OPENCOLLECTIONS",
            top: 19,
            left: 7,
            width: 87,
            height: 7
          },
          {
            text: "FEATURES.OPENTUNES",
            top: 24,
            left: 7,
            width: 87,
            height: 7
          },
          {
            text: "FEATURES.TONALITYTEMPO",
            top: 41,
            left: 26,
            width: 16,
            height: 4
          },
          {
            text: "FEATURES.DOWNLOADTUNE",
            top: 64.5,
            left: 78.5,
            width: 11,
            height: 6
          },
        ]
      },
    ]
  },
];
