<div class="my-4 my-sm-40 px-4">
  <div class="grid">
    <div class="g-col-1 d-none d-md-block"></div>
    <p class="g-col-12 g-col-md-11 dyn-font-8xl">
      Datenschutzerklärung
    </p>
  </div>

  <div class="grid">
    <div class="g-col-3 mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <p>
        Die Personal Music Tools GmbH (nachfolgend Personal Music Tools, wir oder uns) respektiert die Privatsphäre von Personen, die unsere Dienste nutzen.
      </p>
      <p>
        Nachfolgend beschreiben wir, was wir mit Ihren Personendaten tun, wenn Sie unsere Website oder unsere Applikationen verwenden, unsere Dienstleistungen oder Produkte beziehen, anderweitig mit uns im Rahmen eines Vertrags in Verbindung stehen, mit uns kommunizieren oder sonst mit uns zu tun haben. Unter Personendaten werden alle Angaben verstanden, die sich auf eine bestimmte oder bestimmbare Person beziehen (nachfolgend Daten).
      </p>
      <p>
        Wir unterstehen grundsätzlich der Schweizerischen Datenschutzgesetzgebung (DSG). Soweit anwendbar halten wir uns auch an die EU-Datenschutz-Grundverordnung (DSGVO).
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid" id="cap1">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>1.	Wer ist für die Bearbeitung Ihrer Daten verantwortlich?</h2>
      <p>
        Für die Datenbearbeitung gemäss dieser Datenschutzerklärung ist die Personal Music Tools verantwortlich. Wenn Sie datenschutzrechtliche Anliegen haben, können Sie uns diese an fol-gender Kontaktadresse mitteilen:
      </p>
      <p>
        Personal Music Tools GmbH<br>
        Zentweg 21i<br>
        3006 Bern<br>
        <a [href]="'mailto:'+supportMail+'?subject=Nachricht von der band-on Site'" class="text-secondary">{{ supportMail }}</a><br>
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid" id="cap2">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>2.	Welche Daten bearbeiten wir?</h2>
      <p>
        Wir bearbeiten zwei Kategorien von Daten über Sie:
      </p>
      <ul>
        <li>
          <b>Daten, die Sie uns bei der Nutzung bereitstellen:</b> Wenn Sie für die Nutzung unserer Website oder Applikationen ein Konto erstellen, stellen Sie uns folgende Daten zur Verfügung:<br>
          Ihren Nutzernamen, Ihre E-Mail-Adresse und ein Passwort.<br>
          Sie können uns zudem Daten zur Verfügung stellen, wenn Sie mit uns kommunizieren (Kontaktformular, E-Mail, Chat etc.).
        </li>
        <li>
          <b>Daten, die wir bei der Nutzung unserer Dienste erheben:</b> Wir erfassen bei Ihrer Nutzung unserer Dienste Ihre Suchanfragen und welche Audioaufnahmen Sie abspielen und herunterladen.
        </li>
      </ul>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid" id="cap3">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>3.	Zu welchen Zwecken bearbeiten wir Ihre Daten?</h2>
      <p>
        Wir verwenden Ihre Daten für:
      </p>
      <ul>
        <li>
          die <b>Kommunikation</b> mit Ihnen, insbesondere zur Beantwortung von Anfragen und der Geltendmachung Ihrer Rechte und um Sie bei Rückfragen zu kontaktieren;
        </li>
        <li>
          <b>Marketingzwecke</b> und zur Beziehungspflege, z.B. um Ihnen personalisierte Werbung zu Produkten und Dienstleistungen von uns und von Dritten zu senden (z.B. Mailings mit Abmelde-Link/Option), wobei wir Ihre Daten diesen Dritten nicht bekanntgeben;
        </li>
        <li>
          unser <b>Risikomanagement</b> und im Rahmen einer umsichtigen Unternehmensführung.
        </li>
      </ul>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid" id="cap4">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>4.	Auf welcher Grundlage bearbeiten wir Ihre Daten?</h2>
      <p>
        Wir bearbeiten Ihre Daten nur gestützt auf folgende Grundlagen:
      </p>
      <ul>
        <li>
          <b>Ihre Einwilligung</b>, wobei Sie jederzeit jegliche Einwilligungen in unsere Datenbearbei-tung für die Zukunft widerrufen können (vgl. <a class="link-primary band-on-link" (click)="scrollToFragment('cap8')">Ziff. 8</a>);
        </li>
        <li>
          ohne Ihre Einwilligung nur, wenn wir ein <b>berechtigtes Interesse</b> (vgl. <a class="link-primary band-on-link" (click)="scrollToFragment('cap3')">Ziff. 3</a>) an der Datenbearbeitung haben (dazu gehört auch die Einhaltung gesetzlicher Vorschriften).
        </li>
      </ul>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid" id="cap5">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>5.	Wem geben wir Ihre Daten bekannt?</h2>
      <p>
        Wir geben Personendaten nicht an Dritte weiter, ausser dies ist gesetzlich vorgeschrieben oder durch einen richterlichen Entscheid so angeordnet. Davon ausgenommen ist die Weitergabe an Dienstleistungspartner (z.B. IT-Provider oder Versicherungen) oder Geschäftspartner (z.B. Lieferanten, Kunden, Marketing- und Projektpartner), welche uns insbesondere im Marketingbereich, für die Analyse bestimmter technischer Daten und für Funktionen der Verarbeitung und/oder Speicherung von Daten unterstützend zur Seite stehen.
      </p>
      <p>
        Es ist möglich, dass für die Weitergabe an Dritte eine Übermittlung Ihrer Daten ins Ausland notwendig ist. Dies geschieht ebenfalls nur im oben genannten Umfang.<br>
        Befindet sich ein Empfänger in einem Land ohne angemessenen gesetzlichen Datenschutz, verpflichten wir den Empfänger vertraglich zur Einhaltung des anwendbaren Datenschutzes soweit er nicht bereits einem gesetzlich anerkannten Regelwerk zur Sicherstellung des Datenschutzes unterliegt und wir uns nicht auf eine Ausnahmebestimmung stützen können.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid" id="cap6">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>6.	Wie lange bearbeiten wir Ihre Daten?</h2>
      <p>
        Wir bearbeiten Ihre Daten so lange, wie es unsere Bearbeitungszwecke, die gesetzlichen Aufbewahrungsfristen und unsere berechtigten Interessen der Bearbeitung zu Dokumentations- und Beweiszwecken es verlangen oder eine Speicherung technisch bedingt ist.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid" id="cap7">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>7.	Wie schützen wir Ihre Daten?</h2>
      <p>
        Wir treffen angemessene Sicherheitsmassnahmen, um die Vertraulichkeit, Integrität und Verfügbarkeit Ihrer Personendaten zu wahren, um sie gegen unberechtigte oder unrechtmässige Bearbeitungen zu schützen und den Gefahren des Verlusts, einer unbeabsichtigten Veränderung, einer ungewollten Offenlegung oder eines unberechtigten Zugriffs entgegenzuwirken.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid" id="cap8">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>8.	Welche Rechte haben Sie?</h2>
      <p>
        Sie haben jederzeit das Recht auf Auskunft, Berichtigung, Löschung, Datenübertragbarkeit und das Recht auf Einschränkung und sonst dem Widerspruch gegen unsere Datenbearbeitungen.
      </p>
      <p>
        Für Ausübung dieser Rechte müssen Sie Ihre Identität uns gegenüber eindeutig nachweisen (z.B. durch eine Ausweiskopie). Zur Geltendmachung Ihrer Rechte können Sie uns unter der in <a class="link-primary band-on-link" (click)="scrollToFragment('cap1')">Ziffer 1</a> angegebenen Adresse kontaktieren.
      </p>
      <p>
        Sie haben überdies das Recht, Ihre Ansprüche gerichtlich durchzusetzen oder bei der zuständigen Datenschutzbehörde eine Beschwerde einzureichen. Die zuständige Datenschutzbehörde der Schweiz ist der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte (<a target="_blank" href="http://www.edoeb.admin.ch">http://www.edoeb.admin.ch</a>).
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid" id="cap9">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>9.	Cookies</h2>
      <p>
        Wir benutzen Cookies, um einen einfacheren erneuten Zugang auf unsere Dienste zu gewährleisten. Cookies sind Textdateien, die beim Besuch von unserer Website oder unseren Applikationen auf Ihrem Gerät gespeichert werden. Wir benutzen Cookies bei der Kontoanmeldung und bei Suchanfragen an unsere Datenbank.
      </p>
      <p>
        Sie können die Speicherung der Cookies durch bestimmte Einstellungen Ihres Browsers verhindern, wir weisen jedoch darauf hin, dass dadurch gegebenenfalls nicht alle Funktionen unserer Dienste nutzbar sind.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid" id="cap10">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>10.	Einbindung von Angeboten Dritter</h2>
      <p>
        Unsere Dienste sind für die Zahlungsoptionen der App-Plattformen mit Funktionen und Systemen Dritter (Apple, Google, Glassfy) vernetzt. Die Einbindung dieser Zahlungsoptionen ist klar ersichtlich durch ein jeweiliges Pop-Up des Dienstes des Dritten. Die Dritten können bei diesen Einbindungen weitere Daten von Ihnen erfassen und speichern.<br>
        Wir haben über diese Datenerfassung und -verarbeitung keine Kontrolle und übernehmen daher keine Verantwortung oder Haftung, sondern verweisen auf die jeweiligen Allgemeinen Geschäftsbedingungen und Datenschutzerklärungen der Dritten.
      </p>
      <ul>
        <li>
          Apple Inc.
          <ul>
            <li>AGB: <a href="https://www.apple.com/chde/legal/" target="_blank">https://www.apple.com/chde/legal/</a></li>
            <li>Datenschutzerklärung: <a href="https://www.apple.com/legal/privacy/" target="_blank">https://www.apple.com/legal/privacy/</a></li>
          </ul>
        </li>
        <li>
          Google LLC
          <ul>
            <li>AGB: <a href="https://policies.google.com/terms" target="_blank">https://policies.google.com/terms</a></li>
            <li>Datenschutzerklärung: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a> </li>
          </ul>
        </li>
        <li>
          Glassfy
          <ul>
            <li>AGB: <a href="https://glassfy.io/terms_and_conditions/" target="_blank">https://glassfy.io/terms_and_conditions/</a></li>
            <li>Datenschutzerklärung: <a href="https://glassfy.io/privacy_policy/" target="_blank">https://glassfy.io/privacy_policy/</a> </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid" id="cap11">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>11.	Änderungen</h2>
      <p>
        Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils aktuelle, auf unserer Website und in unseren Applikationen publizierte Fassung.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>
</div>
