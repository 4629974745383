import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TitleComponent } from '../general/text-block/title/title.component';
import { TextBlockComponent } from '../general/text-block/text-block.component';
import { SubscriptionPanelComponent } from './subscription-panel/subscription-panel.component';
import { offers } from 'src/app/shared/data/subscriptionOffers.data';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [
    CommonModule,
    TitleComponent,
    TextBlockComponent,
    SubscriptionPanelComponent,
    TranslateModule
  ],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})
export class PaymentComponent {
  period: 'monthly' | 'annual' = 'monthly';

  offers = offers;

  setPeriod(period: 'monthly' | 'annual') {
    this.period = period;
  }
}
