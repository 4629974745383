import { SubscriptionOption } from 'bandon-shared';

export const offers: SubscriptionOption[] = [
  {
    showPopular: false,
    prices: undefined,
    callToAction: 'download',

    title: 'Kostenlos',
    description: 'Nutze band-on kostenlos mit den drei mitgelieferten Demo-Stücken.',
    features: [
      "Nutze unsere Musikplayer mit all den coolen Funktionen",
      "Trete einem Ensemble bei um deren Stücke zu üben",
      "Nimm dich selbst beim üben auf",
      "Nutze unseren Shop um weitere Stücke zu entdecken"
    ]
  },
  {
    showPopular: true,
    prices: undefined,
    callToAction: 'buy',

    title: 'Standard',
    description: 'Buche unser Abo und erhalte so die möglichkeit eigene Stücke hochzuladen und mit deinem Ensemble zu teilen.',
    features: [
      "Du hast Zugriff auf all die kostenlosen Funktionen",
      "Verwalte deine eigene Gruppe (Chor/Band/Ensemble oder andere)",
      "Lade deine eigenen Stücke hoch",
    ]
  },
  {
    showPopular: false,
    prices: undefined,
    callToAction: 'contact',

    title: 'Demo buchen',
    description: 'Du überlegst dir, band-on für dein Ensemble zu verwenden, möchtest aber gerne mehr wissen. Dann buche ein Meeting mit uns.',
    features: [
      "Erfahre in einem Online-Meeting mehr über band-on und trete mit uns in Kontakt."
    ]
  }
]
