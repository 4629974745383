import { Injectable, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  title = inject(Title)
  meta = inject(Meta)
  translate = inject(TranslateService)

  constructor() {
    this.initDetaultMetaInformation();
  }

  initDetaultMetaInformation() {
    this.meta.addTags([
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Personal Music Tools GmbH'}
    ]);
  }

  updateTitle(title: string) {
    this.translate.get(title)
      .subscribe(t => this.title.setTitle(t))
  }

  updateOgUrl(url: string) {
    this.translate.get(url)
      .subscribe(t => this.meta.updateTag({ name: 'og:url', content: t }))
  }

  updateDescription(desc: string) {
    this.translate.get(desc)
      .subscribe(t => {
//        console.log(`Set description: ${t}`)
        this.meta.updateTag({ name: 'description', content: t })
      })
  }

}
