import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-faq-panel',
    templateUrl: './faq-panel.component.html',
    styleUrls: ['./faq-panel.component.scss'],
    standalone: true,
    imports: [TranslateModule]
})
export class FaqPanelComponent {

  @Input() number = ""
  @Input() title = ""
//  @Input() text = ""

  get id(): string {
    return "collapse"+this.number;
  }
}
