import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import * as he from 'he';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DocumentService } from '../../services/document.service';
import { SEOService } from '../../services/seoservice.service';

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.scss'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, TranslateModule]
})
export class ImprintComponent implements OnInit, OnDestroy {
  seoService = inject(SEOService)
  translateService = inject(TranslateService)
  documentService = inject(DocumentService)

  msgSent = false;
  msgSending = false;

  contactForm = new FormGroup({
    name: new FormControl('', [
      Validators.required, Validators.minLength(3)]
    ),
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]
    ),
    msg: new FormControl('', [
      Validators.required, Validators.minLength(50)]
    ),
    mailadd: new FormControl('', [
      this.forbiddenFieldValidator()
    ])
  });

  private unsubscribe$ = new Subject<void>();

  get supportMail(): string {
    return environment.supportEMail
  }

  constructor(
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    setTimeout(() => {
      if(this.documentService.window) {
        this.documentService.window.scroll(0, 0);
      }
    });

    this.seoService.updateTitle('TITLES.IMPRINT');
    this.seoService.updateDescription('DESCRIPTIONS.IMPRINT');

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  submit() {
    if (this.contactForm.valid) {
      this.msgSending = true;

      const name = this.contactForm.get('name')?.value;
      const email = this.contactForm.get('email')?.value;
      const message = this.contactForm.get('msg')?.value;

      const url = `${environment.apiURL}/messages`

      const headers = new HttpHeaders().set('Authorization', `Bearer ${environment.bandon_api_w_key}`);

      const data = {
        email, name, msg: message
      };

      this.httpClient.post(url, data, {headers})
        .subscribe({
          next: () => {
            this.msgSent = true;
            this.msgSending = false;
          },
          error: () => {
            this.msgSending = false;
          }
        });
    }
  }

  forbiddenFieldValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? {forbiddenField: {value: control.value}} : null;
    };
  }

}
