import { LanguageService } from '../language.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { tap, Observable, of,  BehaviorSubject, takeUntil, filter, take } from 'rxjs';
import { Router } from '@angular/router';
import { LoginCredentials, LoginParams, RegisterParams, UserModel } from 'bandon-shared';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { DocumentService } from '../document.service';
import { environment } from '../../../environments/environment';
import { AuthenticationService as BandonAuthService } from 'bandon-shared';
import { response } from 'express';

declare const google: any; // Declare gapi to avoid TypeScript errors
declare const AppleID: any; // Declare the AppleID object

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  httpClient = inject(HttpClient)
  languageService = inject(LanguageService)
  router = inject(Router)
  socialAuthService = inject(SocialAuthService)
  documentService = inject(DocumentService)
  authService = inject(BandonAuthService)

  private apple_web_options = {
    clientId: 'ch.pmtbmbh.bandon.auth',
//    redirectURI: 'https://api.test.band-on.com/auth/apple-signin',
//    redirectURI: 'https://test.band-on.com/api/auth-apple-signin',
    redirectURI: `${this.documentService.window?.origin}/api/auth-apple-signin`,
    scopes: 'email name',
    state: 'state',
    responseMode: 'form_post'
  }

  private readonly appleScriptUrl: string = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
  private ready: Promise<boolean> = new Promise(resolve => {
    if (this.documentService.window) {
      const script = require('scriptjs');
      script(this.appleScriptUrl, () => resolve(true));
    } else {
      resolve(false);
    }
  });

  constructor(
  ) {
    this.socialAuthService.authState.subscribe((user) => {
      if(user) {
//        const headers = new HttpHeaders().set('Authorization', this.getIDToken());

        const data = {
          token: user.idToken,
        };

        this.httpClient.post(environment.apiURL+`/auth/google-signin`, data)
          .subscribe({
            next: (resp: any) => {
              if(resp.uid && resp.email && resp.name) {
                this.loginWithCredentials(resp.uid, resp.name, resp.email);
              }
            },
            error: err => console.error(err)
          });

      }
    });

    this.ready.then(isReady => {
      if (isReady) {
          this.initApple();
      }
    });

    this.documentService.hasLocalStorage
      .pipe(
        filter(value => value === true), // Only pass through `true` values
        take(1) // Take only the first one
      )
      .subscribe(() => this.authService.checkAuthenticated())
  }

  private initApple(): void {
    AppleID.auth.init({
      clientId: this.apple_web_options.clientId,
      scope: this.apple_web_options.scopes,
      redirectURI: this.apple_web_options.redirectURI,
      state: this.apple_web_options.state,
      nonce: 'test',
      usePopup: true
    });
  }

  get loginWithSocial() {
    if(this.documentService.localStorage) {
      const stringValue = this.documentService.localStorage.getItem('isSocialLogin') || '';
      return (/true/i).test(stringValue);
    }
    return false;
  }

  set loginWithSocial(isSocialLogin: boolean) {
    if(this.documentService.localStorage) {
      this.documentService.localStorage.setItem('isSocialLogin', String(isSocialLogin));
    }
  }

  login({ email, password }: { email: string, password: string }) {
    const params: LoginParams = {
      email: email,
      password: password
    }
    this.loginWithSocial = false;
    return this.authService.login(params)
  }

  async loginWithApple() {
    try {
      this.initApple();
      const data = await AppleID.auth.signIn();
      const postData = {
        id_token: data.authorization.id_token
      };


      this.httpClient.post<any>(environment.apiURL+'/auth/apple-signin', postData)
        .subscribe(response => {
          this.loginWithCredentials(response.uid, response.name, response.email)
        });
    } catch ( error ) {
      console.error(error);
    }
  }

  loginWithGoogle() {
    console.log('Login with google');
    google.accounts.id.initialize({
      client_id: '589593319665-kf22t3h6dhuqkcle711rm7qaieau17n7.apps.googleusercontent.com',
      callback: this.onGoogleSignInCallback.bind(this),
      auto_select: true, // Optionally, enable auto-selection of the user's Google Account
      cancel_on_tap_outside: true,
    });
    google.accounts.id.prompt(); // Show the Google sign-in prompt
  }

  onGoogleSignInCallback(response: any) {
    if (response.status === 'OK') {
      // Handle successful sign-in here.
      const userToken = response.credential;
      // Now you can send userToken to your server for authentication.
    } else {
      // Handle sign-in errors here.
      console.error('Sign-in error:', response.error);
    }
  }

  loginWithCredentials(uid: string, name: string, email: string) {
    this.loginWithSocial = true;

    const params: LoginCredentials = {
      uid: uid,
      name: name,
      email: email
    }
    this.authService.loginWithCredentials(params)
      .subscribe(response => {
        this.router.navigateByUrl('userdata');
      })
  }

}
