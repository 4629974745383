import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Feature, FeatureHelpPart, FeatureHelpText } from '../../../../shared/interfaces/feature';
import { AssetLocalizationPipe } from 'src/app/core/pipes/asset-localization.pipe';

const fadeInOut = trigger('fadeInOut', [
  state(
    'open',
    style({
      opacity: 1
    })
  ),
  state(
    'close',
    style({
      opacity: 0
    })
  ),
  transition('open=>close', [animate('0.2s ease-out')]),
  transition('close=>open', [animate('0.2s ease-in')])
]);

@Component({
    selector: 'app-feature-element',
    templateUrl: './feature-element.component.html',
    styleUrls: ['./feature-element.component.scss'],
    animations: [fadeInOut],
    standalone: true,
    imports: [
      CommonModule,
      TranslateModule,
      AssetLocalizationPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FeatureElementComponent {

  @Input() background: 'none' | 'primary' | 'secondary' | 'tertiary' = 'none';
  @Input() feature!: Feature;

  @ViewChild('swipercontainer') swiperEl: ElementRef | undefined;

  helperIndex = 0;
  textIndex = 0;
  isChanging = false;

  get bgClass(): string {
    let bgString = 'bg-white'
    let colorString = 'text-black'

    if (this.background=='primary') {
      bgString = 'bg-primary'
      colorString = 'text-tertiary'
    }
    else if (this.background=='tertiary') {
      bgString = 'bg-tertiary'
      colorString = 'text-black'
    }
    return bgString+' '+colorString+' pb-4';
  }

  get titleClass(): string {
    let bold = '';
    if (this.background=='primary') {
      bold = 'fw-bold font-black'
    }

    return bold+' dyn-font-7xl g-col-12 g-col-md-8 px-4 my-4 title-translated'
  }

  get borderClass(): string {
    let border = "border-black"
    if (this.background == 'primary') {
      border = "border-tertiary"
    }
    return border+' g-col-2 mt-2 mr-2 d-none d-md-block'
  }

  get activeHelp(): FeatureHelpPart | undefined {
    if(this.feature && this.feature.helperParts[this.helperIndex]) {
      return this.feature.helperParts[this.helperIndex];
    }
    return undefined;
  }

  get activeText(): FeatureHelpText | undefined {
    if(this.activeHelp && this.activeHelp.texts[this.textIndex]) {
      return this.activeHelp.texts[this.textIndex];
    }
    return undefined;
  }


  get activeImg(): string {
    if(this.activeHelp) {
      return this.activeHelp.img;
    }
    return '';
  }

  isActiveText(help: FeatureHelpPart, text: FeatureHelpText) {
    return help === this.activeHelp && text == this.activeText;
  }

  openText(help: FeatureHelpPart, text: FeatureHelpText) {
    const index = this.feature.helperParts.indexOf(help);
    if(index>=0) {
      this.helperIndex = index;
      this.isChanging = true;
      const help = this.feature.helperParts[index];
      this.textIndex = help.texts.indexOf(text);
      this.setSlide(this.getSlideIndex());
    }
  }

  getSlideIndex() {
    let slideIndex = 0;
    for(let i=0; i<this.helperIndex; i++) {
      slideIndex += this.feature.helperParts[i].texts.length;
    }
    slideIndex += this.textIndex;
    return slideIndex;
  }

  setSlide(index: number) {
    if(this.swiperEl) {
      this.swiperEl.nativeElement.swiper.slideTo(index);
    }
  }

  getSquareTop(help: FeatureHelpPart): number {
    if(help && help.texts && help.texts[this.textIndex]) {
      return help.texts[this.textIndex].top
    }
    return 0;
  }

  getSquareLeft(help: FeatureHelpPart): number {
    if(help && help.texts && help.texts[this.textIndex]) {
      return help.texts[this.textIndex].left
    }
    return 0;
  }

  getSquareWidth(help: FeatureHelpPart): number {
    if(help && help.texts && help.texts[this.textIndex]) {
      return help.texts[this.textIndex].width
    }
    return 0;
  }

  getSquareHeight(help: FeatureHelpPart): number {
    if(help && help.texts && help.texts[this.textIndex]) {
      return help.texts[this.textIndex].height
    }
    return 0;
  }

  slideChanged(event: any) {
    let index = 0;
    for(let i=0; i<this.feature.helperParts.length; i++) {
      for(let j=0; j<this.feature.helperParts[i].texts.length; j++) {
        if(index===event.detail[0].activeIndex) {
          this.helperIndex = i;
          this.textIndex = j;
          return;
        }
        index ++;
      }
    }
  }
}
