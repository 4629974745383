<nav class="navbar navbar-expand-md sticky-top bg-bright" style="z-index: 10;"
  [ngClass]="{ 'navbar-hidden': isNavbarHidden }"
>
  <div class="container-fluid">
    <div class="navbar-brand collapse navbar-collapse" [routerLink]="['/home'] | localize">
      <!--<div class="position-absolute top-0 start-5 bg-primary pt-4">-->
        <div class="position-absolute top-0 start-5 bg-primary pt-4" [ngClass]="{ 'navbar-hidden': isNavbarHidden }">
        <img src="/assets/Band-On_Logo_1024.png" style="width: 9.5rem">
      </div>
    </div>

    <button class="navbar-toggler border-0 rounded-0 ms-auto" type="button" data-bs-toggle="modal" data-bs-target="#navbarModal"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent" >
      <app-menu-overlay [showTopNav]="showTopNav" class="ms-auto"></app-menu-overlay>
    </div>
  </div>

  <!-- <div class="absolute top-0 left-10 w-40 h-48 bg-primary pt-4">
    <img src="/assets/Band-On_Logo_empty.png">
  </div> -->
</nav>
@if(isTestserver) {
  <div class="w-100 text-center text-bright" style="background-color: blue;">
    Testserver
  </div>
}

<div class="modal fade" id="navbarModal" tabindex="-1">
  <div class="modal-dialog modal-fullscreen fs-3">
    <div class="modal-content bg-primary text-light">
      <div class="modal-header border-0">
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" #closeModalBtn style="color: white;"></button>
      </div>
      <div class="modal-body">
        <app-menu-overlay
          [showTopNav]="showTopNav"
          [background]="'primary'"
          [mode]="'modal'"
          (close)="closeModal()"></app-menu-overlay>
      </div>
    </div>
  </div>
</div>
