<div class="card mb-3" style="max-width: 540px;">
  <div class="row g-0">
    <div class="col-md-4">
      <img src="{{ ImgSrc }}" class="img-fluid" alt="...">
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <h5>{{ tune.title }}</h5>
        <h6 class="card-subtitle mb-2 text-body-secondary">{{ description }}</h6>
      </div>
    </div>
  </div>
</div>
