<!--Register Modal-->
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="modal-header bg-primary text-light">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Registrierung</h1>
        <button type="button" class="btn-close btn-close-custom" (click)="activeModal.close()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="email" class="form-label">{{ 'REGISTER.EMAIL' | translate }}</label>
          <input id="email" class="form-control" formControlName="email" type="email" placeholder="name@example.com" autocomplete="email">
          <div *ngIf="(email?.invalid && email?.touched) || email?.dirty" class="alert p-0">
            <small *ngIf="email?.errors?.['required']" class="text-danger">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: emailString} }}</small>
            <small *ngIf="email?.errors?.['pattern']" class="text-danger">{{ 'REGISTER.PLVALIDMAIL' | translate }}</small>
          </div>
        </div>

        <div class="mb-3">
          <label for="name" class="form-label">{{ 'REGISTER.NAME' | translate }}</label>
          <div id="nameHelp" class="form-text" (oninput)="suggestUsername()">{{ 'REGISTER.NAMEINFO' | translate }}</div>
          <input id="name" class="form-control" formControlName="name" type="text" (input)="suggestUsername()" autocomplete="name">
          <div *ngIf="(name?.invalid && name?.touched) || name?.dirty" class="alert p-0">
            <small *ngIf="name?.errors?.['required']" class="text-danger">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: nameString} }}</small>
            <small *ngIf="name?.errors?.['minlength']" class="text-danger">{{ 'REGISTER.MINLENGTH' | translate: {min: 3} }}</small>
            <small *ngIf="name?.errors?.['profanity']" class="text-danger">{{ 'REGISTER.NAMEPROFANITYERROR' | translate }}</small>
          </div>
        </div>

        <div class="mb-3">
          <label for="username" class="form-label">{{ 'REGISTER.USERNAME' | translate }}</label>
          <div id="usernameHelp" class="form-text">{{ 'REGISTER.USERNAMEINFO' | translate }}</div>
          <input id="username" class="form-control" formControlName="username" autocomplete="username">
          <div *ngIf="(username?.invalid && username?.touched) || username?.dirty" class="alert p-0">
            <small *ngIf="username?.errors?.['required']" class="text-danger">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: usernameString} }}</small>
            <small *ngIf="username?.errors?.['minlength']" class="text-danger">{{ 'REGISTER.MINLENGTH' | translate: {min: 3} }}</small>
            <small *ngIf="username?.errors?.['usernameTaken']" class="text-danger">{{ 'REGISTER.USERNAMETAKEN' | translate }}</small>
            <small *ngIf="username?.errors?.['profanity']" class="text-danger">{{ 'REGISTER.NAMEPROFANITYERROR' | translate }}</small>
            <small *ngIf="suggestedUsernames.length > 0" class="text-danger">{{ 'REGISTER.SUGGESTIONS' | translate }}:</small>
            <small class="text-danger">
              @for (suggestion of suggestedUsernames; track $index) {
                @if($index < suggestedUsernames.length -1) {
                  {{ suggestion }},
                } @else {
                  {{ suggestion }}
                }
              }
            </small>
          </div>
        </div>

        <div class="mb-2">
          <label for="password" class="form-label">{{ 'REGISTER.PASSWORD' | translate }}</label>
          <input id="password" class="form-control" formControlName="password" type="password" autocomplete="new-password">
          <div *ngIf="(password?.invalid && password?.touched) || password?.dirty" class="alert p-0">
            <small *ngIf="password?.errors?.['minlength']" class="text-danger">
              {{ 'REGISTER.MINLENGTH' | translate: {min: 8} }}<br>
            </small>
            <small *ngIf="password?.errors?.['hasNumber']" class="text-danger">
              {{ 'REGISTER.NEEDSNUMBER' | translate: {min: 1} }}<br>
            </small>
            <small *ngIf="password?.errors?.['hasCapitalCase']" class="text-danger">
              {{ 'REGISTER.NEEDSCAPITAL' | translate: {min: 1} }}<br>
            </small>
            <small *ngIf="password?.errors?.['hasSmallCase']" class="text-danger">
              {{ 'REGISTER.NEEDSSMALL' | translate: {min: 1} }}<br>
            </small>
            <small *ngIf="password?.errors?.['hasSpecialCharacters']" class="text-danger">
              {{ 'REGISTER.NEEDSSPECIAL' | translate: {min: 1} }}<br>
            </small>
            <small *ngIf="password?.errors?.['required']" class="text-danger">
              {{ 'REGISTER.REQUIREDFIELD' | translate: {field: passwordString} }}
            </small>
          </div>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox"  id="newsletterCheckbox" formControlName="newsletter">
          <label class="form-check-label" for="newsletterCheckbox">
            {{ 'REGISTER.NEWSLETTER' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="agbCheckbox" formControlName="agb">
          <label class="form-check-label" for="agbCheckbox">
            <div class="ion-text-wrap">
              {{ 'REGISTER.SIGNIN' | translate }}
              <a [routerLink]="[ '/agb' | localize ]" target="_blank"
                ><span class="link">{{ 'REGISTER.AGB' | translate }}</span></a
              >
              {{ 'REGISTER.AND' | translate }}
              <a [routerLink]="[ '/privacypolicy' | localize ]" target="_blank"
                ><span class="link">{{ 'REGISTER.PRIVACY' | translate }}</span></a
              >
              {{ 'REGISTER.AGREED' | translate }}
            </div>
          </label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.close()">{{ 'REGISTER.CANCEL' | translate }}</button>
        <button type="submit" class="btn btn-primary" [disabled]="!form.valid">{{ 'REGISTER.REGISTER' | translate }}</button>
        <!--<button type="submit" class="btn btn-primary" [disabled]="!form.valid">{{ 'REGISTER.REGISTER' | translate }}</button>-->
      </div>
    </form>


