@if(!isSent) {
  <form [formGroup]="registerForm">
    <div class="grid mt-3">
      <div class="g-col-12 text-lg">
        <label for="newsletterEmailInput" class="form-label">{{ 'REGISTER.EMAIL' | translate }}</label>
        <input class="form-control" type="email" id="newsletterEmailInput" value="" placeholder="name@example.com" formControlName="email" autocomplete="email">
        <input class="form-control d-none" type="text" value="singers" formControlName="interest">
        <input type="email" class="form-control d-none" formControlName="mailadd">
        @if((email?.invalid && email?.touched) || email?.dirty) {
          <div class="mx-1 text-primary">
            @if(email && email.errors && email.errors['required']) {
              <small class="text-danger">
                {{ 'REGISTER.REQUIREDFIELD' | translate: {field: emailString} }}<br>
              </small>
            }
            @if(email && email.errors && email.errors['pattern']) {
              <small class="text-danger">{{ 'REGISTER.PLVALIDMAIL' | translate }}</small>
            }
            <!--question mark(?) is a safe navigation operator-->
          </div>
        }
      </div>
      <div class="g-col-12 text-lg">
        <label for="newsletterNameInput" class="form-label">{{ 'REGISTER.NAME' | translate }}</label>
        <input id="newsletterNameInput" class="form-control" type="text" placeholder="Name" formControlName="name" autocomplete="name">
        @if((name?.invalid && name?.touched) || name?.dirty) {
          <div class="mx-1 text-primary">
            @if(name && name.errors && name.errors['required']) {
              <small class="text-danger">
                {{ 'REGISTER.REQUIREDFIELD' | translate: {field: nameString} }}<br>
              </small>
            }
            @if(name && name.errors && name.errors['minlength']) {
              <small class="text-danger">{{ 'REGISTER.MINLENGTH' | translate: {min: 3} }}</small>
            }
          </div>
        }
      </div>
      <div class="g-col-12 text-lg">
        <button (click)="sendRegistration()" class="btn btn-primary" [disabled]="registerForm.invalid || isSending">
          @if(isSending) {
            <div class="spinner-border spinner-border-sm" role="status">
            </div>
          }
          {{ 'REGISTER.SIGNUPNEWSLETTER' | translate }}
        </button>
      </div>
      <noscript><a href="http://www.cleverreach.de">www.CleverReach.de</a></noscript>
    </div>
  </form>
  @if (hasError) {
    <p class="text-primary">{{ 'REGISTER.NEWSLETTERERR' | translate }}</p>
  }
}
@else if(isSent) {
  <p class="mt-3">{{ 'REGISTER.NEWSLETTERREG' | translate }}</p>
}
