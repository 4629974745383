<div class="accordion-item faq-header">
  <h2 class="accordion-header">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#'+id"
        aria-expanded="false" aria-controls="collapseOne">
        {{ number }}.  {{ title | translate }}
    </button>
  </h2>
  <div [id]="id" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
    <div class="accordion-body" #contentContainer>
      <ng-content></ng-content>
    </div>
    <!--<div class="accordion-body">
      {{ text | translate }}
    </div>-->
  </div>
</div>
