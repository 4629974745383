import { OnDestroy, Pipe, PipeTransform, inject } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'localizeAsset',
  standalone: true,
  pure: false
})
export class AssetLocalizationPipe implements PipeTransform, OnDestroy {

  translate = inject(TranslateService);

  currentLang: string;
  private langChangeSubscription: Subscription;

  constructor() {
    this.currentLang = this.translate.currentLang;
    this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });
  }

  transform(value: string): string {
    if (value) {
      // Get the current language code
      const currentLang = this.translate.currentLang;

      // Construct the localized asset path
      const localizedAssetPath = `/assets/${currentLang}${value.split('/assets')[1]}`;
      return localizedAssetPath;
    }
    return '';
  }

  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

}
