import { inject, Injectable } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root'
})
export class ScrollingService {
  scroller = inject(ViewportScroller)
  router = inject(Router)
  documentService = inject(DocumentService)
  route = inject(ActivatedRoute)

  private navbarHeight: number = 60; // Adjust this to the height of your navbar

  constructor() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.handleFragmentScrolling());
  }

  private handleFragmentScrolling() {
    const fragment = this.router.url.split('#')[1];
    if (fragment) {
      this.scrollToFragment(fragment);
    }
  }

  public scrollToFragment(fragment: string) {
    setTimeout(() => { // Use timeout to ensure the view is updated
      const element = document.getElementById(fragment);
      if (element) {
        // Scroll to element with offset for fixed navbar
        this.scroller.scrollToPosition([0, element.getBoundingClientRect().top + window.scrollY - this.navbarHeight]);
      }
    }, 0);
  }

  public scrollToTop() {
    if(this.documentService.window) {
      this.route.fragment.subscribe(fragment => {
        if (fragment) {
          // If a fragment is present, scroll to it
          setTimeout(() => {
            this.scrollToFragment(fragment);
          });
        } else {
          // If no fragment is present, scroll to the top
          setTimeout(() => {
            this.scroller.scrollToPosition([0, 0]);
          });
        }
      });
//      this.documentService.window.scroll(0, 0);
    }
  }

}
