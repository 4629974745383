<div class="grid">
  @if(img) {
    <div class="px-1 g-col-2">
      <h2><i [class]="imgClass"></i></h2>
    </div>
  }
  <div [class.g-col-10]="img" [class.g-col-12]="!img">
    @if(title) {
      <h5>{{ title | translate }}</h5>
    }
    {{ text | translate }}
    @if(detailRoute) {
      <a [routerLink]="detailRoute | localize" [fragment]="fragment">{{ 'FEATURES.MORE' | translate }}</a>
    }
  </div>
</div>
