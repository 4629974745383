<div class="py-5 bg-primary">
  <div class="grid text-center">
    <div class="g-col-12 text-center text-light dyn-font-6xl">
      {{ 'REGISTER.GETBANDON' | translate }}
    </div>
    <div class="g-col-12 g-col-sm-6 text-center text-sm-end">
      <a href="https://apps.apple.com/us/app/band-on/id1673700582" target="_blank">
        <img [src]="'/assets/Download_on_the_App_Store_Badge.svg' | localizeAsset"  style="height: 3.5rem" alt="App Store Badge">
      </a>
    </div>
    <div class="g-col-12 g-col-sm-6 text-center text-sm-start">
      <a href="https://play.google.com/store/apps/details?id=ch.pmtbmbh.bandon.app" target="_blank">
        <img [src]="'/assets/google-play.png' | localizeAsset" class="g-col-12 g-col-sm-6" style="height: 3.5rem" alt="Google Play Badge">
      </a>
    </div>
  </div>
</div>
