<div [class]="bgClass">
  <div class="grid py-4">
    <div class="g-col-2 d-none d-md-block"></div>
    <p [class]="titleClass">{{ feature.title | translate }}</p>
  </div>
  <div class="grid my-4">
    <div class="g-col-2 d-none d-md-block"></div>
    <p class="px-4 g-col-12 g-col-md-8">
      {{ feature.description | translate }}
    </p>
  </div>
  <div class="grid">
    <div [class]="borderClass"></div>
    <div class="g-col-1 d-block d-md-none"></div>
    <!--<div [class]="borderClass"></div>-->
    <div class="px-4 text-lg g-col-12 g-col-md-4">
      <swiper-container #swipercontainer
        [slidesPerView]="1"
        [centeredSlides]="false"
        [pagination]="true"
        navigation="true"
        (swiperslidechange)="slideChanged($event)"
      >
        @for (help of feature.helperParts; track $index) {
          @for (text of help.texts; track $index) {
            <swiper-slide>
              <div class="text-center mx-5 mb-5">
                <div class="image-container">
                  <img  src="{{help.img | localizeAsset }}" class="img-fluid" style="max-height: 70vh;">
                  <div class="red-square"
                    [style.top.%]="getSquareTop(help)" [style.left.%]="getSquareLeft(help)"
                    [style.width.%]="getSquareWidth(help)" [style.height.%]="getSquareHeight(help)">
                  </div>
                </div>
                <div class="d-block d-md-none mt-2">{{ text.text | translate }}</div>
              </div>
            </swiper-slide>
          }
        }
      </swiper-container>
      <!--<div *ngFor="let help of feature.helperParts" class="text-center">
        <div class="image-container" [class.d-none]="help !== activeHelp" [@fadeInOut]="help !== activeHelp ? 'close' : 'open'">
          <img  src="{{help.img}}" class="img-fluid" >
          <div class="red-square"
            [style.top.%]="getSquareTop(help)" [style.left.%]="getSquareLeft(help)"
            [style.width.%]="getSquareWidth(help)" [style.height.%]="getSquareHeight(help)">
          </div>
        </div>
        @if (activeText) {
          <div>{{ activeText.text | translate }}</div>
        }
      </div>-->
    </div>
    <div class="px-4 g-col-12 g-col-md-4">
      <!--<p class="mb-4">{{ feature.description | translate }}</p>-->
      <!--<p class="mb-4">{{ 'FEATURES.CLICKLIST' | translate }}</p>-->
      <ul class="list-group border-none">
        <div *ngFor="let help of feature.helperParts">
          <li *ngFor="let text of help.texts" class="list-group-item border-start-0 border-end-0 border-primary"
              [class.active]="isActiveText(help, text)" [class.bg-transparent]="!isActiveText(help, text)"
              (click)="openText(help, text)">
            <a [class.text-bright]="isActiveText(help, text)">{{ text.text| translate }}</a>
          </li>
        </div>
        </ul>
    </div>
</div>
