import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, afterRender } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private localStorageSet = new BehaviorSubject<boolean>(false);
  hasLocalStorage = this.localStorageSet.asObservable();

  constructor(@Inject(DOCUMENT) private document:Document) {
    afterRender(() => {
      this._window = window;
      this._localStorage = localStorage;
      this.localStorageSet.next(true);
    });
  }

  _window: Window | undefined;
  _localStorage: Storage | undefined;

  get window() {
    if(this._window) {
      return this._window;
    }
    return null;
  }

  get localStorage() {
    if(this._localStorage) {
      return this._localStorage;
    }
    return null;
  }
}
