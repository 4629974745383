// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appURL: './',
  apiURL: 'https://api.test.bandon.ch',
  apiKey: '7ada9becc7b3dd8944a24b0555af4e41768c977798b9b4ef40d1b2bf43aa8225',
  bandon_api_w_key: "84d6ad077e18664df8bbabe05b914cce142a7726b48f5f420a3c33229f4934b43e2c7f602f8bb8541c5586e43007ef8801a342843c1465abb83fc6d1f25a379b",
  google_client_id: '589593319665-kf22t3h6dhuqkcle711rm7qaieau17n7.apps.googleusercontent.com',
  production: false,
  revenueCatKeyWeb: 'rcb_sb_vyVYYOxaclCEvClyQSPBZyEUH',
  supportEMail: "support" + "@" + "band-on.app",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
