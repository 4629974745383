import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TitleComponent } from './title/title.component';
import { ContentComponent } from './content/content.component';

@Component({
    selector: 'app-text-block',
    templateUrl: './text-block.component.html',
    styleUrls: ['./text-block.component.scss'],
    standalone: true,
    imports: [
      TranslateModule,
      TitleComponent,
      ContentComponent
    ]
})
export class TextBlockComponent {

  @Input() background: 'none' | 'primary' | 'secondary' | 'tertiary' = 'none';

  @Input() level = 1;

  get bgClass(): string {
    let bgString = 'bg-white'
    let colorString = 'text-black'

    if (this.background=='primary') {
      bgString = 'bg-primary'
      colorString = 'text-tertiary'
    }
    else if (this.background=='tertiary') {
      bgString = 'bg-tertiary'
      colorString = 'text-black'
    }
    return bgString+' '+colorString;
  }

  get titleClass(): string {
    let bold = '';
    if (this.background=='primary') {
      bold = 'fw-bold font-black '
    }
    if (this.level==1) {
      bold += 'dyn-font-8xl g-col-12 g-col-md-10 '
    } else {
      bold += 'dyn-font-7xl g-col-12 g-col-md-8 '
    }

    return bold+'px-4 my-4 title-translated'
  }

  get borderClass(): string {
    let border = "border-black border-top"
    if (this.background == 'primary') {
      border = "border-tertiary border-top"
    }
    if(this.level>1) {
      border = "border-none"
    }
    return border+' g-col-2 mt-2 mr-2 d-none d-md-block'
  }
}
