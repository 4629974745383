import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, inject, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { Subject, takeUntil } from 'rxjs';

const newsletter_list_ids: { [lang: string]: number } = {
  'en': 375656,
  'de': 374425
}

const group_ids: { [lang: string]: number } = {
  'en': 568103,
  'de': 545686
}


@Component({
  selector: 'app-register-newsletter',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './register-newsletter.component.html',
  styleUrl: './register-newsletter.component.scss'
})
export class RegisterNewsletterComponent implements OnDestroy {
  private translationService = inject(TranslateService);
  private httpClient = inject(HttpClient);

  isSending = false;
  isSent = false;
  hasError = false;

  private unsubscribe$ = new Subject<void>();

  registerForm = new FormGroup({
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]
    ),
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    mailadd: new FormControl('', [this.forbiddenFieldValidator()]),
    interest: new FormControl('singers'),
    groupid: new FormControl(0)
  });

  private translate = inject(TranslateService)

  get name() { return this.registerForm.get('name'); }

  get email() { return this.registerForm.get('email'); }

  get nameString() { return this.translate.instant('REGISTER.NAME'); }

  get emailString() { return this.translate.instant('REGISTER.EMAIL'); }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  forbiddenFieldValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? {forbiddenField: {value: control.value}} : null;
    };
  }

  sendRegistration() {
    if(this.translationService.currentLang) {
      const form_id = newsletter_list_ids[this.translationService.currentLang];
      const group_id = group_ids[this.translationService.currentLang];
      this.isSending = true;
      if (form_id) {
        // Handle the form submission logic here
        const formData = this.registerForm.value;
        formData.groupid = group_id;
        console.log(formData);
        // Replace 'YOUR_API_ENDPOINT' with the actual URL where you want to send the POST request
        const url = `${environment.apiURL}/newsletter/form/${form_id}`;
        let headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);

        this.httpClient.put(url, formData, {headers})
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: (response) => {
              this.isSending = false;
              this.isSent = true;
              console.log('POST request successful:', response);
              // Handle the response as needed
            },
            error: (error) => {
              this.isSending = false;
              this.hasError = true;
              console.error('POST request failed:', error);
              // Handle the error as needed
            }
        });
      }
    }
  }
}
