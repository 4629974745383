import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AssetLocalizationPipe } from 'src/app/core/pipes/asset-localization.pipe';
import { AuthenticationService } from 'bandon-shared';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-instructions-modal',
  standalone: true,
  imports: [
    AssetLocalizationPipe,
    TranslateModule
  ],
  templateUrl: './instructions-modal.component.html',
  styleUrl: './instructions-modal.component.scss'
})
export class InstructionsModalComponent {
  activeModal = inject(NgbActiveModal);
  authService = inject(AuthenticationService)

  get appUrl() {
    return environment.apiURL.replace('api', 'app');
  }

  openWebApp() {
    const token = this.authService.getToken();
    const refresh_token = this.authService.getRefreshToken();
    if(token && refresh_token) {
      const baseURL = `${this.appUrl}/collection/user/login-token`;

      // Construct the query parameters
      const queryParams = new URLSearchParams({ token, refresh_token });

      const url = `${baseURL}?${queryParams}`;
      window.open(url, "_blank");
    }

  }
}
