import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from 'src/app/services/document.service';
import { SEOService } from 'src/app/services/seoservice.service';
import { collections } from 'src/app/shared/data/collections.data';
import { Collection } from 'src/app/shared/interfaces/collection';

@Component({
  selector: 'app-collections-swiper',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './collections-swiper.component.html',
  styleUrl: './collections-swiper.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CollectionsSwiperComponent implements OnInit, OnDestroy {
  seoService = inject(SEOService)
  translateService = inject(TranslateService)
  documentService = inject(DocumentService)

  @Input() background: 'none' | 'primary' | 'secondary' | 'tertiary' = 'none';

  collections: Collection[] = collections;

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
