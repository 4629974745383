<div class="mt-40">
  <div class="grid">
    <div class="g-col-1 d-none d-md-block"></div>
    <p class="g-col-12 g-col-md-11 dyn-font-8xl">
      {{ "USERDATA.YOURDATA" | translate }}
    </p>
  </div>

  <div class="grid my-5" >
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-2"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <form [formGroup]="userdataForm">
        <div class="mb-4">
          <label for="name" class="form-label">{{ 'REGISTER.NAME' | translate }}</label>
          <div id="nameHelp" class="form-text">{{ 'REGISTER.NAMEINFO' | translate }}</div>
          <input type="text" class="form-control" formControlName="name" autocomplete="name">
          <div *ngIf="(name?.invalid && name?.touched) || name?.dirty" class="text-danger">
            <small *ngIf="name?.hasError('required')" class="text-danger">{{ 'USERDATA.REQUIREDFIELD' | translate: {field: nameString} }}</small>
            <!--question mark(?) is a safe navigation operator-->
            <small *ngIf="name?.hasError('minlength')" class="text-danger">{{ 'USERDATA.MINLENGTH' | translate: {min: 3} }}</small>
            <small *ngIf="name?.errors?.['profanity']" class="text-danger">{{ 'REGISTER.NAMEPROFANITYERROR' | translate }}</small>
          </div>
        </div>

        <div class="mb-4">
          <label for="name" class="form-label">{{ 'REGISTER.USERNAME' | translate }}</label>
          <div id="nameHelp" class="form-text">{{ 'REGISTER.USERNAMEINFO' | translate }}</div>
          <input type="text" class="form-control" formControlName="username" autocomplete="username">
          <div *ngIf="(username?.invalid && username?.touched) || username?.dirty" class="text-danger">
            <small *ngIf="username?.hasError('required')" class="text-danger">{{ 'USERDATA.REQUIREDFIELD' | translate: {field: usernameString} }}</small>
            <!--question mark(?) is a safe navigation operator-->
            <small *ngIf="username?.hasError('minlength')" class="text-danger">{{ 'USERDATA.MINLENGTH' | translate: {min: 3} }}</small>
            <small *ngIf="username?.errors?.['usernameTaken']" class="text-danger">{{ 'REGISTER.USERNAMETAKEN' | translate }}</small>
            <small *ngIf="username?.errors?.['profanity']" class="text-danger">{{ 'REGISTER.NAMEPROFANITYERROR' | translate }}</small>
            <small *ngIf="suggestedUsernames.length > 0" class="text-danger">{{ 'REGISTER.SUGGESTIONS' | translate }}:</small>
            <small class="text-danger">
              @for (suggestion of suggestedUsernames; track $index) {
                @if($index < suggestedUsernames.length -1) {
                  {{ suggestion }},
                } @else {
                  {{ suggestion }}
                }
              }
            </small>
          </div>
        </div>

        <div class="mb-4">
          <label for="inputUsermail" class="form-label">{{ "USERDATA.EMAIL" | translate }}</label>
          <input type="text" class="form-control" formControlName="email" autocomplete="email">
        </div>

        <div class="mb-4" *ngIf="!isSocialLogin" >
          <label for="inputOldPassword" class="form-label">{{ "USERDATA.OLDPASSWORD" | translate }}</label>
          <input type="password" class="form-control" formControlName="oldPassword" autocomplete="current-password">
          <div *ngIf="(oldPassword?.invalid) || oldPassword?.dirty" class="text-danger">
            <small *ngIf="oldPassword?.hasError('required')" class="text-danger">{{ 'USERDATA.REQUIREDFIELD' | translate: {field: oldPWDString} }}</small>
          </div>
        </div>

        <div class="mb-4" *ngIf="!isSocialLogin">
          <label for="inputNEwPassword" class="form-label">{{ "USERDATA.NEWPASSWORD" | translate }}</label>
          <input type="password" class="form-control" formControlName="newPassword" autocomplete="new-password">
          <div *ngIf="(password?.invalid && password?.touched) || password?.dirty" class="text-danger">
            <small *ngIf="password?.getError('minlength')" class="text-danger">
              {{ 'USERDATA.MINLENGTH' | translate: {min: 8} }}<br>
            </small>
            <small *ngIf="password?.getError('hasNumber')" class="text-danger">
              {{ 'USERDATA.NEEDSNUMBER' | translate: {min: 1} }}<br>
            </small>
            <small *ngIf="password?.getError('hasCapitalCase')" class="text-danger">
              {{ 'USERDATA.NEEDSCAPITAL' | translate: {min: 1} }}<br>
            </small>
            <small *ngIf="password?.getError('hasSmallCase')" class="text-danger">
              {{ 'USERDATA.NEEDSSMALL' | translate: {min: 1} }}<br>
            </small>
            <small *ngIf="password?.getError('hasSpecialCharacters')" class="text-danger">
              {{ 'USERDATA.NEEDSSPECIAL' | translate: {min: 1} }}<br>
            </small>
            <small *ngIf="password?.getError('required')" class="text-danger">
              {{ 'USERDATA.REQUIREDFIELD' | translate: {field: passwordString} }}
            </small>
          </div>
        </div>

        <div class="mb-4" *ngIf="!isSocialLogin">
          <label for="inputNewPasswordConfirm" class="form-label">{{ "USERDATA.NEWPWDCONFIRM" | translate }}</label>
          <input type="password" class="form-control" formControlName="confirmPassword"  autocomplete="new-password">
          <div *ngIf="userdataForm.hasError('passwordsNotMatch') && userdataForm.get('confirmPassword')?.dirty" class="text-danger">
            <small>
              {{ 'USERDATA.PWDNOTMATCH' | translate }}
            </small>
          </div>
        </div>

        <div>
          <button type="button" class="btn btn-primary" [disabled]="!userdataForm.valid || dataUpdating" (click)="onSubmit()">
            {{ "USERDATA.CHANGE" | translate }}
            <span class="spinner-border spinner-border-sm" aria-hidden="true" *ngIf="dataUpdating" ></span>
          </button>
        </div>
      </form>

      <div class="alert alert-primary alert-dismissible fade show my-4" role="alert" *ngIf="updateErr" >
        {{ 'USERDATA.ERRUPDATE' | translate }}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="resetError()"></button>
      </div>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid py-5 bg-tertiary">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>{{ 'USERDATA.YOURPURCHASES' | translate }}</h2>
      <div class="table-responsive mt-4">

        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">{{ 'USERDATA.DATE' | translate }}</th>
              <th scope="col">{{ 'USERDATA.TYPE' | translate }}</th>
              <th scope="col">{{ 'USERDATA.DESIGNATION' | translate }}</th>
              <th scope="col">{{ 'USERDATA.PRICE' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let purchase of purchases" >
              <th scope="row">{{ purchase.purchasedate | date: 'dd.MM.YYYY'}}</th>
              <td><div *ngFor="let item of purchase.items">
                <p *ngIf="item.collectionid>0">{{ 'USERDATA.COLLECTION' | translate }}</p>
                <p *ngIf="item.tuneid>0">{{ 'USERDATA.TUNE' | translate }}</p>
              </div></td>
              <td><div *ngFor="let item of purchase.items">
                <p *ngIf="item.collectionid>0" >{{ getCollectionTitle(item.collectionid) }}</p>
                <p *ngIf="item.tuneid>0" >{{ getTuneDesc(item.tuneid) }}</p>
              </div></td>
              <td><div *ngFor="let item of purchase.items">{{ item.price| currency: item.currency+' ':'symbol':'1.2-2' }}</div></td>
            </tr>
            <tr *ngIf="purchases?.length==0">
              <td colspan="4" class="text-center">
                {{ 'USERDATA.NOPURCHASES' | translate }}
              </td>
            </tr>
          </tbody>
        </table>

      </div>

    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  @if (devices.length>0) {
    <div class="grid py-5 ">
      <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
      <div class="px-4 text-lg g-col-12 g-col-md-9">
        <h2>{{ 'USERDATA.YOURDEVICES' | translate }}</h2>
        <div class="grid mt-4">
          @for (device of devices; track $index) {
            <div class="card m-3 g-col-12 g-col-md-6">
              <div class="card-body">
                <h5 class="card-title">
                  @if(device.customname) {
                    {{ device.customname }}
                  } @else if(device.name) {
                    {{ device.name }}
                  } @else {
                    {{ device.manufacturer }} {{ device.model }}
                  }
                </h5>
                @if ( device.customname || device.name ) {
                  <p class="card-text">{{ device.manufacturer }} {{ device.model }}</p>
                }
                <p class="card-text">{{ device.operatingsystem }} {{ device.osversion}}</p>
                <button type="button" class="btn btn-secondary m-2" data-bs-toggle="modal" data-bs-target="#deviceNameModal" (click)="setEditDevice(device)">{{ 'USERDATA.RENAME' | translate }}</button>
                <button type="button" class="btn btn-primary m-2" data-bs-toggle="modal" data-bs-target="#deviceDeleteModal" (click)="setEditDevice(device)">{{ 'USERDATA.DELETE' | translate }}</button>
              </div>
            </div>
          }
        </div>

      </div>
    </div>

    <!-- Device Name edit Modal -->
    <div class="modal fade" id="deviceNameModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">{{ 'USERDATA.RENAME' | translate }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{ 'USERDATA.GIVEDEVICENAME' | translate }}
            <div class="mb-3">
              <label for="deviceNameInput" class="form-label">{{ 'USERDATA.DEVICENAME' | translate }}</label>
              <input type="text" class="form-control" id="deviceNameInput" aria-describedby="deviceNameHelp" name="devicename" [(ngModel)]="customDeviceName">
              <div id="deviceNameHelp" class="form-text">{{ 'USERDATA.DEVICENAMEHELP' | translate }}</div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ 'USERDATA.CANCEL' | translate }}</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="saveDevice()">{{ 'USERDATA.SAVE' | translate }}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Device Delete Modal -->
    <div class="modal fade" id="deviceDeleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="deleteModalLabel">{{ 'USERDATA.DELETE' | translate }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{ 'USERDATA.DEVICEDELETE' | translate }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ 'USERDATA.CANCEL' | translate }}</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="deleteDevice()">{{ 'USERDATA.DELETE' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  }
</div>
