import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-keyvisual-img',
    templateUrl: './keyvisual-img.component.html',
    styleUrls: ['./keyvisual-img.component.scss'],
    standalone: true,
    imports: [NgIf, TranslateModule]
})
export class KeyvisualImgComponent {

  @Input() imgSrc = ''
  @Input() text = ''


}
