import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  selected = '';

  constructor(
    private translate: TranslateService,
  ) {
  }

  setInitialAppLanguage() {
/*    const language = this.translate.getBrowserLang();
    if(language && this.getLanguages().find(e=>e.value===language)) {
      this.translate.setDefaultLang(language);
      this.translate.use(language);
      this.selected = language;
    } else {
      this.translate.setDefaultLang('en');
      this.selected = 'en';
    }
    console.log(this.selected);*/
    this.selected = this.translate.currentLang;
  }

  getLanguages() {
    return [
      { text: 'English', value: 'en' },
      { text: 'Deutsch', value: 'de' }
    ];
  }

  setLanguage(lng: any) {
    if (lng) {
      this.translate.use(lng);
      this.selected = lng;
      document.documentElement.lang = lng;
    }
  }

  getLanguage(value: string) {
    return this.getLanguages().find(l => l.value===value);
  }}
