import { PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppLoaderService } from './services/app-loader.service';
import { isPlatformBrowser } from '@angular/common';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
  if (isPlatformBrowser(PLATFORM_ID)) {
    // Client side: Use localStorage directly
    return localStorage.getItem("access_token");
  }
  return null;
//  return localStorage.getItem("access_token");
}

export function serviceLoader(appLoader: AppLoaderService) {
  return () => appLoader.init();
}


