<div class="my-40 px-4">
  <div class="grid">
    <div class="g-col-1 d-none d-md-block"></div>
    <p class="g-col-12 g-col-md-11 dyn-font-8xl">
      {{ "IMPRINT.IMPRINT" | translate }}
    </p>
  </div>

  <form [formGroup]="contactForm" *ngIf="!msgSent" >
    <div class="grid my-5">
      <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
      <div class="px-4 text-lg g-col-12 g-col-md-6">
        <h2>{{ "IMPRINT.CONTACTFORM" | translate }}</h2>
        <form>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">{{ "IMPRINT.EMAIL" | translate }}</label>
            <input [class.is-invalid] = "contactForm.get('email')?.invalid && contactForm.get('email')?.touched"
              type="email" class="form-control" id="inputEmail" aria-describedby="emailHelp" formControlName="email">

            <!-- Error message for required validator -->
            <div *ngIf="contactForm.get('email')?.errors?.['required'] && (contactForm.get('email')?.dirty || contactForm.get('email')?.touched)" class="text-danger">
              {{ "IMPRINT.REQEMAIL" | translate }}
            </div>

            <!-- Error message for pattern validator -->
            <div *ngIf="contactForm.get('email')?.errors?.['pattern'] && (contactForm.get('email')?.dirty || contactForm.get('email')?.touched)" class="text-danger">
              {{ "IMPRINT.REQEMAILOK" | translate }}
            </div>
          </div>
          <div class="mb-3">
            <label for="exampleInputName" class="form-label">{{ "IMPRINT.NAME" | translate }}</label>
            <input [class.is-invalid] = "contactForm.get('name')?.invalid && contactForm.get('name')?.touched"
              type="text" class="form-control" id="inputName" aria-describedby="nameHelp" formControlName="name">

            <!-- Error message for required validator -->
            <div *ngIf="contactForm.get('name')?.errors?.['required'] && (contactForm.get('name')?.dirty || contactForm.get('name')?.touched)" class="text-danger mx-1">
              {{ "IMPRINT.REQNAME" | translate }}
            </div>

            <!-- Error message for pattern validator -->
            <div *ngIf="contactForm.get('name')?.errors?.['minlength'] && (contactForm.get('name')?.dirty || contactForm.get('name')?.touched)" class="text-danger mx-1">
              {{ "IMPRINT.REQNAMEL" | translate }}
            </div>
          </div>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">{{ "IMPRINT.MESSAGE" | translate }}</label>
            <textarea [class.is-invalid] = "contactForm.get('msg')?.invalid && contactForm.get('msg')?.touched"
              class="form-control" id="inputMessage" rows="3" formControlName="msg"></textarea>

            <!-- Error message for required validator -->
            <div *ngIf="contactForm.get('msg')?.errors?.['required'] && (contactForm.get('msg')?.dirty || contactForm.get('name')?.touched)" class="text-danger mx-1">
              {{ "IMPRINT.REQMSG" | translate }}
            </div>

            <!-- Error message for pattern validator -->
            <div *ngIf="contactForm.get('msg')?.errors?.['minlength'] && (contactForm.get('msg')?.dirty || contactForm.get('name')?.touched)" class="text-danger mx-1">
              {{ "IMPRINT.REQMSGL" | translate }}
            </div>
          </div>

          <input type="text" class="d-none" formControlName="mailadd">

          <button type="button" class="btn btn-primary rounded-0" (click)="submit()"
              [disabled]="!contactForm.valid || msgSending">
            {{ "IMPRINT.SUBMIT" | translate }}
            <span class="spinner-border spinner-border-sm" aria-hidden="true" *ngIf="msgSending" ></span>
          </button>
        </form>
      </div>
      <div class="g-col-3 d-none d-md-block"></div>
    </div>
  </form>

  <div class="grid my-5" *ngIf="msgSent" >
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      {{ "IMPRINT.MSGSENT" | translate }}
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>


  <div class="grid">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>{{ "IMPRINT.ADDRESS" | translate }}</h2>
      <p>
        Personal Music Tools GmbH<br>
        Zentweg 21i<br>
        3006 Bern
      </p>
      <p>
        {{ "IMPRINT.EMAIL" | translate }}: <a [href]="'mailto:'+supportMail+'?subject=Nachricht von der band-on Site'" class="text-black">{{ supportMail }}</a>
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

</div>
