<!-- Instructions Modal -->
<div class="modal-header bg-primary text-light">
  <h1 class="modal-title fs-5" id="exampleModalLabel">{{ 'REGISTER.FAILED' | translate }}</h1>
</div>
<div class="modal-body">
  <div class="grid text-center">
    <div class="g-col-12">
      {{ 'REGISTER.EXISTING' | translate }}
    </div>
    <div class="g-col-12">
      <button class="btn btn-primary w-100" type="button" (click)="back()">{{ 'REGISTER.BACK' | translate }}</button>
    </div>
  </div>
</div>
