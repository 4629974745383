<div class="mockup-container d-flex pt-2 px-4 overflow-hidden justify-content-evenly bg-primary position-relative z-1">
  <div class="badge p-3 m-3 fs-5 lh-sm bg-secondary">
    {{ bannerline1 }}<br>{{ bannerline2 }}
  </div>
  <div class="overlay z-2"></div>
  <!--<img src="assets/Bilder/2022_10_01_jazzcampus_aufnahme_halmeskobel-18.jpg" alt="Mockup Image" class="background-image">-->
  <div class="align-self-end text-light z-3">
    <h2>{{ hookLine }}</h2>
    <p>{{ description }}</p>
  </div>
  <div class="mockup-image overflow-hidden position-relative d-none d-sm-block">
    <img [src]="img | localizeAsset" alt="Mockup Image" class="w-100 position-absolute">
  </div>
</div>
