<!-- Instructions Modal -->
<div class="modal-header bg-primary text-light">
  <h1 class="modal-title fs-5" id="exampleModalLabel">{{ 'REGISTER.NEXTSTEPS' | translate }}</h1>
  <button type="button" class="btn-close btn-close-custom"  (click)="activeModal.close()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="grid text-center">
    <div class="g-col-12">
      {{ 'REGISTER.SUCCESS' | translate }}
    </div>
    <div class="g-col-12">
      {{ 'REGISTER.DOWNLOADNOW' | translate }}
    </div>
    <div class="g-col-12 g-col-sm-6 text-center text-sm-end">
      <a href="https://apps.apple.com/us/app/band-on/id1673700582" target="_blank">
        <img [src]="'/assets/Download_on_the_App_Store_Badge.svg' | localizeAsset"  style="height: 3.5rem">
      </a>
    </div>
    <div class="g-col-12 g-col-sm-6 text-center text-sm-start">
      <a href="https://play.google.com/store/apps/details?id=ch.pmtbmbh.bandon.app" target="_blank">
        <img [src]="'/assets/google-play.png' | localizeAsset" class="g-col-12 g-col-sm-6" style="height: 3.5rem">
      </a>
    </div>
    <div class="g-col-12">
      {{ 'REGISTER.OR' | translate }}
    </div>
    <div class="g-col-12">
      <a class="btn btn-primary w-100" type="button"  (click)="openWebApp()">{{ 'REGISTER.OPENWEB' | translate }}</a>

    </div>
  </div>
</div>
