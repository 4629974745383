<ul class="navbar-nav ms-auto">
  @if (showTopNav) {
    @for (p of appPages; track $index) {
      <li class="nav-item">
        <a [class]="getClass(p.url, p.fragment)" aria-current="page" [routerLink]="[p.url | localize]" [fragment]="p.fragment" (click)="scrollToFragment(p.fragment)">{{ p.title | translate }}</a>
      </li>
    }
    <div class="mb-2 call-to-action-button" >
      @if(!isAuth) {
        <a class="btn w-100"
          [class.btn-primary]="background!='primary'"
          [class.btn-secondary]="background=='primary'"
          (click)="openRegisterForm()">
          {{ 'TITLES.CALLTOACTION' | translate }}
        </a>
      } @else {
        <a class="btn btn-primary w-100"
          [class.btn-primary]="background!='primary'"
          [class.btn-secondary]="background=='primary'"
          (click)="openWebApp()">
          {{ 'TITLES.OPENWEBAPP' | translate }}
        </a>
      }
    </div>

  }

  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      {{ language | uppercase }}
    </a>
    @if(mode==='topnav') {
      <ul class="dropdown-menu dropdown-menu-lg-end">
        <li *ngFor="let lang of languages">
          <a class="dropdown-item" [ngClass]="{ 'active-language': lang.value === language }" (click)="setLanguage(lang.value)">{{ lang.value | uppercase }}</a>
        </li>
      </ul>
    } @else {
      <ul class="dropdown-menu dropdown-menu-lg-end w-100 bg-tertiary border-0">
        <li *ngFor="let lang of languages">
          <a class="dropdown-item" [ngClass]="{ 'active-language': lang.value === language }" (click)="setLanguage(lang.value)">{{ lang.value | uppercase }}</a>
        </li>
      </ul>
    }
  </li>

  @if (showTopNav) {
    <li class="nav-item dropdown">
      <a class="nav-link" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="bi bi-person-fill"></i>
      </a>
      <ul class="dropdown-menu dropdown-menu-lg-end"
        [class.w-100]="mode=='modal'"
        [class.bg-tertiary]="mode=='modal'"
        [class.border-0]="mode=='modal'"
      >
        @if (!isAuth) {
          <li><h5 class="dropdown-header">{{ "NAVBAR.LOGINTEXT" | translate }}</h5></li>
          <form class="px-3 py-1" [formGroup]="loginForm">
            <div class="mb-3">
              <label for="loginEmail" class="form-label">{{ "NAVBAR.EMAIL" | translate }}</label>
              <input type="email" class="form-control" id="loginEmail" placeholder="email@example.com" formControlName="email" autocomplete="email">
            </div>
            <div class="mb-3">
              <label for="loginPassword" class="form-label">{{ "NAVBAR.PASSWORD" | translate }}</label>
              <input type="password" class="form-control" id="loginPassword" [placeholder]="passwordString" formControlName="password" autocomplete="current-password">
            </div>
            <div class="d-grid gap-2">
              <button type="button" class="btn btn-primary" [disabled]="!loginForm.valid" (click)="login()">{{ "NAVBAR.SIGNIN" | translate }}</button>
            </div>
          </form>
          <li class="px-3 py-1">
            <button [routerLink]="['/password/generate-reset'] | localize" class="btn text-primary p-0">
              {{ 'NAVBAR.PWDRESET' | translate }}
            </button>
          </li>
        }
        <li><hr class="dropdown-divider" *ngIf="!isAuth" ></li>
        <li class="px-3 py-1 d-grid gap-2" *ngIf="!isAuth" >
          <button type="button" class="btn btn-outline-primary" (click)="loginWithApple()">
            <i class="bi bi-apple"></i>
            {{ "NAVBAR.LOGINAPPLE" | translate }}
          </button>
        </li>
        <li class="px-3 py-1 d-grid gap-2" *ngIf="!isAuth" >
          <asl-google-signin-button type='standard' size="large"></asl-google-signin-button>
        </li>

        <li *ngIf="isAuth">
          <h4 class="dropdown-header">{{ username }}</h4>
          <h6 class="dropdown-header">{{ usermail }}</h6>
        </li>
        <li><hr class="dropdown-divider" *ngIf="isAuth" ></li>
        <li *ngIf="isAuth" class="dropdown-item">
          <a [routerLink]="['/userdata' | localize]">{{ "NAVBAR.USERDATA" | translate }}</a>
        </li>
        <li *ngIf="isAuth" class="p-3 dropdown-item">
          <button type="button" class="btn btn-primary" (click)="logout()">{{ "NAVBAR.LOGOUT" | translate }}</button>
        </li>
      </ul>
    </li>
  }
</ul>
