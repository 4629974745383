
  <div class="position-relative">
    <img src="/assets/Keyvisuals/BO_Keyvisual_Icon_1.jpg" class="w-100">
    <div class="position-absolute top-0 end-r1 bg-primary pt-4 d-md-none">
      <img src="/assets/Band-On_Logo_1024.png" style="width: 13vw">
    </div>
  </div>
  <!--<app-hero-header></app-hero-header>-->

  <app-text-block background="primary">
    <span slot="header">{{ 'HOME.TITLE1' | translate }}</span>
    <span slot="content">
      <p>{{ 'HOME.TEXT1' | translate }}</p>
      <p>{{ 'HOME.TEXT2' | translate }}</p>
      <p>{{ 'HOME.TEXT3' | translate }}</p>
      <p>{{ 'HOME.TEXT4' | translate }}</p>
      <p>{{ 'HOME.TEXT5' | translate }}</p>
    </span>
  </app-text-block>

  <div class="pb-5 bg-primary">
    <div class="grid text-center">
      <div class="g-col-12 g-col-sm-6 text-center text-sm-end">
        <a href="https://apps.apple.com/us/app/band-on/id1673700582" target="_blank">
          <img [src]="'/assets/Download_on_the_App_Store_Badge.svg' | localizeAsset"  style="height: 3.5rem">
        </a>
      </div>
      <div class="g-col-12 g-col-sm-6 text-center text-sm-start">
        <a href="https://play.google.com/store/apps/details?id=ch.pmtbmbh.bandon.app" target="_blank">
          <img [src]="'/assets/google-play.png' | localizeAsset" class="g-col-12 g-col-sm-6" style="height: 3.5rem">
        </a>
      </div>
    </div>
  </div>


  <!--Neu auf band-on-->
  <!--<div class="bg-primary text-tertiary">
    <div class="grid">
      <div class="g-col-2 d-none d-md-block"></div>
      <p class="fw-bold font-black dyn-font-4xl g-col-12 g-col-md-9 px-4 my-4 title-translated">{{ 'HOME.NEWS' | translate }}</p>
    </div>
    <div class="grid">
      <div class="border-tertiary g-col-2 mt-2 mr-2 d-none d-md-block"></div>
      <div class="px-4 text-lg g-col-12 g-col-md-8 line-break-translated link-tertiary" innerHTML="{{ 'HOME.NEWSMSG' | translate }}">
      </div>
      <div class="g-col-2 d-none d-md-block"></div>
      </div>
  </div>-->

  <div class="bg-primary text-tertiary grid pb-5" style="--bs-gap: 0;">
    <div class="g-col-2"></div>
    <div class="g-col-8 grid  ">
        <app-keyvisual-img class="g-col-12 g-col-md-6 g-col-lg-4 mx-3 d-none d-md-block" imgSrc="assets/Keyvisuals/BO_Keyvisual_Icon_2.jpg" text=""></app-keyvisual-img>
        <app-keyvisual-img class="g-col-12 g-col-md-6 g-col-lg-4 mx-3" imgSrc="assets/Keyvisuals/BO_Keyvisual_Icon_4.jpg" text=""></app-keyvisual-img>
        <app-keyvisual-img class="g-col-12 g-col-md-6 g-col-lg-4 mx-3 d-none d-lg-block" imgSrc="assets/Keyvisuals/BO_Keyvisual_Icon_3.jpg" text=""></app-keyvisual-img>
    </div>
    <div class="g-col-2"></div>
  </div>

  <div class="text-black">
    <div class="grid">
      <div class="g-col-2 d-none d-md-block"></div>
      <p class="dyn-font-7xl g-col-12 g-col-md-8 px-4 my-4">{{ "HOME.TITLE2" | translate }}</p>
    </div>
    <div class="grid">
      <div class="border-black g-col-2 mt-2 mr-2 d-none d-md-block"></div>
      <div class="px-4 text-lg g-col-12 g-col-md-8 pb-5">
        <div class="grid">
          <div class="text-lg g-col-12 g-col-md-6">
            <app-feature-panel img="bi-play-circle" text="HOME.FEATURE2" detailRoute="/features" fragment="feature0"></app-feature-panel>
          </div>
          <div class="text-lg g-col-12 g-col-md-6">
            <app-feature-panel img="bi-sliders2-vertical" text="HOME.FEATURE3" detailRoute="/features" fragment="feature1"></app-feature-panel>
          </div>
          <div class="text-lg g-col-12 g-col-md-6">
            <app-feature-panel img="bi-bricks" text="HOME.FEATURE5" detailRoute="/features" fragment="feature2"></app-feature-panel>
          </div>
          <div class="text-lg g-col-12 g-col-md-6">
            <app-feature-panel img="bi-repeat" text="HOME.FEATURE6"  detailRoute="/features" fragment="feature2"></app-feature-panel>
          </div>
          <div class="text-lg g-col-12 g-col-md-6">
            <app-feature-panel img="bi-record-circle" text="HOME.FEATURE7" detailRoute="/features" fragment="feature1"></app-feature-panel>
          </div>
          <div class="text-lg g-col-12 g-col-md-6">
            <app-feature-panel img="bi-people" text="HOME.FEATURE8" detailRoute="/features" fragment="feature4"></app-feature-panel>
          </div>
          <div class="text-lg g-col-12 g-col-md-6">
            <app-feature-panel img="bi-music-note-list" text="HOME.FEATURE1" detailRoute="/features" fragment="feature5"></app-feature-panel>
          </div>
          <div class="text-lg g-col-12 g-col-md-6">
            <app-feature-panel img="bi-bar-chart-fill" text="HOME.FEATURE4"></app-feature-panel>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-black">
    <swiper-container class="mx-5 py-5"
        [slidesPerView]="mockupSlidesPerView"
        [centeredSlides]="false"
        [pagination]="showPagination"
        navigation="true"
        >
      <swiper-slide>
        <img src="{{ '/assets/Mockups/Screenshot_iOS_1.png' | localizeAsset }}" class="mx-2 mb-5" style="height: 30rem;">
      </swiper-slide>
      <swiper-slide>
        <img src="{{ '/assets/Mockups/Screenshot_iOS_2.png' | localizeAsset }}" class="mx-2 mb-5" style="height: 30rem;">
      </swiper-slide>
      <swiper-slide>
        <img src="{{ '/assets/Mockups/Screenshot_iOS_3.png' | localizeAsset }}" class="mx-2 mb-5" style="height: 30rem;">
      </swiper-slide>
      <swiper-slide>
        <img src="{{ '/assets/Mockups/Screenshot_iOS_4.png' | localizeAsset }}" class="mx-2 mb-5" style="height: 30rem;">
      </swiper-slide>
    </swiper-container>
  </div>

  <!--<app-text-block [level]="2" background="tertiary" id="features">
    <span slot="header">Was kann band-on?</span>
    <span slot="content">
      Verschaffe dir hier einen Überblick über unsere Funktionen und entdecke, wie band-on dich beim Üben unterstützt.
    </span>
  </app-text-block>
  <app-features background="tertiary"></app-features>-->

  <!--Unsere Projekte-->
  <app-text-block [level]="2" [background]="'tertiary'">
    <span slot="header">{{ 'HOME.TITLE3' | translate }}</span>
    <span slot="content">{{ 'HOME.TEXT6' | translate }}</span>
  </app-text-block>
  <app-collections-swiper [background]="'tertiary'"></app-collections-swiper>

  <!--Video-->
  <app-text-block [level]="2" [background]="'tertiary'">
    <span slot="header">Unsere Vision</span>
    <span slot="content">Hier erfährst du in drei Minuten das Wichtigste über uns und unsere Vision.</span>
  </app-text-block>

  <div class="py-5 bg-tertiary">
    <div class="grid mb-5">
      <div class="g-col-3 mt-2 mr-2 d-none d-md-block"></div>
      <div class="px-4 g-col-12 g-col-md-6">
        <div class="ratio ratio-16x9">
          <iframe src="https://www.youtube.com/embed/ZIyxq-UskI4?si=BR55Lz383C4EAdOO" title="YouTube video player" allowfullscreen></iframe>
        </div>
      </div>
      <div class="g-col-3 d-none d-md-block"></div>
    </div>
  </div>

  <app-payment id='prices'></app-payment>

  <div class="text-black pb-5" id="faqs">
    <app-title [level]="2">
      {{ "HOME.FAQTITLE" | translate }}
    </app-title>
    <div class="grid">
      <div class="g-col-2 border-black mt-2 mr-2 d-none d-md-block"></div>
      <div class="text-lg g-col-12 g-col-md-10">
        <div class="accordion accordion-flush" id="faqAccordion">
          <app-faq-panel number="1" title="HOME.FAQTITLE1">
            <p>{{ 'HOME.FAQTEXT1_1' | translate }}</p>
            <ul>
              <li>{{ 'HOME.FAQTEXT1_2' | translate }}</li>
              <li>{{ 'HOME.FAQTEXT1_3' | translate }}</li>
              <li>{{ 'HOME.FAQTEXT1_4' | translate }}</li>
            </ul>
            <p>{{ 'HOME.FAQTEXT1_5' | translate }}</p>
          </app-faq-panel>
          <app-faq-panel number="2" title="HOME.FAQTITLE2">
            {{ 'HOME.FAQTEXT2' | translate }}
          </app-faq-panel>
          <app-faq-panel number="3" title="HOME.FAQTITLE3">
            {{ 'HOME.FAQTEXT3' | translate }}
          </app-faq-panel>
          <app-faq-panel number="4" title="HOME.FAQTITLE4">
            {{ 'HOME.FAQTEXT4' | translate }}
          </app-faq-panel>
          <app-faq-panel number="5" title="HOME.FAQTITLE5">
            {{ 'HOME.FAQTEXT5' | translate }}
          </app-faq-panel>
          <app-faq-panel number="6" title="HOME.FAQTITLE6">
            {{ 'HOME.FAQTEXT6' | translate }}
          </app-faq-panel>
          <app-faq-panel number="7" title="HOME.FAQTITLE7">
            {{ 'HOME.FAQTEXT7' | translate }}
          </app-faq-panel>
          <app-faq-panel number="8" title="HOME.FAQTITLE8">
            {{ 'HOME.FAQTEXT8' | translate }}
          </app-faq-panel>
          <app-faq-panel number="9" title="HOME.FAQTITLE9">
            {{ 'HOME.FAQTEXT9' | translate }}
            <a class="link-primary band-on-link" (click)="scrollToFragment('prices')">{{ 'HOME.FAQTEXT9_2' | translate }}</a>
            {{ 'HOME.FAQTEXT9_3' | translate }}
          </app-faq-panel>
        </div>
      </div>
    </div>
  </div>
