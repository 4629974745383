import { Injectable } from '@angular/core';
import { AuthenticationService } from 'bandon-shared'
import { BehaviorSubject, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Collection, SimpleTuneModel } from 'bandon-shared';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  tuneList: SimpleTuneModel[] = [];

  public collectionsBehaviour = new BehaviorSubject<Collection[]>([]);
  public collections$ = this.collectionsBehaviour.asObservable();

  private isAuth = false;
  private collections: Collection[] = [];
  private libraryUrl = environment.apiURL+'/tunes';

  constructor(
    private authService: AuthenticationService,
    private httpClient: HttpClient
  ) { }

  init() {
    this.authService.isAuthenticated.subscribe(auth => {
      this.isAuth = auth;
      if(auth) {
        this.loadDataFromServer();
      }
    });
  }

  loadDataFromServer() {
    this.loadTunesFromServer()
      .subscribe();
    this.loadCollectionsFromServer()
      .subscribe();
  }

  loadTunesFromServer() {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    return this.httpClient.get<SimpleTuneModel[]>(this.libraryUrl, {headers}).pipe(
      tap(result => {
        if(result) {
          this.tuneList.length = 0;
          this.tuneList.push(...result);
          this.tuneList = this.tuneList.sort((e1, e2) => e1.title>e2.title ? 1: -1);
        }
      })
    );
  }

  loadCollectionsFromServer() {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    return this.httpClient.get<Collection[]>(environment.apiURL+'/collections', {headers}).pipe(
      tap(result => {
        if(result) {
          this.collections.length = 0;
          this.collections.push(...result);
          this.collectionsBehaviour.next(result);
        }
      })
    );
  }

  getSimpleTune(tuneId: number): SimpleTuneModel | undefined {
    // eslint-disable-next-line eqeqeq
    const result = this.tuneList.filter(t => t.id == tuneId);
    if( result.length > 0 ) {
      return result[0];
    }
    return undefined;
  }


}
