import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AssetLocalizationPipe } from 'src/app/core/pipes/asset-localization.pipe';

@Component({
  selector: 'app-get-bandon-now',
  standalone: true,
  imports: [TranslateModule, AssetLocalizationPipe],
  templateUrl: './get-bandon-now.component.html',
  styleUrl: './get-bandon-now.component.scss'
})
export class GetBandonNowComponent {

}
