@if(!isSent) {
  <form [formGroup]="registerForm">

    <div class="grid mt-3">
      <div class="text-lg g-col-12 g-col-md-6">
        <label for="registerEmailInput" class="form-label">{{ 'REGISTER.EMAIL' | translate }}</label>
        <input type="email" class="form-control" id="registerEmailInput" placeholder="name@example.com" formControlName="email" autocomplete="new-password">
        <input type="email" class="form-control d-none" placeholder="name@example.com" formControlName="mailadd">
        @if((email?.invalid && email?.touched) || email?.dirty) {
          <div class="mx-1 text-primary">
            @if(email && email.errors && email.errors['required']) {
              <small class="text-danger">
                {{ 'REGISTER.REQUIREDFIELD' | translate: {field: emailString} }}<br>
              </small>
            }
            @if(email && email.errors && email.errors['pattern']) {
              <small class="text-danger">{{ 'REGISTER.PLVALIDMAIL' | translate }}</small>
            }
            <!--question mark(?) is a safe navigation operator-->
          </div>
        }
      </div>
      <div class="text-lg g-col-12 g-col-md-6">
        <label for="registerNameInput" class="form-label">{{ 'REGISTER.NAME' | translate }}</label>
        <input type="email" class="form-control" id="registerNameInput" placeholder="Name" formControlName="name" autocomplete="name">
        @if((name?.invalid && name?.touched) || name?.dirty) {
          <div class="mx-1 text-primary">
            @if(name && name.errors && name.errors['required']) {
              <small class="text-danger">
                {{ 'REGISTER.REQUIREDFIELD' | translate: {field: nameString} }}<br>
              </small>
            }
            @if(name && name.errors && name.errors['minlength']) {
              <small class="text-danger">{{ 'REGISTER.MINLENGTH' | translate: {min: 3} }}</small>
            }
          </div>
        }

      </div>
      <div class="g-col-12">
        <label for="registerPasswortInput" class="form-label">{{ 'REGISTER.PASSWORD' | translate }}</label>
        <input type="password" class="form-control" id="registerPasswortInput" formControlName="password" autocomplete="new-password">
        @if((password?.invalid && password?.touched) || password?.dirty) {
          <div class="mx-1 text-primary">
            @if(password && password.errors && password.errors['minlength']) {
              <small class="text-danger">
                {{ 'REGISTER.MINLENGTH' | translate: {min: 8} }}<br>
              </small>
            }
            @if(password && password.errors && password.errors['hasNumber']) {
              <small class="text-danger">
                {{ 'REGISTER.NEEDSNUMBER' | translate: {min: 1} }}<br>
              </small>
            }
            @if(password && password.errors && password.errors['hasCapitalCase']) {
              <small class="text-danger">
                {{ 'REGISTER.NEEDSCAPITAL' | translate: {min: 1} }}<br>
              </small>
            }
            @if(password && password.errors && password.errors['hasSmallCase']) {
              <small class="text-danger">
                {{ 'REGISTER.NEEDSSMALL' | translate: {min: 1} }}<br>
              </small>
            }
            @if(password && password.errors && password.errors['hasSpecialCharacters']) {
              <small class="text-danger">
                {{ 'REGISTER.NEEDSSPECIAL' | translate: {min: 1} }}<br>
              </small>
            }
            @if(password && password.errors && password.errors['required']) {
              <small class="text-danger">
                {{ 'REGISTER.REQUIREDFIELD' | translate: {field: passwordString} }}
              </small>
            }
          </div>
        }
      </div>
      <div class="g-col-12">
        <input class="form-check-input mx-2" type="checkbox" id="registerAGBCheckbox" formControlName="agb">
        <label class="form-check-label flex-nowrap" for="registerAGBCheckbox">
          {{ 'REGISTER.SIGNIN' | translate }}<br>
          <a href="/agb" target="_blank"
            ><span class="link">{{ 'REGISTER.AGB' | translate }}</span></a
          >
          {{ 'REGISTER.AND' | translate }}
          <a href="https://www.band-on.com/datenschutzrichtlinie-band-on/" target="_blank"
            ><span class="link">{{ 'REGISTER.PRIVACY' | translate }}</span></a
          >
          {{ 'REGISTER.AGREED' | translate }}
        </label>
        @if((agb?.invalid && agb?.touched) || agb?.dirty) {
          <div class="mx-1 text-primary">
            @if(agb && agb.errors) {
              <small class="text-danger">
                {{ 'REGISTER.NEEDSAGB' | translate }}<br>
              </small>
            }
          </div>
        }
      </div>
      <div class="g-col-12">
        <input class="form-check-input mx-2" type="checkbox" id="registerNewsletterCheckbox" formControlName="newsletter">
        <label class="form-check-label flex-nowrap" for="registerNewsletterCheckbox">
          {{ 'REGISTER.NEWSLETTER' | translate }}
        </label>
        @if((newsletter?.invalid && newsletter?.touched) || newsletter?.dirty) {
          <div class="mx-1 text-primary">
            @if(newsletter && newsletter.errors) {
              <small class="text-danger">
                {{ 'REGISTER.NEEDSNEWSLETTER' | translate }}<br>
              </small>
            }
          </div>
        }
      </div>
      <div class="g-col-12 g-col-md-6 d-grid">
        <button type="button" class="btn btn-primary" [disabled]="registerForm.invalid || isSending"
            (click)="sendRegistration('https://apps.apple.com/us/app/band-on/id1673700582')">
          @if(isSending) {
            <div class="spinner-border spinner-border-sm me-2" role="status">
            </div>
          }
          <i class="bi bi-apple"></i>
          {{ 'REGISTER.SIGNUPAPPLE' | translate }}
        </button>
      </div>
      <div class="g-col-12 g-col-md-6 d-grid">
        <button type="button" class="btn btn-primary py-2" [disabled]="registerForm.invalid || isSending"
            (click)="sendRegistration('https://play.google.com/store/apps/details?id=ch.pmtbmbh.bandon.app')">
          @if(isSending) {
            <div class="spinner-border spinner-border-sm me-2" role="status">
            </div>
          }
          <i class="bi bi-google-play"></i>
          {{ 'REGISTER.SIGNUPGOOGLE' | translate }}
        </button>
      </div>
    </div>
  </form>
  @if(hasError) {
    <p class="text-primary mt-3">{{ 'REGISTER.REGISTERERR' | translate }}</p>
  }
}
@else {
  <p class="mt-3">{{ 'REGISTER.REGISTERED' | translate }}</p>
}
