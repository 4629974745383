import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { AppMockupComponent } from '../../../components/general/app-mockup/app-mockup.component';
import { GetBandonNowComponent } from '../../../components/landing-pages/get-bandon-now/get-bandon-now.component';
import { RegisterNewsletterComponent } from '../../../components/landing-pages/register-newsletter/register-newsletter.component';
import { RegisterComponent } from '../../../components/landing-pages/register/register.component';
import { SEOService } from '../../../services/seoservice.service';


@Component({
    selector: 'app-jazz-all',
    templateUrl: './jazz-all.component.html',
    styleUrls: ['./jazz-all.component.scss'],
    standalone: true,
    imports: [
      AppMockupComponent,
      RegisterComponent,
      RegisterNewsletterComponent,
      GetBandonNowComponent,
      TranslateModule
    ]
})
export class JazzAllComponent implements OnInit, OnDestroy {
  free_tune_id = 7

  seoService = inject(SEOService)
  translateService = inject(TranslateService)

  private unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.seoService.updateTitle('TITLES.LANDINGPAGES.JAZZALL');
    this.seoService.updateDescription('DESCRIPTIONS.LANDINGPAGES.JAZZALL');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
