import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';

import { serviceLoader, tokenGetter } from './app.module';
import { JwtModule } from '@auth0/angular-jwt';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppLoaderService } from './services/app-loader.service';
import { environment } from '../environments/environment';
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient, withFetch } from '@angular/common/http';
import { AuthenticationService } from './services/auth/authentication.service';
import { Location } from '@angular/common';
import { provideRouter, withDisabledInitialNavigation } from '@angular/router';
import { routes } from './app.routes';
import { translateLoaderFactory } from './core/translate-loader-factory';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterService, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { localizeLoaderFactory } from './core/localize-loader-factory';
import { TranslateInterceptor } from './core/translate-interceptor';
import { LibConfig } from 'node_modules/bandon-shared/lib/config/lib-config';
import { AuthInterceptor, LibConfigService } from 'bandon-shared';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';

const libConfig: LibConfig = {
  apiServerUrl: environment.apiURL,
  apiKey: environment.apiKey,
  loginUrl: 'home'
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withDisabledInitialNavigation()
    ),
    provideAnimations(),
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
            provide: TranslateLoader,
            useFactory: translateLoaderFactory,
            deps: [HttpClient]
        }
      }),
      LocalizeRouterModule.forRoot(routes, {
        parser: {
          provide: LocalizeParser,
          useFactory: localizeLoaderFactory,
          deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
        },
        initialNavigation: true
      }),
      NgbModule,
      ReactiveFormsModule,
      JwtModule.forRoot({
        config: {
            tokenGetter: tokenGetter,
            allowedDomains: ["band-on.com"]
        },
      }),
      SocialLoginModule,
      GoogleSigninButtonModule,
    ),
    AuthenticationService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TranslateInterceptor,
      multi: true
    },
    {
        provide: 'SocialAuthServiceConfig',
        useValue: {
            autoLogin: false,
            providers: [
                {
                    id: GoogleLoginProvider.PROVIDER_ID,
                    provider: new GoogleLoginProvider(environment.google_client_id)
                },
            ],
            onError: (err) => {
                console.error(err);
            }
        } as SocialAuthServiceConfig,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: serviceLoader,
        deps: [AppLoaderService],
        multi: true
    },
    { provide: 'LIB_CONFIG', useValue: libConfig },
    { provide: LibConfigService, useClass: LibConfigService, deps: ['LIB_CONFIG'] },
  ]
};
