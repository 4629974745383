import { Component, Input } from '@angular/core';
import { AssetLocalizationPipe } from 'src/app/core/pipes/asset-localization.pipe';

@Component({
  selector: 'app-app-mockup',
  standalone: true,
  imports: [ AssetLocalizationPipe ],
  templateUrl: './app-mockup.component.html',
  styleUrl: './app-mockup.component.scss'
})
export class AppMockupComponent {
  @Input() hookLine: string = 'Your HookLine Here';
  @Input() description: string = 'Your small description here. Provide details about the mockup.';
  @Input() img = '/assets/Mockups/Player_Website_2.png'
  @Input() bannerline1 = 'Registriere dich jetzt und'
  @Input() bannerline2 = 'erhalte ein Stück gratis!'

  constructor() { }

  ngOnInit(): void {
  }
}
