import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AgbComponent } from './pages/agb/agb.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { UserDataComponent } from './pages/user/user-data/user-data.component';
import { AppleRedirectComponent } from './pages/auth/apple-redirect/apple-redirect.component';
import { GenResetPasswordComponent } from './pages/gen-reset-password/gen-reset-password.component';
import { MusicCatalogComponent } from './pages/music-catalog/music-catalog.component';
import { JazzAllComponent } from './pages/landingPages/jazz-all/jazz-all.component';
import { SingersComponent } from './pages/landingPages/singers/singers.component';
import { ContentCreatorsComponent } from './pages/content-creators/content-creators.component';
import { ResetPasswordComponent } from './pages/auth/apple-redirect/reset-password/reset-password.component';
import { DeleteUserComponent } from './pages/auth/apple-redirect/delete-user/delete-user.component';
import { ChangeEmailComponent } from './pages/auth/apple-redirect/change-email/change-email.component';
import { ConfirmEmailComponent } from './pages/auth/apple-redirect/confirm-email copy/confirm-email.component';
import { deactivateNavigationGuard } from './guards/deactivate-navigation.guard';
import { TeamComponent } from './pages/team/team.component';
import { authGuard } from 'bandon-shared';
import { FeaturesComponent } from './pages/features/features.component';
import { MidiImportComponent } from './pages/midi-import/midi-import.component';

export const routes: Routes = [
  {
    path: "home", component: HomeComponent
  },
  {
    path: "agb", component: AgbComponent
  },
  {
    path: "auth/reset-password", component: ResetPasswordComponent, canActivate: [deactivateNavigationGuard]
  },
  {
    path: "auth/delete-user", component: DeleteUserComponent, canActivate: [deactivateNavigationGuard]
  },
  {
    path: "auth/change-email", component: ChangeEmailComponent, canActivate: [deactivateNavigationGuard]
  },
  {
    path: "auth/confirm-email", component: ConfirmEmailComponent, canActivate: [deactivateNavigationGuard]
  },
  {
    path: "catalog", component: MusicCatalogComponent
  },
  {
    path: "content-creators", component: ContentCreatorsComponent
  },
  {
    path: "contact", component: ImprintComponent
  },
  {
    path: "features", component: FeaturesComponent
  },
  {
    path: "landingpages/jazzall", component: JazzAllComponent, canActivate: [deactivateNavigationGuard]
  },
  {
    path: "landingpages/singers", component: SingersComponent, canActivate: [deactivateNavigationGuard]
  },
  {
    path: "midi-import", component: MidiImportComponent
  },
  {
    path: "privacypolicy", component: PrivacyPolicyComponent
  },
  {
    path: "team", component: TeamComponent
  },
  {
    path: "userdata", component: UserDataComponent, canActivate: [authGuard]
  },
  {
    path: "api/auth-apple-signin", component: AppleRedirectComponent
  },
  {
    path: "password/generate-reset", component: GenResetPasswordComponent
  },
  {
    path: '', redirectTo: '/home', pathMatch: 'full'
  },
  {
    path: '**', redirectTo: '/home', pathMatch: 'full'
  }

];
