import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AppMockupComponent } from '../../../components/general/app-mockup/app-mockup.component';
import { GetBandonNowComponent } from '../../../components/landing-pages/get-bandon-now/get-bandon-now.component';
import { RegisterNewsletterComponent } from '../../../components/landing-pages/register-newsletter/register-newsletter.component';
import { RegisterComponent } from '../../../components/landing-pages/register/register.component';
import { SEOService } from '../../../services/seoservice.service';

@Component({
  selector: 'app-singers',
  standalone: true,
  imports: [
    AppMockupComponent,
    RegisterComponent,
    RegisterNewsletterComponent,
    GetBandonNowComponent,
    TranslateModule
  ],
  templateUrl: './singers.component.html',
  styleUrl: './singers.component.scss'
})
export class SingersComponent implements OnInit, OnDestroy {
  free_tune_id = 84

  seoService = inject(SEOService)
  translateService = inject(TranslateService)

  private unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.seoService.updateTitle('TITLES.LANDINGPAGES.SINGERS')
    this.seoService.updateDescription('DESCRIPTIONS.LANDINGPAGES.SINGERS');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
