import { AfterViewChecked, AfterViewInit, Component, inject, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { LanguageService } from './services/language.service';
import { AuthenticationService } from 'bandon-shared';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Observable, filter } from 'rxjs';
import { FooterComponent } from './components/navigation/footer/footer.component';
import { DOCUMENT, NgIf, isPlatformBrowser } from '@angular/common';
import { TopNavComponent } from './components/navigation/top-nav/top-nav.component';
import { TranslateService } from '@ngx-translate/core';
import { AppLoaderService } from './services/app-loader.service';
import { ScrollingService } from './services/scrolling.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

declare var gtag: any;

register();

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [TopNavComponent, RouterOutlet, NgIf, FooterComponent]
})
export class AppComponent implements OnInit, AfterViewInit {
  scroller = inject(ScrollingService)
  localize = inject(LocalizeRouterService)

  title = 'band-on';

  get showTopNavAndFooter() {
    return this.appLoader.showTopNavAndFooter
  }

  set showTopNavAndFooter(value: boolean) {
    this.appLoader.showTopNavAndFooter = value;
  }

  constructor(
    private languageService: LanguageService,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private appLoader: AppLoaderService,
    private scrollingService: ScrollingService,
    @Inject(DOCUMENT) private document: Document
  ) {
    languageService.setInitialAppLanguage();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: any) => {
      if (isPlatformBrowser(PLATFORM_ID)) {
        gtag('config', 'G-DF0PFKNRP6', {
          'page_path': event.urlAfterRedirects
        });
      }

      this.showTopNavAndFooter = !event.urlAfterRedirects.includes('landingpages');
      this.showTopNavAndFooter &&= !event.urlAfterRedirects.includes('auth');
      this.scrollToFragment();
    });
  }

  ngOnInit(): void {
    this.authService.checkAuthenticated();
    this.document.documentElement.lang = this.translate.currentLang;

    // Subscribe to language changes using LocalizeRouterService
    this.localize.routerEvents.subscribe((lang: string) => {
      this.updateHtmlLangAttribute(lang);
    });

    // Or, subscribe to language changes using TranslateService
    this.translate.onLangChange.subscribe((event) => {
      this.updateHtmlLangAttribute(event.lang);
    });
  }

  ngAfterViewInit(): void {
    this.handleInitialFragmentScrolling();
  }

  private handleInitialFragmentScrolling() {
    // Scroll to fragment on initial load
    const fragment = this.router.url.split('#')[1];
    if (fragment) {
      this.scroller.scrollToFragment(fragment);
    }
  }

  scrollToFragment() {
    const fragment = this.router.url.split('#')[1];
    if (fragment) {
      this.scroller.scrollToFragment(fragment);
    }
  }

  private updateHtmlLangAttribute(lang: string) {
    document.documentElement.lang = lang; // Update the lang attribute of the <html> element
  }
}
