<div class="text-black pb-5">
  <div class="grid">
    <div class="g-col-2 d-none d-md-block"></div>
    <p class="dyn-font-7xl g-col-12 g-col-md-8 px-4 my-4">{{ "HOME.TEAM" | translate }}</p>
  </div>
  <div class="grid">
    <div class="g-col-2 border-black mt-3 mr-2 d-none d-md-block"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-9">
      <div class="p-2">
        {{ "HOME.VISION" | translate }}
      </div>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>
</div>

<div class="grid pt-4 pb-5" style="--bs-gap: 0;">
  <div class="g-col-3"></div>
  <div class="g-col-6 grid  ">
      <app-keyvisual-img class="g-col-12 g-col-md-6 mx-3" imgSrc="assets/Bilder/Portraits_Band_Elio_halmeskobel-6.jpg" text="Lea Scherer"></app-keyvisual-img>
      <app-keyvisual-img class="g-col-12 g-col-md-6 mx-3" imgSrc="assets/Bilder/Portraits_2022_10_01_jazzcampus_aufnahme_halmeskobel-12.jpg" text="Felix Wolf"></app-keyvisual-img>
  </div>
  <div class="g-col-3"></div>
</div>
