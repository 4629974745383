import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureElementComponent } from 'src/app/components/general/features/feature-element/feature-element.component';
import { TextBlockComponent } from 'src/app/components/general/text-block/text-block.component';
import { features } from 'src/app/shared/data/features.data';
import { Feature } from 'src/app/shared/interfaces/feature';

@Component({
  selector: 'app-features',
  standalone: true,
  imports: [
    CommonModule,
    FeatureElementComponent,
    TextBlockComponent,
    TranslateModule
  ],
  templateUrl: './features.component.html',
  styleUrl: './features.component.scss'
})
export class FeaturesComponent {
  features: Feature[] = features;

}
