@if(offer) {
  <div class="card border-0 d-flex flex-column" style="height: 100%;">
    <div class="card-header pricing-header" [class.popular-header]="offer.showPopular">
      @if(offer.showPopular) {
        Am Beliebtesten
      }
    </div>
    <div class="card-body d-flex flex-column">
      <div class="mb-3 min-height-md">
        <h5 class="card-title">{{ offer.title }}</h5>
        <p class="card-text">{{ offer.description }}</p>
      </div>

      <div class="flex-grow-1 mb-3">
        @if(offer.features) {
          <div class="mb-3 text-start">
            <b>Features</b>
            <ul>
              @for (feature of offer.features; track $index) {
                <li>{{ feature }}</li>
              }
            </ul>
          </div>
        }
      </div>

      @if (offer.callToAction=='buy') {
        <div class="mb-3">
          <select class="form-select" matNativeControl aria-label="Default select example" [value]="2" (change)="selectSize($event)">
            @for (plan of plans; track $index) {
              <option [value]="plan.id">
                @if(getNextSmallerPlan(plan)) {
                  {{ getNextSmallerPlan(plan)?.membercount }}-{{ plan.membercount }} {{'PAYMENT.PERSONS' | translate}}
                } @else {
                  1-{{ plan.membercount }} {{'PAYMENT.PERSONS' | translate}}
                }
              </option>
            }
          </select>
          <div class="my-3 fw-bold w-100 text-end">
            @if(paymentPeriod=='monthly') {
              {{ price_monthly | number: '1.0-2' | currency: currencyCode_monthly+' ' }}
            } @else {
              {{ price_annual | number: '1.0-2' | currency: currencyCode_annual+' ' }}
            }
          </div>
        </div>
      }

      <div class="d-grid mb-3">
        @if(offer.callToAction=='download') {
          @if(isAuth) {
            <a class="btn btn-primary" (click)="openWebApp()">{{ 'TITLES.OPENWEBAPP' | translate }}</a>
          } @else {
            <a class="btn btn-primary" (click)="openRegisterForm()">{{ 'TITLES.CALLTOACTION' | translate }}</a>
          }
        } @else if(offer.callToAction=='contact') {
          <!-- Calendly Link-Widget Beginn -->
          <!--<a class="btn btn-primary">Meeting buchen</a>-->
          <a class="btn btn-primary" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/admin-band-on/30min?primary_color=d40d53'});return false;">Meeting buchen</a>
          <!-- Calendly Link-Widget Ende -->
          <!--<a href="#" class="btn btn-primary">Erstmeeting buchen</a>-->
        } @else if(offer.callToAction=='buy') {
          <a href="#" class="btn btn-primary">{{'PAYMENT.TRY' | translate}}</a>
        }
      </div>
    </div>
  </div>
}
