<div class="my-4 my-sm-40 px-4">
  <div class="grid">
    <div class="g-col-1 d-none d-md-block"></div>
    <p class="g-col-12 g-col-md-11 dyn-font-8xl">
      Nutzungsbedingungen band-on
    </p>
  </div>

  <div class="grid">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>1. Geltungsbereich</h2>
      <p>
        Die Parteien kommen darin überein, dass für ihre Geschäftsbeziehungen ausschliesslich die vorliegenden Nutzungsbedingungen gelten. Erbringerin der Dienstleistungen ist die Personal Music Tools GmbH (nachfolgend «Anbieterin» oder «wir»), eine Gesellschaft mit Geschäftssitz im Zentweg 21i, 3006 Bern, Schweiz, eingetragen im Handelsregister des Kantons Bern unter der Nummer CHE-143.198.678. Sie ist Inhaberin und Betreiberin der Onlineplattform «band-on».
      </p>
      <p>
        Die Anbieterin bietet unter dem Namen «band-on» einen Abonnementdienst sowie einen Onlineshop an, die es den Nutzer:innen ermöglichen, auf Audioaufnahmen und zugehörige Noten zuzugreifen («Inhalt band-on»), die auf der Website der Anbieterin online verfügbar sind oder in den für PC, Mac, iOS und Android geeigneten Applikationen heruntergeladen werden können. Die Nutzung dieser Dienstleistungen ist Regelungsgegenstand dieser Nutzungsbedingungen. Die in den Nutzungsbedingungen verwendeten Begriffe «Dienst band-on», «unser Dienst» oder «der Dienst» bezeichnen die von der Anbieterin erbrachten personalisierten Dienstleistungen, welche es den Nutzer:innen erlauben, im Inhalt von «band-on» zu recherchieren und den Inhalt anzusehen, insbesondere die Gesamtheit der Funktionalitäten, die Website, die Benutzeroberflächen sowie die mit dem Dienst in Verbindung stehenden Programme.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>2.	Zugang zu den Dienstleistungen</h2>
      <p>
        Die Dienste «band-on» werden in Form des Geschäftsmodells «Freemium», «Teil-Abonnement» und «Voll-Abonnement» angeboten. Beim Modell «Freemium» haben Nutzer:innen die Möglichkeit, sich die Applikation «band-on» gratis herunterzuladen und sie können eine begrenzte Anzahl an Audioaufnahmen kostenlos anhören, nachdem sie hierfür ein Konto erstellt haben. Ein Konto kann über die Funktion «Registrieren» in der Applikation unter Angabe eines Benutzernamens, der E-Mail-Adresse und eines Passworts erstellt werden. Anschliessend können sie wählen, ob sie zusätzliche Audioaufnahmen einmalig kaufen, ein Teil-Abonnement oder ein Voll-Abonnement abschliessen, das ihnen Zugang zu einem Teil-, oder zum gesamten Katalog der Anbieterin verschafft.
      </p>
      <p>
        Sobald Nutzer:innen eine Aufnahme gekauft oder über ein Abonnement heruntergeladen wurde, sind sie berechtigt:
      </p>
      <ul>
        <li>die auf der Website «band-on» oder in den Applikationen verfügbare digitale Version der Aufnahme ausschliesslich zu persönlichen, nichtkommerziellen Zwecken zu nutzen;</li>
        <li>die auf der Website «band-on» oder in den Applikationen verfügbare digitale Version der Aufnahmen als Lehrperson für den Unterricht zu nutzen.</li>
        <li>die zugehörigen Noten für den persönlichen, nicht kommerziellen Gebrauch im Papierformat auszudrucken, sofern diese Funktionalität verfügbar ist. Die Anbieterin behält sich das Recht vor, diese Funktion zu blockieren, insbesondere dann, wenn ein:e Rechteinhaber:in dies verlangt.</li>
      </ul>
      <p>
        Nutzer:innen sind nicht berechtigt:
      </p>
      <ul>
        <li>die Noten oder die Audio-Dateien auf ein anderes Gerät zu übertragen und diese auf ihrem eigenen Endgerät oder jedem anderen Endgerät abzuspeichern;</li>
        <li>die Noten, zu kommerziellen Zwecken oder jedem anderen Zweck, der nicht persönlicher Natur ist, digital oder körperlich zu vervielfältigen, oder; </li>
        <li>die Noten in einer Art und Weise abzuändern und zu bearbeiten, die nicht ausdrücklich von «band-on» autorisiert wurde.</li>
      </ul>
      <p>
        Der Zugriff auf die Käufe ist für jedes bei «band-on» erstellte Kundenkonto auf drei Endgeräte begrenzt. Nutzer:innen haben die Möglichkeit, ihre Geräte unter «mein Konto, meine Daten» zu verwalten.
      </p>
      <p>
        Weitere Einzelheiten sind im Abschnitt <a class="link-primary band-on-link" (click)="scrollToFragment('cap3')">3. Nutzung und Urheberrechte</a> geregelt.
      </p>

      <h4>2.1 Einmaliger Kauf von Aufnahmen oder Kauf von «Sammlungen»</h4>
      <p>
        Nutzer:innen haben die Möglichkeit, die Aufnahmen einzeln oder als «Sammlungen» zu kaufen, die dann mehrere Stücke auf einmal enthalten und manchmal mit einem Preisnachlass angeboten werden. Der Kauf der Aufnahmen ermöglicht den Nutzer:innen einen zeitlich unbegrenzten Zugriff auf die digitale Version der Aufnahmen und allenfalls die zugehörigen Noten in der Form, in der sie auf der Seite band-on.com oder in den Applikationen angeboten werden.
      </p>

      <h4>2.2 Abonnement</h4>
      <h6>2.2.1 Funktionsweise des Abonnements</h6>
      <p>
        Nutzer:innen haben gleichermassen die Möglichkeit, ein unbefristetes Monatsabonnement oder ein Jahresabonnement abzuschliessen, in deren Rahmen sie Zugriff auf den gesamten, oder thematisch eingeschränkten Inhalt des Katalogs «band-on» erhalten. Die Abonnements werden am Ende ihrer Laufzeit automatisch verlängert. Dies gilt nicht, wenn Nutzer:innen ihr Abonnement mindestens 24 Stunden vor dem Ende des jeweiligen Abrechnungszeitraums kündigen oder wenn die Anbieterin das Abonnement innert derselben Frist kündigt. Kündigen Nutzer:innen ihr Abonnement, behalten sie weiterhin Zugriff auf ihre Aufnahmen und zugehörigen Noten bis zum Ende der Laufzeit des Abonnements. Danach wird der Zugang zu den Aufnahmen gesperrt und kann entsperrt werden, sobald Nutzer:innen das Abonnement wieder aktivieren. Das Abonnement kann bis 12 Monate nach Ablauf des Abonnements wieder aktiviert werden.
      </p>

      <h6>2.2.2.	Abonnementpakete und Werbeaktionen</h6>
      <p>
        Die Anbieterin hat unterschiedliche Abonnementpakete in ihrem Angebot, darunter spezielle Werbeabonnements oder von Dritten im Rahmen der Darbietung ihrer eigenen Produkte und Dienstleistungen angebotene Abonnements. Bestimmte Abonnementpakete können unterschiedliche Bedingungen und Beschränkungen enthalten, über die Nutzer:innen bei ihrer Registrierung oder über andere von ihnen gewählte Kommunikationswege informiert werden. Nutzer:innen können sämtliche Einzelheiten zu ihrem Abonnement «band-on» auf der Website der Anbieterin unter «Mein Konto, meine Daten, Abonnement», ganz oben rechts finden.
      </p>

      <h6>2.2.3 Abrechnungszeitraum</h6>
      <p>
        Die Abonnementkosten für den Dienst «band-on» werden über die von den Nutzer:innen gewählte Zahlungsmethode zum spezifischen Abrechnungsdatum abgerechnet. Das Datum ist unter «Mein Konto, meine Informationen, Abonnement» angegeben, verfügbar oben rechts auf der Website von band-on. Die Dauer des Abrechnungszeitraums hängt von der Art des Abonnements ab, das die Nutzer:innen bei ihrer Registrierung für den Dienst ausgewählt haben. In bestimmten Fällen kann sich das Abrechnungsdatum ändern, beispielsweise dann, wenn die von den Nutzer:innen gewählte Zahlungsmethode nicht funktioniert hat und daher eine Belastung nicht erfolgen konnte oder wenn das kostenpflichtige Abonnement an einem Tag begonnen hat, den es im jeweiligen Monat nicht gibt.
      </p>

      <h6>2.2.4.	Zahlungsmittel</h6>
      <p>
        Um den Dienst «band-on» nutzen zu können, müssen Nutzer:innen mindestens eine Zahlungsart angeben. Sie autorisieren uns, jegliche mit ihrem Konto verbundene Zahlungsart zu wählen, falls die primäre Zahlungsart abgelehnt wird oder nicht länger zur Bezahlung der Abonnementgebühr zur Verfügung steht. Sollte eine Zahlung nicht erfolgreich abgewickelt werden können, weil die jeweilige Zahlungsart abgelaufen ist, nicht ausreichend Guthaben aufweist oder aus einem anderen Grund fehlschlägt, und Nutzer:innen ihr Konto nicht fristgerecht gekündigt haben, kann ihr Zugang zum Dienst solange gesperrt werden, bis eine gültige Zahlungsart erfolgreich ausgeführt werden konnte. Bei bestimmten Zahlungsarten kann der Aussteller der Zahlungsart eine bestimmte Gebühr, wie zum Beispiel eine Auslandstransaktionsgebühr oder andere Gebühren für die Zahlungsabwicklung, verlangen. Die vor Ort anfallenden Steuern können je nach Zahlungsart variieren. Für weitere Informationen wenden sich Nutzer:innen bitte an den Dienstanbieter der gewählten Zahlungsart.
      </p>
      <p>
        Nutzer:innen können ihre Zahlungsarten aktualisieren, indem sie  auf der Website der Anbieterin auf «Mein Konto, meine Daten, Abonnement» gehen.
      </p>

      <h6>2.2.5.	Kündigung</h6>
      <p>
        Nutzer:innen können ihr Abonnement - jederzeit auf das Ende des gewählten Abrechnungszeitraums (monatlich resp. jährlich) unter Einhaltung einer Frist von mindestens 24 Stunden kündigen und haben bis zum Ende des Abrechnungszeitraums weiterhin uneingeschränkten Zugriff auf den Dienst. Zahlungen sind nicht erstattungsfähig und die Anbieterin gewährt keine Rückerstattungen oder Guthaben für nicht vollständig genutzte Abonnements oder nicht gesehenen Inhalt.
      </p>
      <p>
        Sind Nutzer:innen mit der Änderung der Nutzungsbedingungen nicht einverstanden (Ziffer 8), ist die Anbieterin berechtigt, das Abonnement unter Einhaltung einer Frist von mindestens 24 Stunden auf das Ende des Abrechnungszeitraums zu kündigen.
      </p>
      <p>
        Um das Abonnement zu kündigen, besuchen Nutzer:innen unsere Website und gehen auf «Mein Konto, meine Daten und Abonnement», verfügbar oben rechts auf der Website von band-on unter ihrem Profil.
      </p>
      <p>
        Wenn Nutzer:innen ihr Abonnement kündigen, wird das Konto am Ende des laufenden Abrechnungszeitraums automatisch auf die kostenlos verfügbaren Inhalte beschränkt und sie verlieren den Zugang zu allen anderen kostenpflichtigen Inhalten. Erhalten bleiben Inhalte, welche ausserhalb des Abonnements als einmaliger Kauf getätigt wurden.
      </p>

      <h6>2.2.6.	Widerrufsrecht für Verbraucher:innen</h6>
      <p>
        Nutzer:innen, welche als Verbraucher:innen gelten, steht unter gewissen gesetzlich geregelten Voraussetzungen ein Widerrufsrecht zu. Verbraucher:innen haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen den Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag an dem der/die Verbraucher:in das Abonnement oder eine Aufnahme gekauft hat.
      </p>
      <p>
        Um das Widerrufsrecht auszuüben, müssen Verbraucher:innen der Anbieterin  (Personal Music Tools GmbH, Zentweg 21i, 3006 Bern, support&#64;band-on.app) mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das unten stehende Muster Widerrufs-Formular verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
      </p>
      <p>
        Wenn ein:e Verbraucher:in den Vertrag widerruft zahlen wir ihm/ihr innert 14 Tagen seit Erhalt des Widerrufs die bereits erhaltenen Zahlungen zurück. Die Rückzahlung erfolgt auf dem gleichen Zahlungsweg, den der/die Verbraucher:in bei der ursprünglichen Transaktion verwendet hatte.
      </p>
      <p>
        Das Widerrufsrecht erlischt vorzeitig, wenn die Anbieterin mit der Vertragserfüllung begonnen hat, nachdem der/die Verbraucher:in ausdrücklich zugestimmt hat, dass die Anbieterin mit der Vertragserfüllung vor Ablauf der Widerrufsfrist beginn, Verbraucher:in die Anbieterin ihre Kenntnis davon bestätigt hat, dass durch seine/ihre Zustimmung mit Beginn der Vertragserfüllung das Widerrufsrecht erlischt, und die Anbieterin ihnen eine entsprechende Bestätigung auf einem dauerhaften Datenträger zur Verfügung gestellt hat.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2 id="cap3">3.	Geräte und erforderliche Konfiguration</h2>
      <p>
        Der Dienst «band-on» ist direkt verfügbar auf der Seite <a href="https://app.band-on.com" target="_blank">app.band-on.com</a> in der Form einer Webapplikation sowie in den für PC/Mac, iOS Endgeräte und Android Endgeräte geeigneten Applikationen.
      </p>
      <p>
        Voraussetzung für die Nutzung des Dienstes «band-on» sind ein Internetzugang und ein Endgerät sowie ein kompatibles Betriebssystem. Die Möglichkeit der Nutzer:innen, auf die Dienste zuzugreifen kann von genannten Komponenten beeinflusst werden.
      </p>
      <p>
        Die Nutzer:innen erkennen an und akzeptieren, dass die Erfüllung dieser technischen Erfordernisse, die sich von Zeit zu Zeit verändern können, in ihren Verantwortungsbereich fallen. Die Dienstleistung gehört nicht zu einem Produkt oder Angebot von Dritten und der Kauf eines anderen Produkts kann nicht dahingehend interpretiert werden, dass die Anbieterin den Nutzer:innen der Zugriff auf die Dienstleistung garantiert.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>4.	Nutzung und Urheberrechte</h2>
      <p>
        Der gesamte Datenbestand, Texte, Werke, Illustrationen und Audio-Dateien, zu denen «band-on» im Rahmen des Angebots Zugriff gewährt, ist urheberrechtlich geschützt .
      </p>
      <p>
        Der Dienst «band-on» und sämtliche Inhalte, welche Nutzer:innen über den Dienst nutzen, sind ausschliesslich für die persönliche und nicht kommerzielle Nutzung oder für die Verwendung durch Lehrpersonen für den Unterricht bestimmt. Während der Dauer des Abonnements räumt die Anbieterin den Nutzer:innen ein beschränktes, nicht exklusives und nicht übertragbares Recht, um auf den Dienst band-on zuzugreifen und die Inhalte entsprechend dieser Nutzungsbedingungen zu verwenden. Abgesehen davon werden den Nutzer:innen keine Rechte, Titel oder Ansprüche eingeräumt.
      </p>
      <p>
        Die Nutzung des Dienstes unterliegt bestimmten Einschränkungen, wobei Folgendes nicht zulässig ist: Auf jegliche Teile des Dienstes oder der Inhalte zuzugreifen sowie diese zu vervielfältigen, herunterzuladen, zu verbreiten, zugänglich zu machen, zu übersenden, zu übertragen, anzuzeigen, zu verkaufen, zu lizenzieren, zu ändern, anzupassen oder anderweitig zu verwenden, ausgenommen (a) in der Art und Weise, wie sie im Dienst genehmigt wurde; oder (b) nach vorheriger Genehmigung durch «»band-on in Textform und, sofern relevant, durch die jeweiligen Rechteinhaber:innen oder (c) soweit durch anwendbares Recht gestattet.
      </p>
      <p>
        Es ist nicht gestattet:
      </p>
      <ul>
        <li>Sicherheitsbezogene Funktionen des Dienstes zu umgehen, zu deaktivieren, betrügerisch zu verwenden oder anderweitig zu beeinträchtigen (dies betrifft auch dahin gehende Versuche). </li>
        <li>Sonstige Funktionen des Dienstes zu umgehen, zu deaktivieren, betrügerisch zu verwenden oder anderweitig zu beeinträchtigen (dies betrifft auch dahin gehende Versuche), die (a) das Kopieren bzw. die anderweitige Nutzung der Inhalte verhindern bzw. beschränken oder (b) die Nutzung des Dienstes bzw. der Inhalte einschränken.</li>
        <li>Mit automatisierten Verfahren (z. B. Robotern, Botnets oder Scrapern) auf den Dienst zuzugreifen, ausgenommen es ist durch anwendbares Recht gestattet.</li>
        <li>Software oder andere Produkte oder Prozesse, die über den Dienst band-on zugänglich sind, zu dekompilieren, rückzuentwickeln oder zu zerlegen; Codes oder anderen Produkte einzufügen oder den Dienst band-on in anderer Weise zu manipulieren; sowie Data Mining, Sammeln von Daten oder andere Extraktionsverfahren zu verwenden.</li>
        <li>Inhalte hochzuladen, zu posten, per E-Mail oder anderweitig zu senden oder zu übermitteln, die der Störung, Aufhebung oder Einschränkung der Funktion von Computer-Software/-Hardware oder von Telekommunikationsgeräten in Verbindung mit dem Dienst band-on dienen. Dies gilt auch für jegliche Software-Viren oder andere Codes, Dateien und Programme.</li>
      </ul>
      <p>
        Die Nutzer:innen garantieren, dass sie die von ihnen hergestellten Aufnahmen unter Einhaltung der vorliegenden Nutzungsbedingungen und der jeweils geltend Rechtsordnung verwenden. Sie garantieren insbesondere, keine Rechte Dritter zu verletzen und sind für das Einholen aller erforderlichen Rechte für die Verwendung ihrer Aufnahmen verantwortlich.
      </p>
      <p>
        Falls Nutzer:innen gegen diese Nutzungsbedingungen verstossen oder den Dienst auf illegale oder betrügerische Weise nutzen, oder Urheberrechte verletzen, ist die Anbieterin befugt, das Abonnement fristlos zu kündigen und den Zugang mit sofortiger Wirkung zu sperren. Die Anbieterin behält sich zudem das Geltendmachen weitergehender Schadenersatzansprüche vor.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>5.	Anwendbares Recht</h2>
      <p>
        Auf die vorliegende Nutzungsbedingungen ist Schweizer Recht anwendbar . Vorliegende Nutzungsbedingungen zielen nicht auf die Umgehung verbraucherschützender Normen ab, die auf Nutzer:innen gemäss der in ihrem Wohnsitzland geltenden Legislation anwendbar sein könnten. Ausschliesslicher Gerichtsstand bei Streitigkeiten im Zusammenhang mit den vorliegenden Nutzungsbedingungen ist Bern, Schweiz.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>6.	Kundenservice</h2>
      <p>
        Um weitergehende Informationen zum Dienst «band on» und seinen Funktionalitäten zu erhalten oder wenn sie Hilfe bei der Nutzung ihres Kontos benötigen, können Nutzer:innen die Anbieterin gerne hier kontaktieren: <a href="https://band-on.com/contact">https://band-on.com/contact</a>
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>7.	Ungültigkeit einzelner Bestimmungen/Salvatorische Klausel.</h2>
      <p>
        Sollten sich einzelne Bestimmungen dieser Nutzungsbedingungen als ungültig, unrechtmässig oder nicht vollstreckbar erweisen, bleiben die Gültigkeit, Rechtmässigkeit und Vollstreckbarkeit der übrigen Bestimmungen davon unberührt.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>8.	Änderungen der Nutzungsbedingungen</h2>
      <p>
        Die Anbieterin behält sich explizit das Recht vor, diese Nutzungsbedingungen von Zeit zu Zeit zu ändern. Die Anbieterin informiert die Nutzer:innen mindestens 10 Tage vorher über das Inkrafttreten solcher Änderungen.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

  <div class="grid">
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      <h2>9.	Elektronische Kommunikation</h2>
      <p>
        Kontobezogene Informationen (z. B. Zahlungsautorisierungen, Rechnungen, Änderungen des Passwortes oder der Zahlungsart, Bestätigungs- und sonstige Mitteilungen) erhalten die Nutzer:innen ausschliesslich in elektronischer Form, zum Beispiel als E-Mails an die E-Mail-Adresse, die sie bei ihrer Registrierung angegeben haben.
      </p>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>

</div>
