<div class="grid">
  <div class="g-col-3 d-none d-md-block"></div>
  <div class="g-col-12 g-col-md-9 g-col-lg-6 card container border-0">
    <div class="card-body">
      <div class="card-title">
        <h2>{{ 'AUTH.CONFIRMEMAIL.CONFIRM' | translate: { email: email } }}</h2>
    </div>
      <div *ngIf="validForm && !confirmed">
        <button class="btn btn-primary" (click)="onSubmit()">
          {{ 'AUTH.CONFIRMEMAIL.CHANGE' | translate }}
        </button>
      </div>
      <div *ngIf="!validForm && errmsg" class="alert">
        {{ errmsg }}
      </div>
      <div class="spinner-border text-primary" *ngIf="!validForm && !errmsg">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div *ngIf="confirmed">
          {{ 'AUTH.CONFIRMEMAIL.CONFIRMED' | translate: { email: email } }}
          <button class="btn btn-primary" (click)="closeTab()">
            {{ 'AUTH.CONFIRMEMAIL.CLOSE' | translate }}
          </button>
        </div>
    </div>
  </div>
</div>
