<div class="grid bg-primary text-tertiary">
  <div class="g-col-12 g-col-md-6 p-3">
    <p class="fs-lg fw-bold">
      Navigation<br>
      <!--News<br>
      Tutorials<br>
      Benutzereinstellungen<br>-->
      <!--<a routerLink="catalog" class="text-light">{{ "CATALOG.CATALOG" | translate }}</a><br>-->
      <a routerLink="de/features" class="text-light">{{ "NAVBAR.FEATURES" | translate }}</a><br>
      <a [routerLink]="['/contact'] | localize " class="text-light">{{ "IMPRINT.CONTACT" | translate }}</a><br>
      <a [routerLink]="['/team'] | localize " class="text-light">{{ "HOME.TEAM" | translate }}</a>
    </p>
  </div>

  <div class="g-col-12 g-col-md-6 px-3 pt-3 bg-secondary align-items-start" style="padding-bottom: 6rem;">
    <p class="fw-bold">{{ 'FOOTER.NEWSLETTER' | translate }}</p>
    <form [formGroup]="newsletterEMailForm">
      <div class="grid" style="column-gap: 0;">
        <div class="g-col-10 form-floating form-group bg-black">
          <input [class.is-invalid] = "newsletterEMailForm.get('email')?.invalid && newsletterEMailForm.get('email')?.touched"
            type="email" class="form-control" formControlName="email" id="floatingInput" placeholder="Ihre E-Mail" #newsletterEMail>
          <label for="floatingInput">{{ "FOOTER.EMAILADDR" | translate }}</label>
        </div>
        <button type="button" class="btn btn-dark rounded-0 g-col-2" (click)="registerNewsletter()" [disabled]="!newsletterEMailForm.valid || emailAdding">
          <i class="bi bi-arrow-right text-primary"></i>
          <span class="spinner-border spinner-border-sm ms-2 text-primary" aria-hidden="true" *ngIf="emailAdding" ></span>
        </button>
      </div>
      <p class="text-tertiary my-2" *ngIf="emailAdded" >
        {{ "FOOTER.EMAILADD" | translate }}
      </p>
    </form>
  </div>

  <div class="g-col-12 g-col-md-6 p-3 mx-auto">
    <div class="grid text-center">
      <div class="g-col-12 g-col-sm-6">
        <a href="https://apps.apple.com/us/app/band-on/id1673700582" target="_blank">
          <img [src]="'/assets/Download_on_the_App_Store_Badge.svg' | localizeAsset"  style="height: 3.5rem">
        </a>
      </div>
      <div class="g-col-12 g-col-sm-6">
        <a href="https://play.google.com/store/apps/details?id=ch.pmtbmbh.bandon.app" target="_blank">
          <img [src]="'/assets/google-play.png' | localizeAsset" class="g-col-12 g-col-sm-6" style="height: 3.5rem">
        </a>
      </div>
    </div>
  </div>

  <div class="g-col-12 g-col-md-6 p-3 border-bottom border-tertiary">
    <div class="d-flex flex-row-mb-3 justify-content-start fs-2 text-tertiary">
      <a href="https://www.facebook.com/profile.php?id=100092662064693" target="_blank" class="text-tertiary">
        <i class="bi bi-facebook mx-3"></i>
      </a>
      <a href="https://www.instagram.com/band_on_app/" target="_blank" class="text-tertiary">
        <i class="bi bi-instagram mx-3"></i>
      </a>
      <a href="https://www.youtube.com/@band-on" target="_blank" class="text-tertiary">
        <i class="bi bi-youtube mx-3"></i>
      </a>
      <!--<a href="#" class="text-tertiary">
        <i class="bi bi-linkedin mx-3"></i>
      </a>-->
    </div>
  </div>

  <div class="g-col-12" style="height: 3rem;">
  </div>

  <div class="g-col-12 g-col-md-6 p-3 fs-lg fw-bold text-start">
    <p>
      Kontakt<br>
      Personal Music Tools GmbH<br>
      Zentweg 21i<br>
      3006 Bern<br>
      Schweiz
    </p>
    <p>
      <a [href]="'mailto:'+supportMail+'?subject=Nachricht von der band-on Site'" class="text-tertiary">{{ supportMail }}</a>
    </p>
  </div>
  <div class="g-col-12 g-col-md-6 px-3">
    <p class="dyn-font-7xl fw-bold">{{ "HOME.LISTENSINGPLAY" | translate }}</p>
  </div>
</div>

<div class="grid bg-tertiary text-primary p-4 fw-bold">
  <div class="g-col-6 g-col-sm-4 d-flex justify-content-start">
    © Personal Music Tools GmbH<br>
    Switzerland
  </div>
  <div class="g-col-4 fs-lg justify-content-center d-none d-sm-flex">
    <img src="/assets/Band-On_Logo.png" style="height: 8rem;">
  </div>
  <div class="g-col-6 g-col-sm-4 text-end">
    <a [routerLink]="['/contact'] | localize">{{ "FOOTER.IMPRINT" | translate }}</a><br>
    <a [routerLink]="['/privacypolicy'] | localize">{{ "FOOTER.PRIVACY" | translate }}</a><br>
    <a [routerLink]="['/agb'] | localize">{{ "FOOTER.TERMS" | translate}}</a>
  </div>
</div>
