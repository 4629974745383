import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
    selector: 'app-apple-redirect',
    templateUrl: './apple-redirect.component.html',
    styleUrls: ['./apple-redirect.component.scss'],
    standalone: true
})
export class AppleRedirectComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private jwtHelper: JwtHelperService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      // Prepare the form data with the received query parameters
      const formData = new URLSearchParams();
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          formData.append(key, queryParams[key]);
        }
      }

      console.log(queryParams);
    });

    this.route.queryParams.subscribe((params) => {
      const authorizationCode = params['code'];
      const idToken = params['id_token'];
      console.log(params);

      // Decode the ID token to get additional information (optional).
      const decodedToken = this.jwtHelper.decodeToken(idToken);

      // Process the authorization code and ID token, and perform any required actions.
      // For example, you can send the authorization code and ID token to your backend server for validation and user authentication.

      // Send a message back to the parent window to indicate that the authentication is complete.
      if (typeof window !== "undefined") {
        window.opener.postMessage({ status: 'authentication_complete', token: idToken, code: authorizationCode }, '*');
      }

      // Close the current window (popup).
//      window.close();
    });
  }
}
