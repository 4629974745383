import { Component, inject } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterModalComponent } from '../register-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-registration-error',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './registration-error.component.html',
  styleUrl: './registration-error.component.scss'
})
export class RegistrationErrorComponent {
  activeModal = inject(NgbActiveModal);
  modalService = inject(NgbModal)

  back() {
    this.activeModal.dismiss();
    this.modalService.open(RegisterModalComponent, { 'centered': true } )
  }
}
