<div [class]="bgClass">
  <app-title [level]="level" [background]="background">
    <ng-content select="[slot=header]"></ng-content>
    <!--{{ title | translate }}-->
  </app-title>
  <app-content [level]="level" [background]="background">
    <ng-content select="[slot=content]"></ng-content>
    <!--{{ text | translate }}-->
  </app-content>
</div>
