import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnDestroy, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../environments/environment';
import { CustomValidators } from '../../../shared/classes/custom-validators';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnDestroy {
  @Input() freeTune: number = 7;

  isSending = false;
  isSent = false;
  hasError = false;

  private translationService = inject(TranslateService);
  private httpClient = inject(HttpClient);

  private unsubscribe$ = new Subject<void>();

  registerForm = new FormGroup({
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]
    ),
    password: new FormControl('', [
      Validators.required,
      CustomValidators.patternValidator(/\d/,{
        hasNumber: true
      }),
      CustomValidators.patternValidator(/[A-Z]/, {
        hasCapitalCase: true
      }),
      CustomValidators.patternValidator(/[a-z]/, {
        hasSmallCase: true
      }),
      CustomValidators.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }
      ),
      Validators.minLength(8)
    ]),
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    agb: new FormControl(false, [Validators.required, Validators.requiredTrue]),
    newsletter: new FormControl(false, [Validators.required, Validators.requiredTrue]),
    mailadd: new FormControl('', [this.forbiddenFieldValidator()])
  });

//  private freeTuneID = 7;

  get name() { return this.registerForm.get('name'); }

  get email() { return this.registerForm.get('email'); }

  get password() { return this.registerForm.get('password'); }

  get agb() { return this.registerForm.get('agb'); }

  get newsletter() { return this.registerForm.get('newsletter'); }

  get passwordString() { return this.translationService.instant('REGISTER.PASSWORD'); }

  get nameString() { return this.translationService.instant('REGISTER.NAME'); }

  get emailString() { return this.translationService.instant('REGISTER.EMAIL'); }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  forbiddenFieldValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? {forbiddenField: {value: control.value}} : null;
    };
  }

  sendRegistration(storeLink: string) {
    if(this.translationService.currentLang) {
//      const group_id = group_ids[this.translationService.currentLang];
      this.isSending = true;
      // Handle the form submission logic here
      let hashedPassword = '';
      if(this.password && this.password.value) {
        hashedPassword = CryptoJS.SHA256(this.password.value).toString();
      }
      const formData = {
        name: this.name?.value,
        email: this.email?.value,
        password: hashedPassword,
        newsletter: this.newsletter?.value,
        language: this.translationService.currentLang,
        freetune: this.freeTune
      }
      //console.log(formData);
      // Replace 'YOUR_API_ENDPOINT' with the actual URL where you want to send the POST request
      let headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);

      this.httpClient.post(environment.apiURL+'/auth/register', formData, {headers})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: result => {
            this.isSending = false;
            this.isSent = true;
            window.open(storeLink);
          },
          error: err => {
            this.isSending = false;
            this.hasError =true;
          }
        });

    }
  }

}
