<div class="position-relative">
  <img src="/assets/Keyvisuals/chor_2_Metronom.jpg" class="w-100">
  <div class="position-absolute top-0 end-r1 bg-primary pt-4 d-md-none">
    <img src="/assets/Band-On_Logo_1024.png" style="width: 13vw">
  </div>
</div>

<app-text-block background="primary">
  <span slot="header">{{ "CONTENTCREATORS.TITLE1" | translate }}</span>
  <span slot="content">{{ "CONTENTCREATORS.TEXT1" | translate }}</span>
</app-text-block>

<app-text-block background="tertiary" [level]="2">
  <span slot="header">{{ "CONTENTCREATORS.TITLE2" | translate }}</span>
  <span slot="content">{{ "CONTENTCREATORS.TEXT2" | translate }}</span>
</app-text-block>

<div class="py-5 bg-tertiary">
  <div class="grid mb-3">
    <div class="g-col-3 mt-2 mr-2 d-none d-md-block"></div>
    <div class="px-4 g-col-12 g-col-md-6 text-center">
      <button type="button" class="btn btn-primary" [routerLink]="['/contact'] | localize">{{ 'CONTENTCREATORS.TOCONTACT' | translate }}</button>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>
</div>

<app-text-block [level]="2">
  <span slot="header">{{ "CONTENTCREATORS.TITLE3" | translate }}</span>
  <span slot="content">{{ "CONTENTCREATORS.TEXT3" | translate }}</span>
</app-text-block>

<div class="text-black">
  <div class="grid">
    <div class="g-col-2 d-none d-md-block"></div>
    <p class="dyn-font-3xl g-col-12 g-col-md-8 px-4 my-4">{{ "CONTENTCREATORS.GROUPS" | translate }}</p>
  </div>
  <div class="grid">
    <div class="g-col-2 mt-2 mr-2 d-none d-md-block"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-8 pb-5">
      <div class="grid">
        <div class="text-lg g-col-12 g-col-md-6">
          <app-feature-panel text="CONTENTCREATORS.GROUPTEXT1" title="CONTENTCREATORS.GROUP1"></app-feature-panel>
        </div>
        <div class="text-lg g-col-12 g-col-md-6">
          <app-feature-panel text="CONTENTCREATORS.GROUPTEXT2" title="CONTENTCREATORS.GROUP2"></app-feature-panel>
        </div>
        <div class="text-lg g-col-12 g-col-md-6">
          <app-feature-panel text="CONTENTCREATORS.GROUPTEXT3" title="CONTENTCREATORS.GROUP3"></app-feature-panel>
        </div>
        <div class="text-lg g-col-12 g-col-md-6">
          <app-feature-panel text="CONTENTCREATORS.GROUPTEXT4" title="CONTENTCREATORS.GROUP4"></app-feature-panel>
        </div>
        <div class="text-lg g-col-12 g-col-md-6">
          <app-feature-panel text="CONTENTCREATORS.GROUPTEXT5" title="CONTENTCREATORS.GROUP5"></app-feature-panel>
        </div>
        <div class="text-lg g-col-12 g-col-md-6">
          <app-feature-panel text="CONTENTCREATORS.GROUPTEXT6" title="CONTENTCREATORS.GROUP6"></app-feature-panel>
        </div>
        <div class="text-lg g-col-12 g-col-md-6">
          <app-feature-panel text="CONTENTCREATORS.GROUPTEXT7" title="CONTENTCREATORS.GROUP7"></app-feature-panel>
        </div>
        <div class="text-lg g-col-12 g-col-md-6">
          <app-feature-panel text="CONTENTCREATORS.GROUPTEXT8" title="CONTENTCREATORS.GROUP8"></app-feature-panel>
        </div>
      </div>
    </div>
  </div>
</div>

<app-text-block title="CONTENTCREATORS.TITLE4" text="CONTENTCREATORS.TEXT4" id="pilot" [level]="2"></app-text-block>
<div class="py-5">
  <div class="grid mb-5">
    <div class="g-col-3 mt-2 mr-2 d-none d-md-block"></div>
    <div class="px-4 g-col-12 g-col-md-6 text-center">
      <button type="button" class="btn btn-primary" [routerLink]="['/contact'] | localize">{{ 'CONTENTCREATORS.TOCONTACT' | translate }}</button>
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>
</div>
