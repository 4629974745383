import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FeaturePanelComponent } from '../../components/general/features/feature-panel/feature-panel.component';
import { TextBlockComponent } from '../../components/general/text-block/text-block.component';
import { DocumentService } from '../../services/document.service';
import { SEOService } from '../../services/seoservice.service';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-content-creators',
  standalone: true,
  imports: [
    CommonModule,
    TextBlockComponent,
    FeaturePanelComponent,
    TranslateModule,
    RouterModule,
    LocalizeRouterModule
  ],
  templateUrl: './content-creators.component.html',
  styleUrl: './content-creators.component.scss'
})
export class ContentCreatorsComponent implements OnInit {
  seoService = inject(SEOService)
  documentService = inject(DocumentService)

  ngOnInit(): void {
    setTimeout(() => {
      if(this.documentService.window) {
        this.documentService.window.scroll(0, 0);
      }
    });

    this.seoService.updateTitle('TITLES.CONTENTCREATORS');
    this.seoService.updateDescription('DESCRIPTIONS.CONTENTCREATORS');
  }
}
