import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { DocumentService } from '../../services/document.service';
import { SEOService } from '../../services/seoservice.service';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { ScrollingService } from 'src/app/services/scrolling.service';

@Component({
    selector: 'app-agb',
    templateUrl: './agb.component.html',
    styleUrls: ['./agb.component.scss'],
    standalone: true,
    imports: [
      RouterModule,
      LocalizeRouterModule
    ]
})
export class AgbComponent implements OnInit, OnDestroy {
  seoService = inject(SEOService)
  documentService = inject(DocumentService)
  scroller = inject(ScrollingService)

  private unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    setTimeout(() => {
      if(this.documentService.window) {
        this.documentService.window.scroll(0, 0);
      }
    });

    this.seoService.updateTitle('TITLES.AGB');
    this.seoService.updateDescription('DESCRIPTIONS.AGB');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  scrollToFragment(fragment: string) {
    this.scroller.scrollToFragment(fragment)
  }

}
