<div class="grid">
  <div class="g-col-3 d-none d-md-block"></div>
  <div class="g-col-12 g-col-md-9 g-col-lg-6 card container border-0">
    <div class="card-body">
      <div class="card-title">
        <h2>{{ 'AUTH.CHANGEEMAIL.CHANGETO' | translate: { newemail: newEmail } }}</h2>
    </div>
        <div *ngIf="validForm && !confirmed">
          <button class="btn btn-primary" (click)="onSubmit()">
            {{ 'AUTH.CHANGEEMAIL.CHANGE' | translate }}
          </button>
        </div>
        <div *ngIf="!validForm && errmsg" class="alert">
          {{ errmsg }}
        </div>
        <div class="spinner-border text-primary" *ngIf="!validForm && !errmsg">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div *ngIf="confirmed">
          {{ 'AUTH.CHANGEEMAIL.CONFIRMED' | translate: { newemail: newEmail } }}
          <button class="btn btn-primary" (click)="closeTab()">
            {{ 'AUTH.CHANGEEMAIL.CLOSE' | translate }}
          </button>
        </div>
    </div>
  </div>
</div>
