<div class="grid">
  <div class="g-col-3 d-none d-md-block"></div>
  <div class="g-col-12 g-col-md-9 g-col-lg-6 card container border-0">
    <div class="card-body">
      <div class="card-title">
        <h2>{{ 'AUTH.RESETPWD.RESETYOURPWD' | translate }}</h2>
      </div>
      <form [formGroup]="formData" (ngSubmit)="onSubmit()" class="flex-form" *ngIf="validForm && !pwdSent">
          <div class="mb-3">
              <label for="passwordInput" class="form-label">{{ 'AUTH.RESETPWD.PASSWORD' | translate }}</label>
              <input id="passwordInput" formControlName="password" type="password" class="form-control">
          </div>
          <div *ngIf="password?.invalid && (password?.dirty || password?.touched)"
              class="alert alert-danger mx-0 px-0">

              <div *ngIf="(password?.invalid && password?.touched) || password?.dirty">
                  <p *ngIf="password?.errors?.['required'] || password?.errors?.['minlength']">
                    <i class="bi bi-x-circle-fill"></i>
                    {{ 'AUTH.RESETPWD.MINLENGTH' | translate: { min: 8} }}
                  </p>
                  <p *ngIf="password?.errors?.['required'] || password?.errors?.['hasNumber']">
                    <i class="bi bi-x-circle-fill"></i>
                    {{ 'AUTH.RESETPWD.NEEDSNUMBER' | translate: { min: 1} }}
                  </p>
                  <p *ngIf="password?.errors?.['required'] || password?.errors?.['hasCapitalCase']">
                    <i class="bi bi-x-circle-fill"></i>
                    {{ 'AUTH.RESETPWD.NEEDSCAPITAL' | translate: { min: 1} }}
                  </p>
                  <p *ngIf="password?.errors?.['required'] || password?.errors?.['hasSmallCase']">
                    <i class="bi bi-x-circle-fill"></i>
                    {{ 'AUTH.RESETPWD.NEEDSSMALL' | translate: { min: 1} }}
                  </p>
                  <p *ngIf="password?.errors?.['required'] || password?.errors?.['hasSpecialCharacters']">
                    <i class="bi bi-x-circle-fill"></i>
                    {{ 'AUTH.RESETPWD.NEEDSSPECIAL' | translate: { min: 1} }}
                  </p>
              </div>
          </div>

          <input formControlName="mailadd" style="display:none">
          <button  class="btn btn-primary" type="submit" [disabled]="!formData.valid" >{{ 'AUTH.RESETPWD.RESET' | translate }}</button>
      </form>
      <div *ngIf="!validForm && errmsg" class="alert">
          {{ errmsg }}
      </div>
      <div class="spinner-border text-primary" *ngIf="!validForm && !errmsg">
        <span class="visually-hidden">Loading...</span>
      </div>
        <div *ngIf="pwdSent">
          {{ 'AUTH.RESETPWD.CONFIRM' | translate }}
          <button  class="btn btn-primary" (click)="closeTab()">{{ 'AUTH.RESETPWD.CLOSE' | translate }}</button>
      </div>
    </div>
</div>
</div>


