import { RevenueCatService } from './../../../services/payment/revenue-cat.service';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, inject, Pipe } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionOption, SubscriptionPlan } from 'bandon-shared';
import { RegisterModalComponent } from '../../register/register-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionPlansService } from 'src/app/services/subscription-plans.service';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { AuthenticationService as BandonAuthService } from 'bandon-shared';
import { Package } from '@revenuecat/purchases-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscription-panel',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ],
  templateUrl: './subscription-panel.component.html',
  styleUrl: './subscription-panel.component.scss'
})
export class SubscriptionPanelComponent implements OnDestroy, OnInit {
  subscriptionPlansService = inject(SubscriptionPlansService)
  bandonAuthService = inject(BandonAuthService)
  paymentService = inject(RevenueCatService)

  @Input() offer: SubscriptionOption | undefined;
  @Input() paymentPeriod: 'monthly' | 'annual' = 'monthly'

  isAuth = false;

  selectedPlanID = 2;
  plans: SubscriptionPlan[] = [];
  selected_plan: SubscriptionPlan | undefined;

  selectedAnnualPlan: Package | undefined = undefined;
  selectedMonthlyPlan: Package | undefined = undefined;


  private modalService = inject(NgbModal);

  private unsubscribe$ = new Subject<void>();

  get price_annual(): number {
    if(this.selectedAnnualPlan) {
      let sku = this.selectedAnnualPlan as Package;
      return sku.rcBillingProduct.currentPrice.amountMicros/1000000;
    }
    return 0;
  }

  get price_monthly(): number {
    if(this.selectedMonthlyPlan) {
      let sku = this.selectedMonthlyPlan as Package;
      return sku.rcBillingProduct.currentPrice.amountMicros/1000000;
    }
    return 0;
  }

  get currencyCode_annual(): string {
    if(this.selectedAnnualPlan) {
      let sku = this.selectedAnnualPlan as Package;
      return sku.rcBillingProduct.currentPrice.currency;
    }
    return '';
  }

  get currencyCode_monthly(): string {
    if(this.selectedMonthlyPlan) {
      let sku = this.selectedMonthlyPlan as Package;
      return sku.rcBillingProduct.currentPrice.currency;
    }
    return '';
  }

  get appUrl() {
    return environment.apiURL.replace('api', 'app');
  }

  ngOnInit(): void {
    this.bandonAuthService.isAuthenticated
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe( auth => {
        this.isAuth=auth;
      });

    this.subscriptionPlansService.subscriptionPlans$
      .pipe(
        filter(plans => plans.length>1),
        take(1)
      )
      .subscribe(plans => {
        this.plans = plans;
        this.selectSizeByID(this.selectedPlanID)
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete();
  }

  openRegisterForm() {
    this.modalService.open(RegisterModalComponent, { 'centered': true } )
  }

  getNextSmallerPlan(plan: SubscriptionPlan): SubscriptionPlan | undefined {
    const planIndex = this.plans.indexOf(plan)
    if(planIndex>0) {
      return this.plans[planIndex-1];
    }
    return undefined;
  }

  selectSize(event: Event) {
    const selectedValue = Number((event.target as HTMLSelectElement).value);
    this.selectSizeByID(selectedValue);
  }

  selectSizeByID(id: number) {
    this.selectedPlanID = id;
    const plan = this.plans.find(p => p.id==this.selectedPlanID);
    if(plan) {
      this.selected_plan = plan;
      this.selectPlan(plan)
    }
  }

  selectPlan(plan: SubscriptionPlan) {
    if(plan) {
      this.paymentService.subscriptions$
      .pipe(
        filter(subscriptions => {
          if(subscriptions)
            return true;
          return false;
        }),
        take(1)
      )
      .subscribe((s) => {
        this.selectedMonthlyPlan = this.paymentService.getSubscriptionOffer(plan.sku_monthly);
        this.selectedAnnualPlan = this.paymentService.getSubscriptionOffer(plan.sku_annual);
      })
    } else {
      this.selectedAnnualPlan = undefined;
      this.selectedMonthlyPlan = undefined;
    }
  }

  openWebApp() {
    const token = this.bandonAuthService.getToken();
    const refresh_token = this.bandonAuthService.getRefreshToken();
    if(token && refresh_token) {
      const baseURL = `${this.appUrl}/collection/user/login-token`;

      // Construct the query parameters
      const queryParams = new URLSearchParams({ token, refresh_token });

      const url = `${baseURL}?${queryParams}`;
      window.open(url, "_blank");
    }
  }
}
