export const appPages = [
  { title: 'HOME.HOME', url: '/home' },
  { title: 'Funktionen', url: '/features'},
  /*{ title: 'Preis', url: '/home', fragment: 'prices'},*/
  { title: 'FAQs', url: '/home', fragment: 'faqs'},
  /*{ title: 'NAVBAR.BLOG', url: '/content-creators' },*/
  { title: 'IMPRINT.CONTACT', url: '/contact' }
/*    { title: 'Rollmaterial', icon: 'train', open: false, children: [
    { title: 'Fahrzeuge', url: 'members/rolling-stock', icon: 'train'},
    { title: 'Modelle', url: 'members/model-overview', icon: 'train' }
  ] },
  { title: 'Netz', icon: 'git-merge', open: false, children: [
    { title: 'HAV', url: 'members/hav-overview', icon: 'git-commit'},
    { title: 'Gleisgeometrie', url: 'members/geometrie-edit', icon: 'analytics'},
    { title: 'GFM', url: 'members/gfmoverview', icon: 'analytics'}
  ]},
  { title: 'Fahrplan', icon: 'calendar', open: false, children: [
    { title: 'Aktivitäten', url: 'members/activities-page', icon: 'clipboard'},
    { title: 'Bahnhof-Dispo', url: 'members/bhf-dispo', icon: 'clipboard'},
  ]},
  { title: 'Beleuchtung', url: '/members/lights-overview', icon: 'home' },
  { title: 'FSim', icon: 'train', open: false, children: [
    { title: 'Zug', url: '/members/fsim-train', icon: 'train' },
    { title: 'Info', url: '/members/fsim-info', icon: 'information-circle'},
    { title: 'Diagramme', url: '/members/fsim-charts', icon: 'bar-chart' }
  ] },
  { title: 'Spam', url: '/folder/Spam', icon: 'warning' },*/
];
