import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-delete-user',
    templateUrl: './delete-user.component.html',
    styleUrls: ['./delete-user.component.scss'],
    standalone: true,
    imports: [ NgIf, TranslateModule]
})
export class DeleteUserComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  get validForm(): boolean {
    if (this.token)
      return true;
    return false;
  }

  token: String | undefined
  errmsg: String | undefined
  deleted = false
  user_id = ''

  constructor(private route: ActivatedRoute,
    private httpClient: HttpClient, private translate: TranslateService) {}

  ngOnInit() {

    this.route.queryParams
      .subscribe(params => {
        this.user_id = params['user_id'];
        if (this.user_id && params['token']) {
          const headers = new HttpHeaders()
          .set('Authorization', 'Bearer '+environment.apiKey)
          const http_params = new HttpParams()
          .set('token', params['token']);

          this.httpClient.get<any>(`${environment.apiURL}/users/${this.user_id}/deletion`, {headers, params: http_params})
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: data => {
                this.token = params['token'];
              },
              error: error => {
                this.translate.get('AUTH.INVALIDFORM')
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(value => this.errmsg = value)
                console.log(error);
              }
            })
        } else {
          this.translate.get('AUTH.INVALIDFORM')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(value => this.errmsg = value)
        }
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  onSubmit() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer '+this.token);
    this.httpClient.delete(`${environment.apiURL}/users/${this.user_id}/deletion`, {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: data => {
        this.deleted = true;
      },
      error: error => {
        console.log(error)
        this.translate.get('AUTH.DELETEUSER.ERROR')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(value => this.errmsg = value)
        this.token = undefined
      }
    });
  }

  closeTab() {
    window.close();
  }

}
  function closeTab() {
    throw new Error('Function not implemented.');
  }

