import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomValidators } from '../../../../shared/classes/custom-validators';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: true,
    imports: [ NgIf, ReactiveFormsModule, TranslateModule]
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  formData: FormGroup;
  private unsubscribe$ = new Subject<void>();

  get password() { return this.formData.get('password'); }
  get validForm(): boolean {
    if (this.token)
      return true;
    return false;
  }

  token: String | undefined
  errmsg: String | undefined
  pwdSent = false

  constructor(private route: ActivatedRoute,
    private httpClient: HttpClient, private translate: TranslateService)
  {
      this.formData = new FormGroup({
        password: new FormControl('', [
          Validators.required,
          CustomValidators.patternValidator(/\d/,{
            hasNumber: true
          }),
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8)
        ]),
        mailadd: new FormControl('', [
          this.forbiddenFieldValidator()
        ])
      });
  }

  ngOnInit() {

    this.route.queryParams
      .subscribe(params => {
        if (params['token']) {
          const headers = new HttpHeaders()
          .set('Authorization', 'Bearer '+environment.apiKey)
          const http_params = new HttpParams()
          .set('token', params['token']);

          this.httpClient.get<any>(environment.apiURL+'/auth/reset_password', {headers, params: http_params})
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: data => {
                this.token = params['token'];
              },
              error: error => {
                this.errmsg = error.error['message'];
                console.log('The token seems to be invalid!');
                console.log(error);
              }
            })
        } else {
          this.translate.get('AUTH.INVALIDFORM')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(value => this.errmsg = value)
        }
      })
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next()
      this.unsubscribe$.complete()
  }

  onSubmit() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer '+this.token);
    const hashedPassword = CryptoJS.SHA256(this.formData.get('password')?.value).toString();
    const data = {new_password: hashedPassword};
    console.log(data);
    this.httpClient.post(environment.apiURL+'/auth/change_password', data, {headers})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: data => {
        this.pwdSent = true;
      },
      error: error => {
        console.log(error)
        this.translate.get('AUTH.RESETPWD.ERROR')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(value => this.errmsg = value)
        this.token = undefined
      }
    });
  }

  forbiddenFieldValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? {forbiddenField: {value: control.value}} : null;
    };
  }

  closeTab() {
    window.close();
  }

}
