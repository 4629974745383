<div class="grid pb-5"
  [class.bg-tertiary]="background==='tertiary'"
>
  <div class="g-col-2 mt-2 mr-2 d-none d-md-block"></div>
  <div class="px-4 g-col-12 g-col-md-8">
    <swiper-container
      slides-per-view="1"
      centered-slides="true"
      pagination="true"
      navigation="true"
      auto-height="true"
      pagination-clickable="true"
    >
    @for (collection of collections; track $index) {
      <swiper-slide [class.bg-tertiary]="background==='tertiary'">
        <div class="text-center mx-5 mb-4 grid">
          <div class="g-col-11 g-col-md-4">
            <img class="img-fluid" src="{{ collection.img }}">
          </div>
          <div class="g-col-12 g-col-md-8 text-start">
            <p class="fw-bold">{{ collection.title }}</p>
            <p class="line-break-translated">{{ collection.description }}</p>
          </div>
        </div>
      </swiper-slide>
    }
    </swiper-container>
  </div>
  <div class="g-col-2 d-none d-md-block"></div>
</div>
