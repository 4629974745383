<div class="bg-primary text-tertiary">
  <div class="grid">
    <div class="g-col-3 d-none d-md-block"></div>
    <p class="fw-bold font-black dyn-font-8xl g-col-9 g-col-md-9 px-4 my-4 title-translated">Test</p>
  </div>
  <div class="grid">
    <div class="border-tertiary g-col-3 border-top mt-2 mr-2 d-none d-md-block"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      Test
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
    </div>
</div>

<div class="row row-cols-1 row-cols-md-2 g-4 mx-4">
  <div class="col" *ngFor="let tune of tunes" >
    <app-tune-card [tune]="tune"></app-tune-card>
  </div>
</div>
