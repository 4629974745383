import { inject, Injectable } from '@angular/core';
import { LogLevel, Offering, Offerings, Package, Purchases } from '@revenuecat/purchases-js';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserDataService } from '../user/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class RevenueCatService {
  userDataService = inject(UserDataService)

  isInit = false;

  private offerings: BehaviorSubject<Offerings | undefined> = new BehaviorSubject<Offerings | undefined>(undefined);

  private subscriptions: BehaviorSubject<Offering | undefined> = new BehaviorSubject<Offering | undefined>(undefined);
  subscriptions$ = this.subscriptions.asObservable();

  constructor() {
    this.initRevenueCat();

    this.userDataService.user$.subscribe(user => {
      let userID = '1234'
      if(user) {
        userID = user.uid
      }
      Purchases.getSharedInstance().changeUser(userID)
    });
  }

  async initRevenueCat() {
    if(!this.isInit) {
      this.isInit = true;
      Purchases.setLogLevel(LogLevel.Info)
      let userID = '1234'
      if(this.userDataService.user) {
        userID = this.userDataService.user.uid
      }
      Purchases.configure(environment.revenueCatKeyWeb, userID)

      const offerings = await Purchases.getSharedInstance().getOfferings();
      this.offerings.next(offerings)

      this.refreshOfferings();
    }
  }

  async refreshOfferings() {
    console.log('refreshOfferings')
    const offerings = await Purchases.getSharedInstance().getOfferings();
    this.offerings.next(offerings);
    this.subscriptions.next(offerings.all['content_creator']);
  }

  getSubscriptionOffer(identifier: string): Package | undefined {
    if(this.subscriptions.value) {
      return this.subscriptions.value.availablePackages.find(p => p.identifier===identifier);
    }
    return undefined;
  }

}
