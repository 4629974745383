import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { SimpleTuneModel } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { TuneCardComponent } from '../../components/library/tune-card/tune-card.component';
import { NgFor } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { DocumentService } from '../../services/document.service';
import { SEOService } from '../../services/seoservice.service';

@Component({
    selector: 'app-music-catalog',
    templateUrl: './music-catalog.component.html',
    styleUrls: ['./music-catalog.component.scss'],
    standalone: true,
    imports: [NgFor, TuneCardComponent]
})
export class MusicCatalogComponent implements OnInit, OnDestroy {
  seoService = inject(SEOService)
  translateService = inject(TranslateService)
  documentService = inject(DocumentService)

  tunes: SimpleTuneModel[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      if(this.documentService.window) {
        this.documentService.window.scroll(0, 0);
      }
    });

    let headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);

    this.httpClient.get<SimpleTuneModel[]>(environment.apiURL+"/tunes", {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.tunes = res.sort((t1, t2) => t1.title.localeCompare(t2.title));
      });

    this.seoService.updateTitle('TITLES.LIBRARY');
    this.seoService.updateDescription('DESCRIPTIONS.LIBRARY');
  }


  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

}
