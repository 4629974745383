import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Subject, take, takeUntil } from 'rxjs';
import { PricingCategory, UserDevice, UsernameValidator, profanityValidator } from 'bandon-shared';
import { Collection } from 'bandon-shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { CurrencyPipe, DatePipe, CommonModule } from '@angular/common';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { AuthenticationService as BandonAuthService } from 'bandon-shared';
import { environment } from '../../../../environments/environment';
import { LibraryService } from '../../../services/library.service';
import { CustomValidators } from '../../../shared/classes/custom-validators';
import { UserDataService } from 'src/app/services/user/user-data.service';

export interface UserPurchaseItem {
  date: Date;
  collectionid?: number;
  tuneid?: number;
  price?: PricingCategory;
  userPrice?: number;
  userCurrency?: string;
}

@Component({
    selector: 'app-user-data',
    templateUrl: './user-data.component.html',
    styleUrls: ['./user-data.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      CurrencyPipe,
      DatePipe,
      TranslateModule
    ]
})
export class UserDataComponent implements OnInit, OnDestroy {
  usernameValidator = inject(UsernameValidator)
  userDataService = inject(UserDataService)
  bandonAuthService = inject(BandonAuthService)

  isChangingPassword = false; // Flag to track if user is changing password
  userdataForm!: FormGroup;
  dataUpdating = false;
  updateErr = false;

  devices: UserDevice[] = [];
  editDevice: UserDevice | undefined;

  suggestedUsernames: string[] = []; // Property to store suggested usernames

  public userPurchases: UserPurchaseItem[] = [];

  private unsubscribe$ = new Subject<void>();
  private collections: Collection[] = [];

  constructor(
    private authService: AuthenticationService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private libraryService: LibraryService,
    private httpClient: HttpClient
  ) {}


  get email() { return this.userdataForm.get('email'); }

  get name() { return this.userdataForm.get('name'); }

  get username() { return this.userdataForm.get('username'); }

  get oldPassword() { return this.userdataForm.get('oldPassword'); }

  get password() { return this.userdataForm.get('newPassword'); }

  get nameString() { return this.translate.instant('REGISTER.NAME'); }

  get usernameString() { return this.translate.instant('USERDATA.USERNAME'); }

  get oldPWDString() { return this.translate.instant('USERDATA.OLDPASSWORD'); }

  get passwordString() { return this.translate.instant('USERDATA.NEWPASSWORD'); }

  get isSocialLogin() { return this.authService.loginWithSocial; }

  get purchases() { return this.userDataService.user?.purchases }

  get customDeviceName() {
    if(this.editDevice) {
      return this.editDevice.customname ?? ''
    }
    return ''
  }

  set customDeviceName(name: string) {
    if(this.editDevice) {
      this.editDevice.customname = name;
    }
  }


  ngOnInit(): void {
    setTimeout(() => {
      window.scroll(0, 0);
    });

    this.userdataForm = this.formBuilder.group({
      name: [ this.userDataService.user?.name, [Validators.required, Validators.minLength(3), profanityValidator()]],
      username: [this.userDataService.user?.username, [Validators.required, Validators.minLength(3), profanityValidator()], [this.usernameValidator.validate.bind(this.usernameValidator)]],
      email: [this.userDataService.user?.email, Validators.required],
      oldPassword: [''],
      newPassword: [''],
      confirmPassword: ['']
    }, { validators: this.confirmPasswordValidator() });

    // Subscribe to username control value changes to update suggested usernames
    this.userdataForm.get('username')?.valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      if (value && value.length > 0 && this.username && !this.username.errors) {
        this.usernameValidator.validate(this.username).subscribe(
          (result) => {
            if (result && result['usernameTaken']) {
              this.suggestedUsernames = result['suggestedUsernames'] || [];
            } else {
              this.suggestedUsernames = []; // Clear suggestions if username is available
            }
          }
        );
      } else {
        this.suggestedUsernames = []
      }
    });

    this.updatePasswordValidators(false);

    // Subscribe to changes in the newPassword field
    this.userdataForm.get('newPassword')?.valueChanges.subscribe(newValue => {
      this.updatePasswordValidators(newValue);
    });

    this.userdataForm.get('email')?.disable();

    this.userDataService.user$.subscribe(user => {
      if(user) {
        this.userdataForm.get('username')?.setValue(user.username)
        this.userdataForm.get('name')?.setValue(user.name)
        this.userdataForm.get('email')?.setValue(user.email)

        this.userPurchases.length = 0;
        user.purchases?.forEach((purchase: { items: any[]; purchasedate: any; }) => {
          purchase.items.forEach(item=> {
            const purchaseItem: UserPurchaseItem = {
              date: purchase.purchasedate,
              collectionid: item.collectionid,
              tuneid: item.tuneid,
              userPrice: item.price,
              userCurrency: item.currency
            };
            this.userPurchases.push(purchaseItem);
          });
        });

        this.refreshDevices();
      }
    });

    this.libraryService.collections$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(collections => {
        this.collections.length = 0;
        this.collections.push(...collections);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  refreshDevices() {
    if(this.userDataService.user) {
      const token = this.bandonAuthService.getIDToken();
      const headers = new HttpHeaders().set('Authorization', token);
      this.httpClient.get<UserDevice[]>(`${environment.apiURL}/users/${this.userDataService.user.uid}/devices`, {headers})
        .subscribe({
          next: data => {
            this.devices = data;
          },
          error: err => {
            console.error(err)
          }
        })
    }
  }

  updatePasswordValidators(enableValidators: boolean): void {
    const oldPasswordControl = this.userdataForm.get('oldPassword')!;
    const newPasswordControl = this.userdataForm.get('newPassword')!;
    const confirmPasswordControl = this.userdataForm.get('confirmPassword')!;

    if (enableValidators && !this.isChangingPassword) {
      this.isChangingPassword = true;

      oldPasswordControl.setValidators([Validators.required]);
      newPasswordControl.setValidators([
        Validators.required,
        CustomValidators.patternValidator(/\d/,{
          hasNumber: true
        }),
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8)
      ]);
      confirmPasswordControl.setValidators([Validators.required]);

      oldPasswordControl.updateValueAndValidity();
      newPasswordControl.updateValueAndValidity();
      confirmPasswordControl.updateValueAndValidity();
    } else if(this.isChangingPassword) {
      this.isChangingPassword = false;

      oldPasswordControl.clearValidators();
      newPasswordControl.clearValidators();
      confirmPasswordControl.clearValidators();


//      oldPasswordControl.updateValueAndValidity();
//      newPasswordControl.updateValueAndValidity();
//      confirmPasswordControl.updateValueAndValidity();
    }

  }

  confirmPasswordValidator(): ValidatorFn {
    return (group: AbstractControl): { [key: string]: boolean } | null => {
      const newPassword = group.get('newPassword')?.value;
      const confirmPassword = group.get('confirmPassword')?.value;

      return newPassword === confirmPassword ? null : { passwordsNotMatch: true };
    };
  }

  onSubmit() {
    this.dataUpdating = true;
    if (this.userdataForm.valid && this.userDataService.user) {
      this.userDataService.user.name = this.name?.value;

      this.userDataService.updateUserData()?.pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: resp => {
            this.dataUpdating = false;
          },
          error: err => {
            console.log(err);
            this.dataUpdating = false;
            this.updateErr = true;
          }
        });

        if(this.password && this.password.value && this.oldPassword && this.oldPassword.value) {
          this.changePassword(this.oldPassword.value, this.password.value)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(res => console.log(res));
        }
    } else {
      this.dataUpdating = false;
      this.updateErr = true;
    }
  }

  changePassword(oldPassword: string, newPassword: string) {
    const headers = new HttpHeaders().set('Authorization', this.bandonAuthService.getIDToken());
    const oldHashedPassword = CryptoJS.SHA256(oldPassword).toString();
    const newHashedPassword = CryptoJS.SHA256(newPassword).toString();

    return this.httpClient.post(environment.apiURL+`/users/${this.bandonAuthService.getUserID()}/password`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        {old_password: oldHashedPassword, new_password: newHashedPassword}, {headers});
  }


  getCollectionTitle(collectionid: number) {
    return this.collections.find(c => c.id===collectionid)?.designation;
  }

  getTuneDesc(tuneid: number) {
    const tune = this.libraryService.getSimpleTune(tuneid);
    if(tune) {
      return tune.title;
    }
    return 'Unbekanntes Stück';
  }

  resetError() {
    this.updateErr = false;
  }

  deleteDevice() {
    if(this.userDataService.user && this.editDevice) {
      const token = this.bandonAuthService.getIDToken();
      const headers = new HttpHeaders().set('Authorization', token);
      this.httpClient.delete(`${environment.apiURL}/users/${this.userDataService.user.uid}/devices/${this.editDevice.deviceid}`, {headers})
        .subscribe({
          next: data => {
            this.refreshDevices()
          },
          error: err => {
            //TODO: show error
            console.error(err)
          }
        })
    }
  }

  setEditDevice(device: UserDevice) {
    this.editDevice = device;
  }

  saveDevice() {
    if(this.userDataService.user && this.editDevice) {
      const token = this.bandonAuthService.getIDToken();
      const headers = new HttpHeaders().set('Authorization', token);
      const formData = new FormData();

      formData.append('device', JSON.stringify(this.editDevice));

      this.httpClient.post<UserDevice>(`${environment.apiURL}/users/${this.userDataService.user.uid}/devices/${this.editDevice.deviceid}`, formData, {headers})
        .subscribe({
          next: data => {
            this.refreshDevices()
          },
          error: err => {
            //TODO: show error
            console.error(err)
          }
        })
    }
  }
}
