import { environment } from "src/environments/environment";
import { Collection } from "../interfaces/collection";

export const collections: Collection[] = [
  {
    title: 'Ann Malcolm\'s music spectrum',
    description: "Erlebe eine Sammlung von Einsingübungen, entwickelt von der renommierten Sängerin Ann Malcolm. Als fester Bestandteil der Jazzszene und langjährige Gesangsdozentin am Jazzcampus Basel hat Ann Malcolm Übungen kreiert, die dir helfen, dich optimal aufzuwärmen und dein Gehör zu schulen.",
    img: `${environment.apiURL}/photoImg/92`
  },
  {
    title: 'Schweizer Volksmusik',
    description: "Tauche ein in die Welt der Schweizer Volksmusik mit Arrangements und Aufnahmen von Kristina und Evelyn Brunner. Diese Sammlung bietet dir einen perfekten Einstieg in die traditionelle Musik für Schwiizerörgeli und Kontrabass.",
    img: `${environment.apiURL}/photoImg/79`
  },
  {
    title: 'Play with Jazzcampus Basel',
    description: "Spiele zusammen mit einigen der besten Musiker*innen der aktuellen Jazzszene. Ann Malcolm, Florian Egli, William Evans, Bänz Oester und Jorge Rossy, dozierende am Jazzcampus und fest im Jazz verwurzelt, haben Jazzstandards für dich eingespielt. Lerne und performe Jazzstandards mit den Besten!",
    img: `${environment.apiURL}/photoImg/2`
  },
  {
    title: 'Weihnachtslieder',
    description: "Bereite dich auf die Weihnachtszeit vor mit unseren vierstimmigen Weihnachtsliedern. Vier Saxophon-Stimmen begleitet von einer Band schaffen die perfekte Atmosphäre für festliche Proben und Auftritte.",
    img: `${environment.apiURL}/photoImg/91`
  },
  {
    title: 'Basic Jazz',
    description: "Dein Einstieg in den Jazz! Wir haben Jazzstandards in drei Schwierigkeitsgraden aufgenommen, sodass du einfach anfangen und kontinuierlich spannende Fortschritte machen kannst. Tauche ein und entdecke die Welt des Jazz!",
    img: `${environment.apiURL}/photoImg/3`
  }

]
