import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './title.component.html',
  styleUrl: './title.component.scss'
})
export class TitleComponent {
  @Input() level = 1;

  @Input() background: 'none' | 'primary' | 'secondary' | 'tertiary' = 'none';

  get titleClass(): string {
    let bold = '';
    if (this.background=='primary') {
      bold = 'fw-bold font-black text-light '
    }
    if (this.level==1) {
      bold += 'dyn-font-8xl g-col-12 g-col-md-10 '
    } else {
      bold += 'dyn-font-7xl g-col-12 g-col-md-8 '
    }

    return bold+'px-4 my-4 title-translated'
  }
}
