<div class="my-40 px-4">
  <div class="grid">
    <div class="g-col-1 d-none d-md-block"></div>
    <p class="g-col-12 g-col-md-11 dyn-font-8xl">
      {{ "PWDRESET.PWDRESET" | translate }}
    </p>
  </div>

  <form [formGroup]="contactForm" *ngIf="!msgSent" >
    <div class="grid my-5">
      <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
      <div class="px-4 text-lg g-col-12 g-col-md-6">
        <form>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">{{ "IMPRINT.EMAIL" | translate }}</label>
            <input [class.is-invalid] = "contactForm.get('email')?.invalid && contactForm.get('email')?.touched"
              type="email" class="form-control" id="inputEmail" aria-describedby="emailHelp" formControlName="email">

            <!-- Error message for required validator -->
            <div *ngIf="contactForm.get('email')?.errors?.['required'] && (contactForm.get('email')?.dirty || contactForm.get('email')?.touched)" class="text-danger">
              {{ "IMPRINT.REQEMAIL" | translate }}
            </div>

            <!-- Error message for pattern validator -->
            <div *ngIf="contactForm.get('email')?.errors?.['pattern'] && (contactForm.get('email')?.dirty || contactForm.get('email')?.touched)" class="text-danger">
              {{ "IMPRINT.REQEMAILOK" | translate }}
            </div>
          </div>

          <input type="text" class="d-none" formControlName="mailadd">

          <button type="button" class="btn btn-primary rounded-0" (click)="submit()"
              [disabled]="!contactForm.valid || msgSending">
            {{ "PWDRESET.REQUEST" | translate }}
            <span class="spinner-border spinner-border-sm" aria-hidden="true" *ngIf="msgSending" ></span>
          </button>
        </form>
      </div>
      <div class="g-col-3 d-none d-md-block"></div>
    </div>
  </form>

  <div class="grid my-5" *ngIf="msgSent" >
    <div class="g-col-3 border-top border-black mr-2 d-none d-md-block mt-3"></div>
    <div class="px-4 text-lg g-col-12 g-col-md-6">
      {{ "PWDRESET.SENT" | translate }}
    </div>
    <div class="g-col-3 d-none d-md-block"></div>
  </div>
</div>
