import { Component, OnInit, HostListener, Renderer2, CUSTOM_ELEMENTS_SCHEMA, inject, OnDestroy, Inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FaqPanelComponent } from '../../components/general/faq-panel/faq-panel.component';
import { FeaturePanelComponent } from '../../components/general/features/feature-panel/feature-panel.component';
import { KeyvisualImgComponent } from '../../components/general/keyvisual-img/keyvisual-img.component';
import { TextBlockComponent } from '../../components/general/text-block/text-block.component';
import { Subject, takeUntil } from 'rxjs';
import { RouterModule } from '@angular/router';
import { DocumentService } from '../../services/document.service';
import { SEOService } from '../../services/seoservice.service';
import { HeroHeaderComponent } from 'src/app/components/general/hero-header/hero-header.component';
import { AssetLocalizationPipe } from 'src/app/core/pipes/asset-localization.pipe';
import { FeaturesComponent } from 'src/app/components/general/features/features.component';
import { PaymentComponent } from 'src/app/components/payment/payment.component';
import { TitleComponent } from 'src/app/components/general/text-block/title/title.component';
import { RegisterModalComponent } from 'src/app/components/register/register-modal.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { CollectionsSwiperComponent } from 'src/app/components/general/collections-swiper/collections-swiper.component';
import { ScrollingService } from 'src/app/services/scrolling.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [
      TextBlockComponent,
      KeyvisualImgComponent,
      FeaturePanelComponent,
      FaqPanelComponent,
      TranslateModule,
      RouterModule,
      HeroHeaderComponent,
      AssetLocalizationPipe,
      FeaturesComponent,
      PaymentComponent,
      TitleComponent,
      RegisterModalComponent,
      LocalizeRouterModule,
      CollectionsSwiperComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeComponent implements OnInit, OnDestroy {
  seoService = inject(SEOService)
  translateService = inject(TranslateService)
  documentService = inject(DocumentService)
  scroller = inject(ScrollingService)

  private unsubscribe$ = new Subject<void>();

  constructor() {
  }

  mockupSlidesPerView = 4;
  centeredSlides = false;
  showPagination = false;

  ngOnInit(): void {
    this.onWindowResize();
    setTimeout(() => {
      this.scroller.scrollToTop();
    });

    this.seoService.updateTitle('TITLES.HOMEPAGE');
    this.seoService.updateDescription('DESCRIPTIONS.HOMEPAGE');

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  @HostListener('window:resize', [])
  onWindowResize() {
    if(this.documentService.window) {
      const screenWidth = this.documentService.window.innerWidth;
      // Adjust the screen size breakpoints and corresponding slidesPerView values as needed
      this.centeredSlides = true;
      this.showPagination = true;

      if (screenWidth < 650) {
        this.mockupSlidesPerView = 1;
      } else if (screenWidth < 920) {
        this.mockupSlidesPerView = 2;
      } else if (screenWidth < 1190) {
        this.mockupSlidesPerView = 3;
      } else {
        this.mockupSlidesPerView = 4;
        this.centeredSlides = false;
        this.showPagination = false;
      }
    }
  }

  scrollToFragment(fragment: string) {
    this.scroller.scrollToFragment(fragment)
  }

}
