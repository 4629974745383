import { Component, OnInit, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DocumentService } from '../../services/document.service';
import { SEOService } from '../../services/seoservice.service';
import { ScrollingService } from 'src/app/services/scrolling.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
    standalone: true
})
export class PrivacyPolicyComponent implements OnInit{
  seoService = inject(SEOService)
  documentService = inject(DocumentService)
  scroller = inject(ScrollingService)

  get supportMail(): string {
    return environment.supportEMail
  }

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      if(this.documentService.window) {
        this.documentService.window.scroll(0, 0);
      }
    });

    this.seoService.updateTitle('TITLES.PRIVACY');
    this.seoService.updateDescription('DESCRIPTIONS.PRIVACY');
  }

  scrollToFragment(fragment: string) {
    this.scroller.scrollToFragment(fragment)
  }

}
