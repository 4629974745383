import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomValidators, RegisterParams, UsernameValidator, profanityValidator } from 'bandon-shared';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InstructionsModalComponent } from './instructions-modal/instructions-modal.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';
import { RegistrationErrorComponent } from './registration-error/registration-error.component';
import { AuthenticationService } from 'bandon-shared';
import * as CryptoJS from 'crypto-js';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-register-modal',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    LocalizeRouterModule,
    RouterModule
  ],
  templateUrl: './register-modal.component.html',
  styleUrl: './register-modal.component.scss',
})
export class RegisterModalComponent implements OnInit, OnDestroy {
  activeModal = inject(NgbActiveModal);
  fb = inject(FormBuilder)
  translate = inject(TranslateService)
  httpClient = inject(HttpClient)
  usernameValidator = inject(UsernameValidator)
  modalService = inject(NgbModal)
  authService = inject(AuthenticationService)
  languageService = inject(LanguageService)

  private unsubscribe$ = new Subject<void>();

  suggestedUsernames: string[] = []; // Property to store suggested usernames

  form: FormGroup;

  get username() { return this.form.get('username'); }

  get name() { return this.form.get('name'); }

  get email() { return this.form.get('email'); }

  get password() { return this.form.get('password'); }

  get newsletter() { return this.form.get('newsletter'); }

  get passwordString() { return this.translate.instant('REGISTER.PASSWORD'); }

  get usernameString() { return this.translate.instant('REGISTER.USERNAME'); }

  get nameString() { return this.translate.instant('REGISTER.NAME'); }

  get emailString() { return this.translate.instant('REGISTER.EMAIL'); }
  constructor() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      name: ['', [Validators.required, Validators.minLength(3), profanityValidator()]],
      username: ['', [Validators.required, profanityValidator()], [this.usernameValidator.validate.bind(this.usernameValidator)]],
      password: new FormControl('', [
        Validators.required,
        CustomValidators.patternValidator(/\d/,{
          hasNumber: true
        }),
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8)
      ]),
      agb: [ false, [Validators.required, Validators.requiredTrue]],
      newsletter: [ true ]
    });

    // Subscribe to username control value changes to update suggested usernames
    this.form.get('username')?.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value && value.length > 0 && this.username && !this.username.errors) {
          this.usernameValidator.validate(this.username).subscribe(
            (result) => {
              if (result && result['usernameTaken']) {
                this.suggestedUsernames = result['suggestedUsernames'] || [];
              } else {
                this.suggestedUsernames = []; // Clear suggestions if username is available
              }
            }
          );
        } else {
          this.suggestedUsernames = []
        }
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  onSubmit() {
    // Handle the form submission logic here
    const params: RegisterParams = {
      email: this.email?.value,
      username: this.username?.value,
      name: this.name?.value,
      password: this.password?.value,
      newsletter: this.newsletter?.value,
      lang: this.languageService.selected,
      agb: true
    }
    this.authService.register(params)
      .subscribe({
        next: result => {
          this.openInstructionsModal();
        },
        error: err => {
          this.showAlert()
        }
      });
//    this.openInstructionsModal();
  }

  openInstructionsModal() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(InstructionsModalComponent, { centered: true });
    // Pass any necessary data to the instructions modal
  }

  showAlert() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(RegistrationErrorComponent, { centered: true });
  }

  suggestUsername() {
    let username = this.name?.value.replace(/\s+/g, '').toLowerCase();
    this.checkUsernameAvailability(username)
  }

  checkUsernameAvailability(username: string) {
    if(username) {
      const headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
      this.httpClient.get<any>(`${environment.apiURL}/check-username/${username}`, {headers})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: response => {
            if (response.available) {
              // Username is available
              this.username?.setValue(username); // Convert to lowercase for consistency
            } else {
              // Username already exists
              this.username?.setValue(response.suggestions[0]); // Convert to lowercase for consistency
            }
          },
          error: err => {
            console.error('Error checking username availability:', err);
            // Handle error, maybe show a generic error message to the user
          }
        });
    }
  }
}
