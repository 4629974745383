import { Component, Input } from '@angular/core';
import { SimpleTuneModel } from 'bandon-shared';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-tune-card',
    templateUrl: './tune-card.component.html',
    styleUrls: ['./tune-card.component.scss'],
    standalone: true
})
export class TuneCardComponent {

  @Input() tune!: SimpleTuneModel;

  get description(): string {
    if (this.tune) {
      return this.tune.tonalities+', '+this.tune.speeds;
    }
    return '';
  }

  get ImgSrc(): string {
    if(this.tune && this.tune.picture) {
      return environment.apiURL+'/photoImg/'+this.tune.picture.id;
    }
    return '';
  }

}
