import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AssetLocalizationPipe } from 'src/app/core/pipes/asset-localization.pipe';
import { RegisterModalComponent } from '../../register/register-modal.component';

@Component({
  selector: 'app-hero-header',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AssetLocalizationPipe
  ],
  templateUrl: './hero-header.component.html',
  styleUrl: './hero-header.component.scss'
})
export class HeroHeaderComponent {
  @Input() tagline = 'HOME.TAGLINE';
  @Input() subtTagline = 'HOME.SUBTAGLINE';
  @Input() callToAction = 'TITLES.CALLTOACTION';
  @Input() mockupUrl = '/assets/Mockups/Hero_Mockup.png';

  private modalService = inject(NgbModal);

  openRegisterForm() {
    this.modalService.open(RegisterModalComponent, { 'centered': true } )
  }

}
