import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-feature-panel',
    templateUrl: './feature-panel.component.html',
    styleUrls: ['./feature-panel.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      TranslateModule,
      LocalizeRouterModule,
      RouterModule
    ]
})
export class FeaturePanelComponent {

  @Input() img: string | undefined;
  @Input() title: string | undefined;
  @Input() text = "";
  @Input() detailRoute = "";
  @Input() fragment = "";

  get imgClass(): string {
    return `bi ${this.img}`
  }
}
