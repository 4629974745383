import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthenticationService, SubscriptionPlan } from 'bandon-shared';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlansService {
  private authService = inject(AuthenticationService);
  private http = inject(HttpClient);

  public subscriptionPlans = new BehaviorSubject<SubscriptionPlan[]>([]);
  public subscriptionPlans$ = this.subscriptionPlans.asObservable();

  private isAuth = false;

  constructor() {
    this.init()
  }

  init() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    this.http.get<SubscriptionPlan[]>(`${environment.apiURL}/subscription_plans`, {headers})
    .subscribe({
      next: plans => {
        this.subscriptionPlans.next(plans.sort((p1, p2) => p1.membercount-p2.membercount));
      }
    })
    this.authService.isAuthenticated.subscribe(auth => {
      if (auth===this.isAuth) {
        return;
      }
      this.isAuth = auth;
    });
  }

  getSubscription(id: number): SubscriptionPlan | undefined {
    return this.subscriptionPlans.value.find(p => p.id == id);
  }

}
